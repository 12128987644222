import { AutocompleteRenderInputParams, TextField } from '@mui/material';
import React from 'react';

interface Props extends AutocompleteRenderInputParams {
  placeholder?: string;
}
export const RenderInput: React.FC<Props> = ({ placeholder, ...p }) => {
  return (
    <TextField
      {...p}
      InputProps={{
        ...p.InputProps,
        startAdornment: undefined,
      }}
      inputProps={{
        ...p.inputProps,
      }}
      variant={'outlined'}
      placeholder={placeholder}
    />
  );
};
