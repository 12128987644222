import { createSelector } from 'reselect';
import { calcPaginationState } from '../../utils';
import { AppState } from '../index';
import { initStatePostsUsersItem } from './slice';

const selectState = (state: AppState) => state.postsUsers;

const selectUserID = (state: any, userID: string) => userID;

export const selectPostsUsersState = createSelector(
  [selectState, selectUserID],
  (state, userID) => {
    return state[userID] || initStatePostsUsersItem();
  },
);
export const selectPostsUsersStatePagination = createSelector(selectPostsUsersState, (state) => {
  return calcPaginationState(state.pagination);
});

export const selectPostsUsersStateHasFilters = createSelector(selectPostsUsersState, (state) => {
  return state.filters.search !== '';
});
