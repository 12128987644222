import { createAsyncThunk } from '@reduxjs/toolkit';
import { PostInfo, PostModel, ServicePosts } from 'services/posts';
import { parseErrorData } from 'utils';
import { AppAsyncThunkConfig } from '../index';

export const actionPostsDelete = createAsyncThunk<
  { id: string },
  { id: string },
  AppAsyncThunkConfig
>(`POSTS/delete`, async ({ id }) => {
  try {
    await ServicePosts.deletePost(id);
    return { id };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionPostsCreate = createAsyncThunk<void, PostModel, AppAsyncThunkConfig>(
  `POSTS/create`,
  async (data) => {
    try {
      await ServicePosts.createPost(data);
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);

export const actionPostsEdit = createAsyncThunk<
  void,
  { id: string; oldData: PostModel; newData: PostModel },
  AppAsyncThunkConfig
>(`POSTS/edit`, async ({ id, oldData, newData }) => {
  try {
    await ServicePosts.updatePost(id, oldData, newData);
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionPostsRefresh = createAsyncThunk<
  { data: PostInfo },
  { id: string },
  AppAsyncThunkConfig
>('POSTS/refresh', async ({ id }) => {
  try {
    const { data } = await ServicePosts.getByID(id);
    return { data };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
