import { createSelector } from 'reselect';
import { calcPaginationState } from 'utils';
import { AppState } from '../index';

const selectState = (state: AppState) => state.specialists;

export const selectSpecialists = createSelector(selectState, (state) => {
  return state;
});
export const selectSpecialistsStatuses = createSelector(selectState, ({ isLoading, isInit }) => {
  return { isLoading, isInit };
});
export const selectSpecialistsData = createSelector(selectState, ({ data }) => {
  return data;
});
export const selectSpecialistsFilters = createSelector(selectSpecialists, ({ filters }) => {
  return filters;
});

export const selectHasSpecialistsFilters = createSelector(selectSpecialists, ({ filters }) => {
  return Object.values(filters).some((item: string | boolean | string[]) => {
    return Array.isArray(item) ? Boolean(item.length) : Boolean(item);
  });
});
export const selectSpecialistsPagination = createSelector(
  selectSpecialists,
  ({ take, page, count }) => {
    return calcPaginationState({ take, page, count });
  },
);
