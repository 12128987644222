import { Typography, TypographyProps } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import style from './index.module.scss';

interface Props {
  className?: string;
  label: string;
  LabelProps?: Omit<TypographyProps, 'ref'>;
  value: any;
  ValueProps?: Omit<TypographyProps, 'ref'>;
}

export const ItemLabel: React.FC<Props> = ({ className, value, label, LabelProps, ValueProps }) => {
  if (!value) {
    return null;
  }
  return (
    <div className={className}>
      <Typography
        component={'span'}
        variant={'body2'}
        {...LabelProps}
        className={clsx(style.label, LabelProps?.className)}
      >
        {label}:
      </Typography>
      &nbsp;
      <Typography
        component={'span'}
        variant={'body2'}
        {...ValueProps}
        className={clsx(style.value, ValueProps?.className)}
      >
        {value}
      </Typography>
    </div>
  );
};
