import { API } from 'utils';
import { API_LABELS, Label } from './models';

export * from './models';

class Service {
  async getDefaultLanguageID() {
    return API.get<string>(API_LABELS.GET_DEFAULT_LANGUAGE);
  }
  async getLabels(languageID: string) {
    const params = {
      filter: `languageID=="${languageID}"`,
    };
    return API.get<{ value: Label[] }>(API_LABELS.GET_LABELS, { params });
  }
}

export const ServiceLabels = new Service();
