import { actionChatGetMessages } from 'components/cutom-chat/store';
import { useAppDispatch } from 'hooks';
import React, { useEffect } from 'react';
import { useChatConversation, useChatOnlineUsers, useChatRead } from '../../hooks';
import { CustomChatHeader } from '../message-header';
import { CustomMessagesContent } from '../messages-content';
import style from './index.module.scss';

interface Props {
  isShowClose?: boolean;
}

export const CustomChantMessages: React.FC<Props> = ({ isShowClose }) => {
  const dispatch = useAppDispatch();
  const { messages, conversation, conversationID, isNew, isLoading, isInit, onDelete, onClose } =
    useChatConversation();

  useEffect(() => {
    if (!isNew && !isInit && !isLoading && conversationID) {
      dispatch(actionChatGetMessages({ conversationID }));
    }
  }, [isNew, isInit, isLoading, conversationID, dispatch]);

  const { checkIsOnline } = useChatOnlineUsers();
  useChatRead();

  return (
    <div className={style.wrap}>
      {conversation && (
        <CustomChatHeader
          isOnline={checkIsOnline(conversation.appUserID)}
          appUserID={conversation.appUserID}
          fullName={[
            conversation.appUserTitleOfName,
            conversation.appUserFirstName,
            conversation.appUserLastName,
          ]
            .filter(Boolean)
            .join(' ')}
          picture={conversation.appUserProfilePicture}
          position={conversation.appUserPosition}
          onClose={isShowClose ? onClose : undefined}
          onDelete={!isNew ? onDelete : undefined}
        />
      )}
      {conversationID && conversation && (
        <CustomMessagesContent
          conversationID={conversationID}
          isNew={isNew}
          messages={messages}
          appUserID={conversation.appUserID}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};
