import React, { memo } from 'react';

interface Props {
  active?: boolean;
  zIndex?: number;
  background?: string;
}

export const AppLoading = memo<Props>(
  ({ active = true, zIndex = 1000, background = 'rgba(255,255,255,0)' }) => {
    if (!active) return null;
    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: zIndex,
          background: background,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
    );
  },
);
