import { useAppDispatch, useAppSelector } from 'hooks';
import React, { FC, useEffect } from 'react';
import { actionAuthGetCurrentUser, selectAccountsState } from 'store/accounts';

interface Props {
  children: React.ReactNode
}
export const AuthProvider: FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { init, loading } = useAppSelector(selectAccountsState);

  useEffect(() => {
    if (!init && !loading) {
      dispatch(actionAuthGetCurrentUser());
    }
  }, [dispatch, init, loading]);

  return <>{children}</>;
};
