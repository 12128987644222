import { AppSelectProps } from 'components/app-select';
import { useCallback } from 'react';
import { UseFormStateReturn } from 'react-hook-form/dist/types';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { decorateAfter, fieldToLabelKey } from 'utils/other';
import { useTranslate } from './use-translate';

interface Options {
  field: ControllerRenderProps<any, any>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<any>;
}

interface Props extends Partial<AppSelectProps<any, any, any, any>> {}

export const useFilterSelectProps = (props?: Props) => {
  const { t } = useTranslate();
  return useCallback(
    (options: Options) => {
      const { field } = options;
      return {
        ...props,
        RenderInputProps: {
          ...props?.RenderInputProps,
          InputLabelProps: {
            ...props?.RenderInputProps?.InputLabelProps,
          },
        },
        ...field,
        label: t(fieldToLabelKey(field.name)),

        onChange: decorateAfter(field.onChange, props?.onChange),
      };
    },
    [props, t],
  );
};
