import { createAsyncThunk } from '@reduxjs/toolkit';
import { ServiceSurveys, Survey } from 'services/surveys';
import { parseErrorData } from 'utils';
import { AppAsyncThunkConfig } from '../index';

export const actionSurveyLatestVote = createAsyncThunk<
  { surveyID: string; data: Survey },
  { surveyID: string; optionID: string },
  AppAsyncThunkConfig
>(`SURVEY_LATEST/vote`, async ({ surveyID, optionID }) => {
  try {
    const { data } = await ServiceSurveys.voteSurveyItem({ surveyID, optionID });
    return { surveyID, data };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionSurveyLatestGetCount = createAsyncThunk<
  { count: number },
  void,
  AppAsyncThunkConfig
>(`SURVEY_LATEST/count`, async () => {
  try {
    const { data: count } = await ServiceSurveys.getCountUnVoted();
    return { count };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
