import { ServiceSpecialists, SpecialistFull, SpecialistPreview } from 'services/specialists';
import { call, debounce, put, select, takeEvery } from 'typed-redux-saga';
import {
  createFilterArrays,
  createFilterContains,
  createFilterSmartSearch,
  parseErrorData,
} from 'utils';
import { workerErrorNotifySaga } from 'utils/sagas';
import { selectCheckedTagIDs } from '../tags';
import {
  selectSpecialistsLatestApplyTags,
  selectSpecialistsLatestFilters,
  selectSpecialistsLatestPagination,
} from './selectors';
import { actionSpecialistsLatestGet } from './slice';

function* fetchSpecialistsLatest() {
  const { search } = yield* select(selectSpecialistsLatestFilters);
  const { take } = yield* select(selectSpecialistsLatestPagination);
  const tagIDs = yield* select(selectCheckedTagIDs);
  const applyTags = yield* select(selectSpecialistsLatestApplyTags);

  const filterSearch = [
    createFilterSmartSearch<SpecialistFull>({ name: ['firstName', 'lastName'], value: search }),
    createFilterContains<SpecialistFull>({ name: 'subject', value: search }),
  ]
    .filter((v) => !!v)
    .join('||');

  const filter = [
    createFilterArrays<SpecialistFull, 'userExpertProfileTagIDs'>({
      name: 'userExpertProfileTagIDs',
      key: 'id',
      value: applyTags ? tagIDs : [],
    }),
    filterSearch,
  ]
    .filter((v) => !!v)
    .join('&&');
  const selectParams = Object.keys(new SpecialistPreview()).join(',');

  try {
    const {
      data: { value, count },
    } = yield* call(ServiceSpecialists.getAllDynamic, {
      select: selectParams,
      filter,
      take,
    });
    yield put(actionSpecialistsLatestGet.success({ data: value, count }));
  } catch (e: any) {
    yield put(actionSpecialistsLatestGet.fail({ error: parseErrorData(e) }));
  }
}

export const sagasSpecialistsLatest = [
  debounce(500, actionSpecialistsLatestGet.request, fetchSpecialistsLatest),
  takeEvery([actionSpecialistsLatestGet.fail], workerErrorNotifySaga),
];
