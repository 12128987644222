import { useCallback } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { checkIsHebrew } from 'utils';

export const validateEmail = (data: string) => {
  if (data && data.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/gi) === null) {
    return 'invalid-email';
  } else {
    return undefined;
  }
};
export const validateURL = (data: string) => {
  if (
    data &&
    data.match(
      // eslint-disable-next-line
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g,
    ) === null
  ) {
    return 'invalid-url';
  } else {
    return undefined;
  }
};

export const validatePhoneNumber = (value: string) => {
  if (String(value).length === 11) {
    return undefined;
  } else {
    return 'invalid-phone';
  }
};
export const validateTheSame = (
  value: string,
  original: string,
  errorLabel: string = 'invalid',
) => {
  if (original === value) {
    return undefined;
  } else {
    return errorLabel;
  }
};
export const validateLicense = (value: string) => {
  if (value) {
    return value.length < 4 ? 'license-validation' : undefined;
  }
  return undefined;
};
export const validateRequiredArray = (value?: any[]) => {
  return value?.length ? undefined : 'required-array';
};

export const validateHebrew = (value: string) => {
  if (!value || checkIsHebrew(value)) {
    return undefined;
  } else {
    return 'rule-hebrew';
  }
};

type ValidateRules =
  | 'required'
  | 'phone'
  | 'minLength'
  | 'hebrew'
  | 'email'
  | 'url'
  | 'license'
  | 'requiredArray';
export const useValidateRules = () => {
  const rules = useCallback((...args: ValidateRules[]) => {
    return args.reduce((acc: RegisterOptions, rule) => {
      switch (rule) {
        case 'required':
          acc['required'] = true;
          return acc;
        case 'hebrew':
          acc['validate'] = {
            ...acc['validate'],
            hebrew: validateHebrew,
          };
          return acc;
        case 'email':
          acc['validate'] = {
            ...acc['validate'],
            email: validateEmail,
          };
          return acc;
        case 'url':
          acc['validate'] = {
            ...acc['validate'],
            url: validateURL,
          };
          return acc;
        case 'phone':
          acc['validate'] = {
            ...acc['validate'],
            phone: validatePhoneNumber,
          };
          return acc;
        case 'license':
          acc['validate'] = {
            ...acc['validate'],
            license: validateLicense,
          };
          return acc;
        case 'minLength':
          acc['minLength'] = {
            value: 4,
            message: 'min-length',
          };
          return acc;
        case 'requiredArray':
          acc['validate'] = {
            ...acc['validate'],
            license: validateRequiredArray,
          };
          return acc;
        default:
          return acc;
      }
    }, {});
  }, []);
  return {
    rules,
  };
};
