import AddIcon from '@mui/icons-material/Add';
import { Button, CardActions, CardContent, CardHeader, Paper } from '@mui/material';
import { AppAvatar } from 'components/app-avatar';
import { useFollowing, useOnlineUsers, useStartChatAction, useTranslate } from 'hooks';
import React, { useCallback, useMemo } from 'react';
import { ExpertProfile } from 'services/expert-profiles';
import { decorateAfter } from 'utils';
import { ItemLabel } from '../../../item-lable';
import style from './index.module.scss';

type Model = ExpertProfile;

interface Props {
  className?: string;
  data: Model;
  isLoading: boolean;
}

export const UserTooltipCard: React.FC<Props> = ({ className, data, isLoading }) => {
  const { t } = useTranslate();
  const { checkIsOnline } = useOnlineUsers();
  const { onStartChat, titleActionChat, canStartChat } = useStartChatAction({
    ...data,
    userExpertProfileID: data.appIdentityUserID,
  });
  const onPrevent = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);
  const { isPharmaCompanyExpert, following } = data;

  const { onFollowingAdd, isFollowingAccess, isFollowingLoading } = useFollowing({
    userExpertProfileID: data.appIdentityUserID,
  });
  const isShowFollowing = useMemo(() => {
    return [isPharmaCompanyExpert, isFollowingAccess, !following].every(Boolean);
  }, [isPharmaCompanyExpert, isFollowingAccess, following]);
  return (
    <Paper className={style.wrap}>
      <CardHeader
        classes={{ root: style.header, subheader: style.headerSub, content: style.headerContent }}
        title={[data.titleOfNameTitle, data.firstName, data.lastName].filter(Boolean).join(' ')}
        subheader={data.position}
        avatar={
          <AppAvatar
            src={data.userPhoto}
            bgColor={data.color}
            isOnline={checkIsOnline(data.appIdentityUserID)}
          />
        }
      />
      <CardContent classes={{ root: style.content }}>
        <ItemLabel
          label={t('primary-medical-field')}
          value={data.primaryMedicalFieldTitle}
          className={style.data}
        />
        <ItemLabel
          label={t('secondary-medical-field')}
          value={data.secondaryMedicalFieldTitle}
          className={style.data}
        />
        <ItemLabel
          label={t('speak-languages')}
          value={data.userExpertProfileSpeakLanguageTitles}
          className={style.data}
        />
      </CardContent>
      <CardActions classes={{ root: style.footer }}>
        {isShowFollowing && (
          <Button
            endIcon={<AddIcon />}
            onClick={decorateAfter(onPrevent, onFollowingAdd)}
            disabled={isFollowingLoading || isLoading}
            size={'small'}
            className={style.button}
          >
            {t('start-follow')}
          </Button>
        )}
        {canStartChat && (
          <Button
            color={'primary'}
            variant={'contained'}
            onClick={decorateAfter(onPrevent, onStartChat)}
            size={'small'}
            className={style.button}
          >
            {titleActionChat}
          </Button>
        )}
      </CardActions>
    </Paper>
  );
};
