import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Drawer, IconButton, Link } from '@mui/material';
import clsx from 'clsx';
import { AppHeaderMobileMenu } from 'components/app-header/components/header-mobile-menu';
import { ProfileLink } from 'components/app-header/components/profile-link';
import { DialogPrivacy, DialogTerms } from 'components/dialogs';
import { useOpen, useTranslate } from 'hooks';
import Logo from 'images/logo-login.svg';
import React from 'react';
import style from './index.module.scss';

interface Props {
  className?: string;
}

export const AppHeaderMobilePanel: React.FC<Props> = ({ className }) => {
  const { isOpen, onClose, onToggle } = useOpen();
  const { t } = useTranslate();
  const { isOpen: isOpenPrivacy, onOpen: onOpenPrivacy, onClose: onClosePrivacy } = useOpen();
  const { isOpen: isOpenTerms, onOpen: onOpenTerms, onClose: onCloseTerms } = useOpen();

  return (
    <div className={clsx(className)}>
      <IconButton color={'secondary'} onClick={onToggle} className={style.button}>
        <MenuIcon fontSize={'inherit'} />
      </IconButton>
      <Drawer anchor={'right'} open={isOpen} onClose={onClose} classes={{ paper: style.wrap }}>
        <div className={style.header}>
          <div className={style.headerTop}>
            <img src={Logo} alt="" />
            <IconButton onClick={onClose} color={'inherit'}>
              <CloseIcon color={'inherit'} />
            </IconButton>
          </div>
          <div className={style.headerBottom}>
            <ProfileLink />
          </div>
        </div>
        <AppHeaderMobileMenu onClose={onToggle} />
        <div className={style.footer}>
          <div className={style.nav}>
            <Link color={'secondary'} underline={'always'} onClick={onOpenTerms}>
              {t('terms')}
            </Link>
            <span className={style.navSep}>|</span>
            <Link color={'secondary'} underline={'always'} onClick={onOpenPrivacy}>
              {t('privacy')}
            </Link>
          </div>
          <div className={style.copy}>{t('copyrights')}</div>
        </div>
      </Drawer>
      <DialogPrivacy isOpen={isOpenPrivacy} onClose={onClosePrivacy} />
      <DialogTerms isOpen={isOpenTerms} onClose={onCloseTerms} />
    </div>
  );
};
