import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Theme, Typography, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import style from './index.module.scss';

interface AppDialogTitleProps {
  Icon?: React.ReactNode;
  onClose?: () => void;
  className?: string;
  children?: React.ReactNode;
}

export const AppDialogTitle: React.FC<AppDialogTitleProps> = ({
  className,
  Icon,
  onClose,
  children,
}) => {
  const isMobile = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('md');
  });
  return (
    <div className={clsx(style.wrap, className)}>
      {children && (
        <div className={style.title}>
          {Icon && <div className={style.icon}>{Icon}</div>}
          <Typography variant={isMobile ? 'subtitle2' : 'h6'} component={'h3'}>
            {children}
          </Typography>
        </div>
      )}
      {onClose && (
        <IconButton size={'small'} className={style.close} onClick={onClose} color={'secondary'}>
          <CloseIcon color={'secondary'} />
        </IconButton>
      )}
    </div>
  );
};
