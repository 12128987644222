import { createSelector } from 'reselect';
import { calcPaginationState } from 'utils';
import { AppState } from '../index';

const selectState = (state: AppState) => state.posts;

export const selectPostsStatuses = createSelector(selectState, ({ isInit, isLoading, error }) => {
  return { isInit, isLoading, error };
});
export const selectPostsData = createSelector(selectState, ({ data }) => {
  return data;
});
export const selectPostsFilters = createSelector(selectState, ({ filters }) => {
  return filters;
});
export const selectPostsOrder = createSelector(selectState, ({ orderBy }) => {
  return orderBy;
});
export const selectPostsPagination = createSelector(selectState, ({ pagination }) => {
  return calcPaginationState(pagination);
});

export const selectNewPostData = createSelector(selectState, ({ newPost }) => {
  return newPost;
});
