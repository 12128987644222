import React, { FC, memo, useEffect, useReducer } from 'react';
import { useRawScript } from '../../hooks';

class InitState {
  title = '';

  generalBodyScripts?: string | null | undefined = '';
  generalFooterScripts?: string | null | undefined = '';
  generalHeaderScripts?: string | null | undefined = '';
  googleAnalyticScripts?: string | null | undefined = '';

  metaTitle?: string | null | undefined = '';
  metaDescription?: string | null | undefined = '';
  metaKeywords?: string | null | undefined = '';
}

export const AppMetaContext = React.createContext<((action: Actions) => void) | null>(null);

type Actions = { type: 'set'; payload: Partial<InitState> };
const reducer = (state: InitState, action: Actions): InitState => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload };
    default: {
      return state;
    }
  }
};

interface Props {
  children: React.ReactNode
}

export const AppMetaProvider: FC<Props> = memo(({ children }) => {
  const [state, dispatch] = useReducer(reducer, new InitState());

  const { generalBodyScripts, generalFooterScripts, generalHeaderScripts, googleAnalyticScripts } =
    state;

  useRawScript(generalHeaderScripts, { parent: document.head });
  useRawScript(googleAnalyticScripts, { parent: document.head });
  useRawScript(generalBodyScripts, { parent: document.body, insert: 'first' });
  useRawScript(generalFooterScripts, { parent: document.body });

  const { metaTitle, metaDescription, metaKeywords } = state;

  useEffect(() => {
    if (!metaTitle) {
      return;
    }
    document.title = metaTitle;
  }, [metaTitle]);

  useEffect(() => {
    if (!metaDescription) {
      return;
    }
    const el = document.querySelector("meta[name='description']");
    el?.setAttribute('content', metaDescription);
  }, [metaDescription]);

  useEffect(() => {
    if (!metaKeywords) {
      return;
    }
    const el = document.querySelector("meta[name='keywords']");
    el?.setAttribute('content', metaKeywords);
  }, [metaKeywords]);

  return <AppMetaContext.Provider value={dispatch}>{children}</AppMetaContext.Provider>;
});
