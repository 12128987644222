import { APP_ROUTES } from 'configs/routes';
import React, { useCallback } from 'react';
import { ServiceAuth } from 'services/auth';
import { resetUserAction } from 'store/accounts';
import { getCookies, updateAuthTokens } from 'utils/cookies';
import { AxiosInterceptorPatch } from './patch';
import { AxiosInterceptorsRefreshToken } from './refresh-token';
import { AxiosInterceptorsToken } from './token';
import {useAppDispatch} from "hooks";

const { SIGN_IN } = APP_ROUTES;

interface Props {
  children: React.ReactNode
}

export const AxiosInterceptors: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const onRefresh = useCallback(async () => {
    const Token = getCookies(window.authCookieName_);
    const RefreshToken = getCookies(window.authRefreshCookieName_);
    const expires = getCookies(window.authDateCookieName_) as string;

    const {
      data: { token, refreshToken },
    } = await ServiceAuth.refreshToken({ Token, RefreshToken });

    return { token, refreshToken, expires };
  }, []);

  const onError = useCallback(
    (e: Error) => {
      dispatch(resetUserAction());
      if (window.location.pathname !== SIGN_IN.path) {
        window.location.href = `${SIGN_IN.path}?redirect=${window.location.pathname}${window.location.search}`;
      }
    },
    [dispatch],
  );

  return (
    <AxiosInterceptorPatch>
      <AxiosInterceptorsToken>
        <AxiosInterceptorsRefreshToken
          onRefresh={onRefresh}
          onSuccess={updateAuthTokens}
          onError={onError}
        >
          {children}
        </AxiosInterceptorsRefreshToken>
      </AxiosInterceptorsToken>
    </AxiosInterceptorPatch>
  );
};
