import { createSelector } from 'reselect';
import { AppState } from 'store';

const selectState = (state: AppState) => {
  return state.chat;
};
const selectChatState = createSelector(selectState, (state) => {
  return state;
});

export const selectChatStatuses = createSelector(
  selectChatState,
  ({ isLoading, isInit, error }) => {
    return { isLoading, isInit, error };
  },
);

export const selectChatIsOpen = createSelector(selectChatState, ({ isOpen }) => {
  return isOpen;
});
const selectChatConversations = createSelector(selectChatState, ({ data }) => {
  return data;
});

export const selectChatOpenConversationID = createSelector(
  selectChatState,
  ({ openConversationID }) => {
    return openConversationID;
  },
);

export const selectChatOpenConversation = createSelector(
  selectChatConversations,
  selectChatOpenConversationID,
  (stateItems, openConversationID) => {
    return stateItems.find(
      ({ conversation }) => conversation.conversationID === openConversationID,
    );
  },
);

export const selectChatOnlineUsers = createSelector(selectChatState, ({ users }) => {
  return users;
});
export const selectChatOnlineUserIDs = createSelector(selectChatState, ({ users }) => {
  return Object.keys(users);
});

export const selectChatConversationsList = createSelector(
  selectChatConversations,
  selectChatOnlineUsers,
  (conversations, onlineUsersIDs) => {
    return Object.values(conversations)
      .sort((a, b) => {
        const timeA = new Date(a.conversation.lastUpdatedDate).getTime();
        const timeB = new Date(b.conversation.lastUpdatedDate).getTime();
        return Number(timeA < timeB) - Number(timeB < timeA);
      })
      .sort((a, b) => {
        const isOnlineA = Boolean(onlineUsersIDs[a.conversation.appUserID]);
        const isOnlineB = Boolean(onlineUsersIDs[b.conversation.appUserID]);
        if (isOnlineA && !isOnlineB) {
          return -1;
        } else if (isOnlineB && !isOnlineA) {
          return 1;
        } else {
          return 0;
        }
      });
  },
);
export const selectChatConversationsUnRead = createSelector(selectChatConversations, (list) => {
  return list.reduce((acc, { conversation }) => {
    acc = conversation.notRead ? acc + 1 : acc;
    return acc;
  }, 0);
});

export const selectChatHasNewMessages = createSelector(
  selectChatConversationsUnRead,
  (badgeCount) => {
    return badgeCount > 0;
  },
);
