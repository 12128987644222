import { Theme, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { AppLoading } from 'components/loading';
import { useCurrentUser } from 'hooks';
import React, { useEffect, useMemo, useRef } from 'react';
import { useChatOnlineUsers } from '../../hooks';
import { ConversationMessage } from '../../service';
import { splitBy } from '../../utils';
import { MessageDate } from '../date';
import { Message } from '../message';
import { MessageInput } from '../message-input';
import { CustomChatScroll, CustomChatScrollRef } from '../scroll';
import style from './index.module.scss';

interface Props {
  conversationID: string;
  isNew: boolean;
  isLoading: boolean;
  messages: ConversationMessage[];
  appUserID: string;
  className?: string;
  Input?: React.ReactNode;
}

export const CustomMessagesContent: React.FC<Props> = ({
  messages,
  className,
  conversationID,
  isNew,
  appUserID,
  isLoading,
  Input,
}) => {
  const splitMessages = useMemo(() => {
    return splitBy(messages, (prev, next) => {
      return new Date(next.messageDate).getDay() !== new Date(prev.messageDate).getDay();
    });
  }, [messages]);
  const { appIdentityUserID } = useCurrentUser();

  const ref = useRef<CustomChatScrollRef>(null);

  useEffect(() => {
    ref.current?.scrollToBottom();
  }, [messages.length]);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { checkIsOnline } = useChatOnlineUsers();

  const InputComponent = Input || (
    <MessageInput isNew={isNew} conversationID={conversationID} userID={appUserID} />
  );

  return (
    <div className={clsx(style.wrap, className)}>
      <CustomChatScroll ref={ref}>
        <div className={clsx(style.content, isMobile && style.hidden)}>
          {splitMessages.map((items, i) => (
            <React.Fragment key={i}>
              <MessageDate date={items[0].messageDate} />
              {items.map((item, i) => {
                return (
                  <Message
                    date={item.messageDate}
                    key={i}
                    personal={item.appUserID === appIdentityUserID}
                    message={item.message}
                    AvatarProps={{
                      src: item.appUserProfilePicture,
                      isOnline: checkIsOnline(item.appUserID),
                      bgColor: false,
                    }}
                  />
                );
              })}
            </React.Fragment>
          ))}
        </div>
      </CustomChatScroll>
      <div className={style.footer}>{InputComponent}</div>
      <AppLoading active={isLoading} />
    </div>
  );
};
