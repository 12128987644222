import { useAppDispatch, useAppSelector } from 'hooks';
import { useCallback, useMemo } from 'react';
import {
  actionChatConversationDelete,
  actionChatConversationOpen,
  selectChatOpenConversation,
  selectChatOpenConversationID,
} from '../store';

export const useChatConversation = () => {
  const dispatch = useAppDispatch();

  const conversationID = useAppSelector(selectChatOpenConversationID);

  const openConversation = useAppSelector(selectChatOpenConversation);

  const isNew = useMemo(() => {
    return openConversation ? openConversation.isNew : true;
  }, [openConversation]);
  const isLoading = useMemo(() => {
    return openConversation?.isLoading || false;
  }, [openConversation]);
  const isTyping = useMemo(() => {
    return openConversation?.isTyping || false;
  }, [openConversation]);
  const isInit = useMemo(() => {
    return openConversation?.isInit || false;
  }, [openConversation]);

  const conversation = useMemo(() => {
    return openConversation ? openConversation.conversation : null;
  }, [openConversation]);
  const messages = useMemo(() => {
    return openConversation ? openConversation.messages : [];
  }, [openConversation]);

  const onSelect = useCallback(
    (dialogID: string) => {
      dispatch(actionChatConversationOpen({ conversationID: dialogID }));
    },
    [dispatch],
  );

  const onClose = useCallback(() => {
    dispatch(actionChatConversationOpen({ conversationID: null }));
  }, [dispatch]);

  const onDelete = useCallback(async () => {
    if (conversationID) {
      await dispatch(actionChatConversationDelete({ conversationID }));
    }
  }, [dispatch, conversationID]);

  return {
    isNew,
    isLoading,
    isTyping,
    isInit,
    conversationID,

    messages,

    conversation,

    onSelect,
    onClose,
    onDelete,
  };
};
