import { ValueFileUploader } from 'utils/file-uploader';
import { PostComment } from '../post-comments';
import { PostLike } from '../post-likes';
import { PostPicture } from '../post-pictures';

const API = 'Articles';

export const API_POSTS = {
  GET_DYNAMIC: `${API}/GetArticlesDynamic`,
  GET: (ID: Post['id']) => `${API}/GetArticle/${ID}`,
  POST: `${API}/PostArticle`,
  PUT: `${API}/UpdateArticle`,
  POST_TAG: `${API}/AddArticleTag`,
  DELETE_TAG: (articleID: string, medicalFieldID: string) =>
    `${API}/DeleteArticleTag/${articleID}/${medicalFieldID}`,
  POST_USER: `${API}/AddArticleUserExpertProfile`,
  DELETE_USER: (articleID: string, userID: string) =>
    `${API}/DeleteArticleUserExpertProfile/${articleID}/${userID}`,
  DELETE: (ID: Post['id']) => `${API}/DeleteArticle/${ID}`,
  GET_ARTICLE_CATEGORIES: `${API}/GetArticleCategoriesDynamic`,
};

export interface Post {
  id: string;
  publishDate: string;
  titleOfNameTitle: string;
  html: string;
  video: string;
  picture: string;
  userExpertProfileID: string;
  firstName: string;
  lastName: string;
  userPhoto: string;
  position: string;
  readonly fullName: string;
  readonly following: boolean;
  pharmaCompanyUserID: string;
  companyName: string;
  logo: string;
  readonly expertArticle: boolean;
  displayForAll: boolean;
  readonly isUserReact: boolean;
  readonly isOwner: boolean;
  totalInteractives: number;
}
export interface PostDoctor {
  appIdentityUserID: string;
  color: string;
  firstName: string;
  fullName: string;
  lastName: string;
  position: string;
  primaryMedicalFieldTitle: string;
  secondaryMedicalFieldID: string;
  titleOfNameID: string;
  titleOfNameTitle: string;
  userPhoto: string;
}

export interface PostInfo extends Post {
  readonly articleCategoryID: string;
  readonly color: string;
  readonly totalComments: number;
  readonly totalReactions: number;
  readonly comments: PostComment[];
  readonly reactions: PostLike[];
  readonly userExpertProfiles: PostDoctor[];
  readonly tags: { articleID: string; tagID: string; title: string }[];
  readonly pictures: (Omit<PostPicture, 'picture'> & { picture: string })[];
}

export class PatchPostModel {
  html: string = '';
  video: string = '';
  picture: ValueFileUploader = '';
  displayForAll: boolean = true;
}

export class PostModel extends PatchPostModel {
  pictures: PostPicture[] = [];
  tagIDs: string[] = [];
  userExpertProfileIDs: string[] = [];
}
