import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { createTheme, ThemeProvider } from '@mui/material';
import React, { memo } from 'react';
import { AppThemeOptions } from 'styles/theme';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';

export const themeRtl = createTheme({ ...AppThemeOptions, direction: 'rtl' });
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
  prepend: true,
});

interface Props {
  children: React.ReactNode;
}

export const AppThemeProvider: React.FC<Props> = memo(({ children }) => {
  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={themeRtl}>{children}</ThemeProvider>
    </CacheProvider>
  );
});
