import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectAccountsUser } from 'store/accounts';

export const useCurrentUser = () => {
  const user = useSelector(selectAccountsUser);

  if (!user) {
    throw new Error('private-only');
  }

  const fullName = useMemo(() => {
    return [user.titleOfNameTitle, user.firstName, user.lastName].filter((v) => !!v).join(' ');
  }, [user.firstName, user.lastName, user.titleOfNameTitle]);
  return useMemo(() => {
    return {
      ...user,
      fullName,
    };
  }, [user, fullName]);
};
