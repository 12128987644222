import { API, createSelectParam } from 'utils';
import { apiRtk, transformResponseDynamic } from '../apiRtk';
import { User } from '../auth';
import { API_EXPERT_PROFILES } from '../expert-profiles';
import { API_POSTS } from '../posts';
import { API_PARAMETERS, BaseParameter, MedicalField, SpeakLanguage, TitleOfName } from './models';

export * from './models';

class Service {
  async getFullStoryCode() {
    return API.get<string>(API_PARAMETERS.GET_FULL_STORY_CODE);
  }
}

export const ServiceParameters = new Service();

type Expert = Pick<
  User,
  'appIdentityUserID' | 'firstName' | 'lastName' | 'fullName' | 'position' | 'userPhoto' | 'color'
>;

type ArticleCategory = BaseParameter & { icon: string };

export const apiParameters = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getTitleOfNames: builder.query<TitleOfName[], void>({
      query() {
        return {
          url: API_PARAMETERS.TITLE_OF_NAMES,
          params: { orderBy: 'rank asc' },
        };
      },
      transformResponse: transformResponseDynamic,
    }),
    getTags: builder.query<BaseParameter[], void>({
      query() {
        return {
          url: API_PARAMETERS.TAGS,
          params: { orderBy: 'title asc' },
        };
      },
      transformResponse: transformResponseDynamic,
    }),
    getCities: builder.query<BaseParameter[], void>({
      query() {
        return {
          url: API_PARAMETERS.CITIES,
          params: { orderBy: 'title asc' },
        };
      },
      transformResponse: transformResponseDynamic,
    }),
    getDepartments: builder.query<BaseParameter[], void>({
      query() {
        return {
          url: API_PARAMETERS.DEPARTMENTS,
          params: { orderBy: 'title asc' },
        };
      },
      transformResponse: transformResponseDynamic,
    }),
    getHmos: builder.query<BaseParameter[], void>({
      query() {
        return {
          url: API_PARAMETERS.HMOS,
          params: { orderBy: 'title asc' },
        };
      },
      transformResponse: transformResponseDynamic,
    }),
    getHospitals: builder.query<BaseParameter[], void>({
      query() {
        return {
          url: API_PARAMETERS.HOSPITALS,
          params: { orderBy: 'title asc' },
        };
      },
      transformResponse: transformResponseDynamic,
    }),
    getMedicalFields: builder.query<MedicalField[], void>({
      query() {
        return {
          url: API_PARAMETERS.MEDICAL_FIELDS,
          params: { orderBy: 'title asc' },
        };
      },
      transformResponse: transformResponseDynamic,
    }),
    getExperts: builder.query<Expert[], void>({
      query() {
        return {
          url: API_EXPERT_PROFILES.GET_DYNAMIC,
          params: {
            select: createSelectParam<User>(
              'firstName',
              'lastName',
              'fullName',
              'appIdentityUserID',
              'position',
              'userPhoto',
              'color',
            ),
            orderBy: 'firstName,lastName asc',
            take: 50,
          },
        };
      },
      transformResponse: transformResponseDynamic,
    }),
    getSpeakLanguages: builder.query<SpeakLanguage[], void>({
      query() {
        return {
          url: API_PARAMETERS.SPEAK_LANGUAGES,
          params: { orderBy: 'rank asc' },
        };
      },
      transformResponse: transformResponseDynamic,
    }),
    getWorkPlaces: builder.query<BaseParameter[], void>({
      query() {
        return {
          url: API_PARAMETERS.WORK_PALACES,
          params: { orderBy: 'title asc' },
        };
      },
      transformResponse: transformResponseDynamic,
    }),
    getPostCategories: builder.query<ArticleCategory[], void>({
      query() {
        return {
          url: API_POSTS.GET_ARTICLE_CATEGORIES,
          params: { select: 'articleCategoryID as id,title,icon,rank', orderBy: 'rank desc' },
        };
      },
      transformResponse: transformResponseDynamic,
    }),
  }),
});
