const API = 'Accounts';

export const DOCTOR_EXPERT_TYPE_ID = '2dfbd386-de2b-4789-b7c3-7fcbfc040a7e';

export const API_AUTH = {
  LOGIN_OR_REGISTER_NEW_USER: `${API}/LoginOrRegisterNewUser`,
  LOGIN_WITH_CODE: `${API}/LoginWithCode`,
  LOGOUT: `${API}/Logout`,
  GET_USER: `${API}/GetCurrentAppUser`,
  REFRESH_TOKEN: `${API}/RefreshToken`,
  VALIDATE_LICENSE: `${API}/ValidateLicense`,
  VALIDATE_EXPERT_NAME: `${API}/ValidateExpertName`,
  GET_TARGET_AUDIENCES: `${API}/GetTargetAudiencesForMedicalEventDynamic`,
  GET_EXPERT_TYPES: `${API}/GetExpertTypesDynamic`,
  WELCOME_USER: `${API}/WelcomeUser`,
  GET_LICENSE_INFO: `${API}/GetLicenseInfo`,
};

export enum USER_STATUS_KEY {
  ACTIVE = 'active',
  ONBOARD_NETWORK = 'onboard-network',
  ONBOARD_PHARMA = 'onboard-pharma',
  PENDING_INVITATION = 'pending-invitation',
}

export enum USER_STATUS_ID {
  ACTIVE = '3f9af680-8fdc-41d8-b1a4-252b9da22917',
  ONBOARD_NETWORK = '4ec972a8-4af1-4344-9ff8-c8ddaca3aaa4',
  ONBOARD_PHARMA = '033b6445-bff7-4db1-8330-86b5653b3637',
  PENDING_INVITATION = 'b2458f6d-e26f-4229-bc43-2d0f06aef3ae',
}

export interface LicenseInfo {
  externalCertificateLicenseID: string;
  externalCertificateFirstName: string;
  externalCertificateLastName: string;
  expertTypeTitle: string;
}

export interface JWT {
  jwt: {
    token: string;
    refreshToken: string;
  };
  expires: string;
  onboardingStatus: string;
  physician: boolean;
}

export class UserPermissions {
  physician: boolean = false;
  visitor: boolean = false;
  isAllowViewDashboard: boolean = false;
  isAllowViewTags: boolean = false;
  isAllowViewMedicalEvent: boolean = false;
  isAllowRegisterMedicalEvent: boolean = false;
  isAllowViewArticle: boolean = false;
  isAllowPostArticle: boolean = false;
  isAllowReactArticle: boolean = false;
  isAllowCommentArticle: boolean = false;
  isAllowViewSurvey: boolean = false;
  isAllowVoteSurvey: boolean = false;
  isAllowViewPhysician: boolean = false;
  isAllowChatPhysician: boolean = false;
  isAllowSearchPhysician: boolean = false;
  isAllowFollowPhysician: boolean = false;
  isAllowViewPharmaCompanyExpert: boolean = false;
  isAllowViewComment = false;
  isAllowViewReaction = false;
  isAllowViewWebinars = false;
  isRequireLicenseVerification: boolean = false;
}

export class UserWorkPlaces {
  hmoName: string = '';
  hmoCityID: string = '';
  hmoID: string = '';
  hospitalPosition: string = '';
  hospitalDepartmentID: string = '';
  hospitalID: string = '';
  privateWorkPlaceName: string = '';
  privateWorkPlaceMedicalPractice: string = '';
  otherWorkPlaceID: string = '';
}

export class User extends UserWorkPlaces {
  color = '';
  appIdentityUserID = '';
  hmoTitle = '';
  firstName = '';
  lastName = '';
  fullName = '';
  expertTypeID?: string = '';
  userStatusID: USER_STATUS_ID = USER_STATUS_ID.ONBOARD_NETWORK;
  titleOfNameID = '';
  titleOfNameTitle = '';
  primaryMedicalFieldID: string = '';
  primaryMedicalFieldTitle: string = '';
  secondaryMedicalFieldID: string = '';
  secondaryMedicalFieldTitle: string = '';
  hmoCityTitle: string = '';
  hospitalTitle: string = '';
  hospitalDepartmentTitle: string = '';
  otherWorkPlaceTitle: string = '';
  fullNameEnglish: string = '';
  userPhoto: string | null = '';
  externalCertificateValid: boolean = true;
  mobilePhone: string = '';
  email: string = '';
  position: string = '';
  externalCertificateFirstName: string = '';
  externalCertificateLastName: string = '';
  externalCertificateLicenseID: string = '';
  isAllowSMS: boolean = false;
  isActive: boolean = true;
  userExpertProfileSpeakLanguageTitles: string = '';
  isHospital: boolean = false;
  isHmo: boolean = false;
  isPrivateWorkPlaceName: boolean = false;
  isPharmaCompanyExpert: boolean = false;
  pharmaCompanyExpertSubject: string = '';
  following: boolean = false;
  follower: boolean = false;
  isPhysician: boolean = false;
  userStatusKey!: USER_STATUS_KEY;
  userPermission: UserPermissions = new UserPermissions();
}

export class UserWithMixins extends User {
  languages!: { id: string; speakLanguageID: string }[];
}

export interface TargetAudience {
  id: string;
  expertTypeID: string;
  expertTypeTitle: string;
  isRequireLicenseVerification: boolean;
  visitor: boolean;
  rank: 0;
}

export interface ExpertType {
  id: string;
  title: string;
  expertTypeTitle: string;
  licensePrefix: string;
  externalLinkURL: null | string;
  externalCertificateLicenseTypeID: number;
}
