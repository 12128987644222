export const onChangeNumber = (e: any) => {
  const value = e?.target?.value || '';
  return String(value).replace(new RegExp(/[^0-9]/gi), '');
};

export const checkIsHebrew = (value?: string) => {
  return new RegExp(/[\u0590-\u05FF ,.'-]/gi).test(String(value || ''));
};

export const prepareRequest = <T extends { [x: string]: any | null } = {}>(data: T) => {
  const keys = Object.keys(data) as (keyof T)[];
  return keys.reduce((acc, key) => {
    // @ts-ignore
    acc[key] = data[key] === '' ? null : data[key];
    return acc;
  }, data);
};
