const API = 'Parameters';

export const API_PARAMETERS = {
  TITLE_OF_NAMES: `${API}/GetTitleOfNamesDynamic`,
  TAGS: `${API}/GetTagsDynamic`,
  CITIES: `${API}/GetCitiesDynamic`,
  DEPARTMENTS: `${API}/GetDepartmentsDynamic`,
  HMOS: `${API}/GetHmosDynamic`,
  HOSPITALS: `${API}/GetHospitalsDynamic`,
  MEDICAL_FIELDS: `${API}/GetMedicalFieldsDynamic`,
  SPEAK_LANGUAGES: `${API}/GetSpeakLanguagesDynamic`,
  WORK_PALACES: `${API}/GetWorkPlacesDynamic`,
  GET_FULL_STORY_CODE: `${API}/GetFullStoryCode`,
};

export interface BaseParameter {
  id: string;
  title: string;
}

export interface SpeakLanguage extends BaseParameter {
  rank: number;
}
export interface TitleOfName extends BaseParameter {
  rank: number;
}
export interface MedicalField extends BaseParameter {
  isForDoctorOnly: boolean;
}
