import { createSelector } from 'reselect';
import { createObjectMap } from '../../utils';
import { AppState } from '../index';

export const selectTagsState = (state: AppState) => state.tags;

export const selectCheckedTagIDs = createSelector(selectTagsState, ({ checkedTagIDs }) => {
  return checkedTagIDs;
});
export const selectHasCheckedTagIDs = createSelector(selectCheckedTagIDs, (checkedTagIDs) => {
  return checkedTagIDs.length > 0;
});
export const selectMapCheckedTagIDs = createSelector(selectCheckedTagIDs, (tagsIDs) => {
  const source = tagsIDs.map((tagID) => ({ tagID }));
  return createObjectMap(source, 'tagID');
});
