import { API, BASE_URL } from 'utils';
import { apiRtk, transformResponseDynamic } from '../apiRtk';
import { API_CURRENT_USER } from '../current-user';
import {
  API_AUTH,
  ExpertType,
  JWT,
  LicenseInfo,
  TargetAudience,
  User,
  UserWorkPlaces,
} from './models';

export * from './models';

class Service {
  async refreshToken(p: { Token?: string; RefreshToken?: string }) {
    const result = await API.post<JWT['jwt']>(API_AUTH.REFRESH_TOKEN, p);

    const {
      data: { token },
    } = result;
    const testResponse = await fetch(`${BASE_URL}/api/${API_CURRENT_USER.GET_USER}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/plain',
        Authorization: 'Bearer ' + token,
      },
    });

    if (!testResponse.ok) {
      const text = await testResponse.text();
      throw new Error(testResponse.statusText || text.replace(/"/gi, ''));
    }
    return result;
  }

  async signIn(data: {
    email: string;
    mobilePhone: string;
    expertTypeID?: string;
    medicalEventID?: string;
  }) {
    return API.post(API_AUTH.LOGIN_OR_REGISTER_NEW_USER, data);
  }

  async loginWithCode(p: { mobilePhone: string; Code: string }) {
    return API.post<JWT>(API_AUTH.LOGIN_WITH_CODE, p);
  }

  async getLicenseInfo() {
    return API.get<LicenseInfo>(API_AUTH.GET_LICENSE_INFO);
  }

  async logout() {
    return API.post<User>(API_AUTH.LOGOUT);
  }

  async validateLicense(p: Pick<User, 'externalCertificateLicenseID'>) {
    return API.post<'done' | 'not-valid'>(API_AUTH.VALIDATE_LICENSE, p);
  }

  async validateExpertName(p: { firstName?: string; lastName?: string }) {
    return API.post(API_AUTH.VALIDATE_EXPERT_NAME, p);
  }

  async welcomeUser() {
    return API.get(API_AUTH.WELCOME_USER);
  }

  isValidWorkPlaces(fields: UserWorkPlaces): boolean {
    return [
      [fields.hmoID, fields.hmoCityID, fields.hmoName].every((v) => !!v),
      [fields.hospitalID, fields.hospitalDepartmentID, fields.hospitalPosition].every((v) => !!v),
      [fields.privateWorkPlaceName, fields.privateWorkPlaceMedicalPractice].every((v) => !!v),
      !!fields.otherWorkPlaceID,
    ].some((v) => v);
  }
}

export const ServiceAuth = new Service();

export const apiAuth = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getExpertTypes: builder.query<ExpertType[], void>({
      query: () => ({
        url: API_AUTH.GET_EXPERT_TYPES,
        params: {
          orderBy: 'title asc',
        },
      }),
      transformResponse: transformResponseDynamic,
    }),
    getTargetAudiences: builder.query<(TargetAudience & { title: string })[], string>({
      query: (medicalEventID) => {
        const originFields = [
          'id',
          'expertTypeID',
          'expertTypeTitle',
          'isRequireLicenseVerification',
          'visitor',
          'rank',
        ];
        return {
          url: API_AUTH.GET_TARGET_AUDIENCES,
          params: {
            select: [...originFields, 'expertTypeTitle as title'].join(','),
            medicalEventID,
            orderBy: 'rank asc',
          },
        };
      },
      transformResponse: transformResponseDynamic,
    }),
  }),
});
