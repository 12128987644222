import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { patchUserActions } from 'store/accounts';
import { UserWithMixins } from '../services/auth';
import { replaceNull, setToModel } from '../utils';
import { useAppDispatch } from './use-app-dispatch';
import { useCurrentUser } from './use-current-user';

export const useUserForm = () => {
  const dispatch = useAppDispatch();
  const user = useCurrentUser();
  const form = useForm({
    shouldUnregister: true,
    defaultValues: setToModel(
      new UserWithMixins(),
      replaceNull({
        ...user,
      }),
    ),
  });
  const { trigger, getValues } = form;
  const autoSave = useCallback(
    async (name: keyof UserWithMixins) => {
      const isValid = await trigger(name);
      if (!isValid) return;
      const val = getValues(name);
      dispatch(patchUserActions.request({ [name]: val }));
    },
    [trigger, getValues, dispatch],
  );
  return { autoSave, ...form };
};
