export const getRandomNumber = (min: number = 0, max: number = 1): number => {
  return min + Math.floor((max - min + 1) * Math.random());
};
export const getRandomString = (length = 10, chars = 'aA') => {
  let mask = '';
  if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
  if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  if (chars.indexOf('#') > -1) mask += '0123456789';
  if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
  let result = '';
  for (let i = length; i > 0; --i) result += mask[Math.floor(Math.random() * mask.length)];
  return result;
};
export const throttle = <T extends Function>(callback: T, limit: number) => {
  let waiting = false;
  let latestArguments: Array<any> | null = null;
  let latestContext: any | null = null;

  function wrapper(...args: any[]) {
    if (waiting) {
      latestArguments = args;
      // @ts-ignore
      latestContext = this;
      return;
    }

    // @ts-ignore
    callback.apply(this, args);

    waiting = true;

    setTimeout(function () {
      waiting = false;

      if (latestArguments) {
        callback.apply(latestContext, latestArguments);

        latestArguments = null;
        latestContext = null;
      }
    }, limit);
  }

  return wrapper;
};
export type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;
export const debounce = <T extends Function>(cb: T, delay: number) => {
  let timerID: NodeJS.Timeout | null = null;
  return (...args: ArgumentTypes<T>) => {
    if (timerID) {
      clearTimeout(timerID);
    }
    timerID = setTimeout(() => {
      cb(...args);
      timerID = null;
    }, delay);
  };
};
export const calcPaginationSkip = ({ page, take }: { take: number; page: number }) => {
  return take * (page - 1);
};
export const calcPaginationState = ({
  take,
  page,
  count,
}: {
  take: number;
  count: number;
  page: number;
}) => {
  const skip = calcPaginationSkip({ take, page });
  const pages = Math.ceil(count / take);
  const isLastPage = pages === page || count === 0;
  return {
    take,
    page,
    count,
    pages,
    skip,
    isLastPage,
  };
};

export const base64ToFileStream = (base64: string) => {
  return base64.split(',')[1];
};
const PATTERN_EMAIL = '[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,4}';
/**
 * @desc Check email
 * @param email {String}
 * @returns {Boolean}
 */
export const checkEmail = (email: string) => {
  email = String(email);
  let pattern = new RegExp(`^${PATTERN_EMAIL}$`, 'i');
  return pattern.test(email);
};
/**
 * @desc Find emails into text
 * @param text {String}
 * @returns {Array<String>}
 */
export const findEmails = (text: string) => {
  text = '  ' + String(text) + '  ';
  let pattern = new RegExp(`(?<=\\W)${PATTERN_EMAIL}(?=\\W)`, 'ig');
  let result = text.match(pattern) || [];
  return result.filter((match) => checkEmail(match));
};
export const replaceNull = <T extends Record<string, any>>(data: T) => {
  const keys = Object.keys(data) as Array<keyof T>;
  return keys.reduce((acc, key) => {
    // @ts-ignore
    acc[key] = data[key] === null ? '' : data[key];
    return acc;
  }, {} as T);
};

const replaceLabel = (value: string, replacer: (v: string) => string) => {
  return String(value).replace(/#.+?#/gi, (v: string) => {
    const key = v.substring(1, v.length - 1);
    return replacer(key);
  });
};
export const calcRequestLabel = (value: string, replacer: (v: string) => string) => {
  const _value = String(value);
  if (RegExp(/^@.+@$/gi).test(_value)) {
    return replaceLabel(_value.substring(1, _value.length - 1), replacer);
  } else {
    return replacer(_value);
  }
};

const defaultDecorator = () => {};
export const decorateAfter = <T extends (...args: any[]) => any>(
  cb: T,
  decorator: (...args: any[]) => any = defaultDecorator,
) => {
  return (...args: any[]) => {
    const result = cb(...args);
    decorator && decorator(...args);
    return result;
  };
};

export const fieldToLabelKey = <T extends Record<string, any> = any>(field: keyof T) => {
  return String(field)
    .replace(/ID/g, '')
    .replace(/[A-Z]/g, (substring) => {
      return `-${substring}`;
    })
    .toLowerCase()
    .replace(/^-/gi, '');
};
