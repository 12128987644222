import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import {
  Autocomplete,
  AutocompleteFreeSoloValueMapping,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  AutocompleteValue,
  TextFieldProps,
} from '@mui/material';
import { AppInput } from 'components/app-input';
import { useMap } from 'hooks/use-map';
import { useTranslate } from 'hooks/use-translate';
import React, { forwardRef, memo, useCallback, useMemo } from 'react';
import { SelectListBox, SelectPaper, SelectPopper } from './components';

const defOptions: any[] = [];

const defaultGetOptionDisabled = <T extends Record<string, any> = { isActive?: boolean }>(
  option: T,
) => {
  return option?.isActive === false;
};

export interface AppSelectProps<
  T extends Record<string, any> = { id: string; title: string; isActive?: boolean },
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
> extends Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'value' | 'options' | 'onChange' | 'renderInput'
  > {
  label?: React.ReactNode;
  options?: T[];
  value?: Multiple extends true ? any[] : any;
  onChange?: (v: Multiple extends true ? any[] : any, option: T) => void;
  optionLabel?: keyof T;
  optionValue?: keyof T;
  error?: boolean;
  helperText?: React.ReactNode;
  required?: boolean;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  RenderInputProps?: Partial<Omit<TextFieldProps, 'value' | 'onChange'>>;
}

const AppSelectComponent = <
  T extends Record<string, any> = { id: string; title: string; isActive: boolean },
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>(
  {
    onChange,
    value,
    optionLabel = 'title',
    optionValue = 'id',
    options = defOptions,
    error,
    helperText,
    loading = false,
    label,
    required,
    placeholder,
    RenderInputProps,
    getOptionDisabled = defaultGetOptionDisabled,
    limitTags = -1,
    ...rest
  }: AppSelectProps<T, Multiple, DisableClearable, FreeSolo>,
  ref: React.ForwardedRef<HTMLUListElement>,
) => {
  const InputProps = useMemo(() => {
    return {
      required,
      label,
      placeholder,
      error,
      helperText,
      ...RenderInputProps,
      inputRef: ref,
      inputProps: {
        autoComplete: 'nope',
        ...RenderInputProps?.inputProps,
      },
    };
  }, [required, label, placeholder, error, helperText, RenderInputProps, ref]);

  const _renderInput = useCallback(
    (p: AutocompleteRenderInputParams) => {
      return (
        <AppInput
          {...p}
          {...InputProps}
          inputProps={{
            ...p.inputProps,
            ...InputProps.inputProps,
          }}
          InputProps={{
            ...p.InputProps,
            ...InputProps?.InputProps,
          }}
        />
      );
    },
    [InputProps],
  );

  const _getOptionLabel = useCallback(
    (option: T | AutocompleteFreeSoloValueMapping<FreeSolo>) => {
      // @ts-ignore
      const value = option[optionLabel];
      return String(value || '');
    },
    [optionLabel],
  );

  const handleChange = useCallback(
    (
      event: React.SyntheticEvent,
      option: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>,
    ) => {
      if (Array.isArray(option)) {
        // @ts-ignore
        const value = option.map((optionItem) => optionItem[optionValue]).filter((v) => !!v);
        onChange && onChange(value, option as any);
      } else {
        // @ts-ignore
        const value = (option && option[optionValue]) || ('' as any);
        onChange && onChange(value, option as any);
      }
    },
    [onChange, optionValue],
  );

  const sourceMap = useMap(options, optionValue);

  const selectedValue = useMemo(() => {
    if (Array.isArray(value)) {
      const values = value || [];
      return values.map((id: any) => sourceMap[id]).filter((v: any) => !!v);
    } else if (value) {
      return sourceMap[value] || null;
    } else {
      return null;
    }
  }, [value, sourceMap]) as AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>;

  const { t } = useTranslate();
  const noOptionsText = t('no-options');

  return (
    <Autocomplete<T, Multiple, DisableClearable, FreeSolo>
      value={selectedValue}
      noOptionsText={noOptionsText}
      getOptionDisabled={getOptionDisabled}
      PopperComponent={SelectPopper}
      ListboxComponent={SelectListBox}
      {...rest}
      renderInput={rest.renderInput || _renderInput}
      options={options}
      getOptionLabel={_getOptionLabel}
      onChange={handleChange}
      PaperComponent={SelectPaper}
      loading={loading}
      popupIcon={
        <ArrowDropDownRoundedIcon color={'inherit'} style={{ color: 'rgba(0,0,0,0.3)' }} />
      }
      limitTags={limitTags}
    />
  );
};

export const AppSelect = memo(forwardRef(AppSelectComponent)) as typeof AppSelectComponent;
