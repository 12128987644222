import { useEffect, useState } from 'react';
import { convertToDate, DateValue } from '../utils';

interface Options {
  updateInterval?: number | false;
}
export const useStateDate = (date: DateValue, options?: Options) => {
  const { updateInterval = false } = options || {};
  const [state, setState] = useState(() => convertToDate(date));

  useEffect(() => {
    if (updateInterval === false) return;

    const intervalID = setInterval(() => {
      setState(convertToDate(date));
    }, updateInterval);

    return () => {
      clearInterval(intervalID);
    };
  }, [updateInterval, date]);

  return [state, setState] as const;
};
