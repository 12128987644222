import { useAppDispatch, useMountedRef } from 'hooks';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { actionChatSendMessage, actionChatStartConversation } from '../store';

interface Props {
  isNew: boolean;
  conversationID: string;
  appUserID: string;
}

const defaultValues = {
  message: '',
};

type Model = typeof defaultValues;

export const useChatSendMessage = ({ conversationID, appUserID, isNew }: Props) => {
  const { control, handleSubmit, reset } = useForm({ defaultValues });
  const [key, setKey] = useState(1);
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const mountedRef = useMountedRef();

  const onSend = useCallback(
    async (data: Model) => {
      setLoading(true);

      if (isNew) {
        await dispatch(
          actionChatStartConversation({
            conversationID,
            appUserID,
            message: data.message,
          }),
        );
      } else {
        await dispatch(
          actionChatSendMessage({
            conversationID,
            appUserID,
            message: data.message,
          }),
        );
      }

      if (!mountedRef) return;

      reset(defaultValues);
      setKey((prev) => prev + 1);
      setLoading(false);
    },
    [conversationID, dispatch, appUserID, mountedRef, reset, isNew],
  );

  const onSendMessage = handleSubmit(onSend);

  return {
    key,
    control,
    loading,
    onSendMessage,
  };
};
