import { API_RTK_TAGS } from 'services/apiRtk';
import { apiExpertProfiles, ServiceExpertProfiles } from 'services/expert-profiles';
import { call, put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import { parseErrorData } from 'utils';
import { workerErrorNotifySaga, workerErrorNotifyThunk } from 'utils/sagas';
import { actionFollowingAdd, actionFollowingRemove } from './actions';
import { selectFollowingPagination } from './selectors';
import { actionFollowingGet } from './slice';

function* getFollowing() {
  const { take, skip } = yield* select(selectFollowingPagination);

  try {
    const {
      data: { value, count },
    } = yield call(ServiceExpertProfiles.getFollowing, {
      take,
      skip,
    });
    yield put(actionFollowingGet.success({ data: value, count }));
  } catch (e: any) {
    yield put(actionFollowingGet.fail({ error: parseErrorData(e) }));
  }
}
function* refreshRTK(
  action: ReturnType<typeof actionFollowingAdd.fulfilled | typeof actionFollowingRemove.fulfilled>,
) {
  const {
    payload: { id },
  } = action;

  yield* put(apiExpertProfiles.util.invalidateTags([{ type: API_RTK_TAGS.USER_INFO, id }]));
}

export const sagasFollowing = [
  takeLatest(actionFollowingGet.request, getFollowing),
  takeLatest([actionFollowingGet.fail], workerErrorNotifySaga),
  takeEvery([actionFollowingAdd.fulfilled, actionFollowingRemove.fulfilled], refreshRTK),
  takeEvery([actionFollowingAdd.rejected, actionFollowingRemove.rejected], workerErrorNotifyThunk),
];
