import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setToModel } from 'utils';
import { combineActions } from 'utils/sagas';
import { Conversation, ConversationMessage } from '../service';
import {
  actionChatConversationDelete,
  actionChatConversationGet,
  actionChatGetMessages,
  actionChatMessageGet,
  actionChatReadConversation,
  actionChatSendMessage,
  actionChatStartConversation,
} from './actions';

const createNewConversationID = (data: Pick<PayloadStartChat, 'appUserID'>) => {
  return `****__${data.appUserID}__****`;
};

interface PayloadStartChat {
  appUserID: string;
  firstName: string;
  lastName: string;
  titleOfName: string;
  profilePicture?: string;
  position?: string;
}

class StateItem {
  isLoading: boolean = false;
  isInit: boolean = false;
  isTyping: boolean = false;
  isNew: boolean = false;

  conversation!: Conversation;
  messages: ConversationMessage[] = [];
}

interface State {
  openConversationID: string | null;

  data: StateItem[];

  isOpen: boolean;
  isLoading: boolean;
  isInit: boolean;
  error: null | Error;

  users: { [appUserID: string]: boolean };
}

const initialState: State = {
  openConversationID: null,

  isOpen: false,
  isLoading: false,
  isInit: false,
  error: null,

  data: [],
  users: {},
};

const slice = createSlice({
  name: 'CUSTOM_CHAT',
  initialState,
  reducers: {
    actionCustomChatUsersListSet(state, action: PayloadAction<string[]>) {
      const userIDs = Array.from(new Set(action.payload).keys());
      state.users = userIDs.reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {} as Record<string, boolean>);
    },
    actionChatUserConnected(state, action: PayloadAction<string>) {
      state.users[action.payload] = true;
    },
    actionChatUserDisconnected(state, action: PayloadAction<string>) {
      delete state.users[action.payload];
      state.data = state.data.map((item) =>
        item.conversation.appUserID === action.payload ? { ...item, isTyping: false } : item,
      );
    },

    actionChatConversationOpen(state, action: PayloadAction<{ conversationID: string | null }>) {
      const { conversationID } = action.payload;
      state.openConversationID = conversationID;
    },

    actionChatTyping(
      state,
      action: PayloadAction<{ conversationID: string; appUserID: string; value: boolean }>,
    ) {},
    actionChatTypingCame(
      state,
      action: PayloadAction<{ conversationID: string; typing: boolean }>,
    ) {
      const { conversationID, typing } = action.payload;
      state.data = state.data.map((item) =>
        item.conversation.conversationID === conversationID ? { ...item, isTyping: typing } : item,
      );
    },

    actionChatStartChat(state, action: PayloadAction<PayloadStartChat>) {
      state.isOpen = true;
    },
    actionChatStartChatNew(state, action: PayloadAction<PayloadStartChat>) {
      const { appUserID, firstName, lastName, position, profilePicture, titleOfName } =
        action.payload;

      const newConversationID = createNewConversationID({ appUserID });

      const newOne = setToModel(new StateItem(), {
        isInit: true,
        isNew: true,
        conversation: setToModel(new Conversation(), {
          conversationID: newConversationID,
          appUserTitleOfName: titleOfName,
          appUserID,
          appUserFirstName: firstName,
          appUserLastName: lastName,
          appUserPosition: position,
          appUserProfilePicture: profilePicture,
        }),
      });
      state.data = [...state.data, newOne];
      state.openConversationID = newConversationID;
    },
    actionChatSwitchChat(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    },
    actionChatConversationWasDeleted(state, action: PayloadAction<{ conversationID: string }>) {
      const { conversationID } = action.payload;
      state.openConversationID =
        conversationID === state.openConversationID ? null : state.openConversationID;
      state.data = state.data.filter(
        ({ conversation }) => conversation.conversationID !== conversationID,
      );
    },

    actionChatCameMessage(
      state,
      action: PayloadAction<{ conversationID: string; messageID: string }>,
    ) {
      const { conversationID } = action.payload;
      state.data = state.data.map((item) =>
        item.conversation.conversationID === conversationID
          ? { ...item, conversation: { ...item.conversation, notRead: true } }
          : item,
      );
    },

    requestGetConversations(state) {
      state.isLoading = true;
      state.isInit = true;
    },
    successGetConversations(state, action: PayloadAction<{ data: Conversation[] }>) {
      const { data } = action.payload;
      state.isLoading = false;
      state.isInit = true;
      state.data = data.map((conversation) => setToModel(new StateItem(), { conversation }));
    },
    failGetConversations(state, action: PayloadAction<{ error: Error }>) {
      state.isLoading = false;
      state.isInit = true;
      state.error = action.payload.error;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actionChatReadConversation.fulfilled, (state, action) => {
      const { conversationID } = action.payload;
      state.data = state.data.map((item) =>
        item.conversation.conversationID === conversationID
          ? { ...item, conversation: { ...item.conversation, notRead: false } }
          : item,
      );
    });

    builder.addCase(actionChatGetMessages.pending, (state, action) => {
      const { conversationID } = action.meta.arg;
      state.data = state.data.map((item) =>
        item.conversation.conversationID === conversationID
          ? { ...item, isLoading: true, error: null }
          : item,
      );
    });
    builder.addCase(actionChatGetMessages.fulfilled, (state, action) => {
      const { conversationID, messages } = action.payload;
      state.data = state.data.map((item) =>
        item.conversation.conversationID === conversationID
          ? { ...item, isLoading: false, isInit: true, messages }
          : item,
      );
    });
    builder.addCase(actionChatGetMessages.rejected, (state, action) => {
      const { conversationID } = action.meta.arg;
      const error = action.error;
      state.data = state.data.map((item) =>
        item.conversation.conversationID === conversationID
          ? { ...item, error, isLoading: false, isInit: true }
          : item,
      );
    });

    builder.addCase(actionChatConversationDelete.pending, (state, action) => {
      const { conversationID } = action.meta.arg;
      state.data = state.data.map((item) =>
        item.conversation.conversationID === conversationID
          ? { ...item, isLoading: true, error: null }
          : item,
      );
    });
    builder.addCase(actionChatConversationDelete.fulfilled, (state, action) => {
      const { conversationID } = action.meta.arg;
      state.data = state.data.filter((item) => item.conversation.conversationID !== conversationID);
      state.openConversationID =
        conversationID === state.openConversationID ? null : state.openConversationID;
    });
    builder.addCase(actionChatConversationDelete.rejected, (state, action) => {
      const { conversationID } = action.meta.arg;
      const error = action.error;
      state.data = state.data.map((item) =>
        item.conversation.conversationID === conversationID
          ? { ...item, isLoading: false, error }
          : item,
      );
    });

    builder.addCase(actionChatStartConversation.fulfilled, (state, action) => {
      const { fakeConversationID, conversation, message } = action.payload;

      state.data = [
        ...state.data.filter(
          ({ conversation }) => conversation.conversationID !== fakeConversationID,
        ),
        setToModel(new StateItem(), { isInit: true, conversation, messages: [message] }),
      ];

      state.openConversationID =
        state.openConversationID === fakeConversationID
          ? conversation.conversationID
          : state.openConversationID;
    });
    builder.addCase(actionChatSendMessage.fulfilled, (state, action) => {
      const { conversationID, message } = action.payload;

      state.data = state.data.map((item) =>
        item.conversation.conversationID === conversationID
          ? {
              ...item,
              conversation: {
                ...item.conversation,
                lastMessage: message.message,
                lastUpdatedDate: message.messageDate,
                lastMessageFirstName: message.appUserFirstName,
              },
              messages: [...item.messages, message],
            }
          : item,
      );
    });

    builder.addCase(actionChatConversationGet.fulfilled, (state, action) => {
      const { conversationID, conversation } = action.payload;
      const itemIndex = state.data.findIndex(
        ({ conversation }) => conversation.conversationID === conversationID,
      );

      if (itemIndex !== -1) {
        state.data = Array.from(state.data).splice(itemIndex, 1, {
          ...state.data[itemIndex],
          conversation,
        });
      } else {
        state.data = [...state.data, setToModel(new StateItem(), { conversation })];
      }
    });
    builder.addCase(actionChatMessageGet.fulfilled, (state, action) => {
      const { conversationID, message } = action.payload;

      state.data = state.data.map((item) =>
        item.conversation.conversationID === conversationID
          ? {
              ...item,
              conversation: {
                ...item.conversation,
                lastMessage: message.message,
                lastUpdatedDate: message.messageDate,
                lastMessageFirstName: message.appUserFirstName,
              },
              messages: [...item.messages, message],
            }
          : item,
      );
    });
  },
});

const actions = slice.actions;
export const {
  actionCustomChatUsersListSet,
  actionChatConversationOpen,
  actionChatSwitchChat,
  actionChatStartChat,
  actionChatStartChatNew,
  actionChatConversationWasDeleted,
  actionChatCameMessage,
  actionChatTyping,
  actionChatTypingCame,
  actionChatUserConnected,
  actionChatUserDisconnected,
} = actions;

export const actionChatGetConversations = combineActions(
  actions.requestGetConversations,
  actions.successGetConversations,
  actions.failGetConversations,
);

export const chatReducer = slice.reducer;
