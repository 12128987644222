import { createSelector } from 'reselect';
import { AppState } from '../index';

export const selectFollowingState = (state: AppState) => state.following;

export const selectFollowingUsers = createSelector(selectFollowingState, ({ data }) => {
  return data;
});

export const selectFollowingLoading = createSelector(selectFollowingState, ({ isLoading }) => {
  return isLoading;
});
export const selectFollowingPagination = createSelector(
  selectFollowingState,
  ({ take, page, count }) => {
    return {
      take,
      page,
      count,
      pages: Math.ceil(count / take),
      skip: take * (page - 1),
    };
  },
);
