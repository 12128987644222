import { MedicalEvent, ServiceMedEvents } from 'services/medical-events';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { createFilterArrays, createFilterContains, createSelectParam, parseErrorData } from 'utils';
import { selectAccountsPermissions, selectAccountsUser } from '../accounts';
import { selectMedEventsFilters, selectMedEventsPagination } from './selectors';
import { actionMedicalEventsGet, MED_EVENT_CUSTOM_KEYS } from './slice';

const createCustomFilter = ({
  name,
  value,
}: {
  name: string;
  value: MED_EVENT_CUSTOM_KEYS | null;
}) => {
  switch (value) {
    case MED_EVENT_CUSTOM_KEYS.ARCHIVE:
      return `(${name}.date <= DateTime.Now.date)`;
    case MED_EVENT_CUSTOM_KEYS.THREE_MONTHS:
      return `(${name}.date >= DateTime.Now.date && ${name}.date <= DateTime.Now.addMonths(3).date)`;
    case MED_EVENT_CUSTOM_KEYS.YEAR:
      return `(${name}.date >= DateTime.Now.date && ${name}.date <= DateTime.Now.AddYears(1).date)`;
    default:
      return null;
  }
};

const getOrderBy = (custom: MED_EVENT_CUSTOM_KEYS | null): string => {
  switch (custom) {
    case MED_EVENT_CUSTOM_KEYS.ARCHIVE:
      return 'EventDate desc';
    default:
      return 'EventDate';
  }
};

function* fetchEvents() {
  const { search, date, tags } = yield* select(selectMedEventsFilters);
  const { skip, take } = yield* select(selectMedEventsPagination);
  const { isDoctorUser } = yield* select(selectAccountsPermissions);
  const user = yield* select(selectAccountsUser);

  const selectParams = createSelectParam<MedicalEvent>(
    'id',
    'title',
    'picture',
    'eventDate',
    'eventTimeSlot',
    'presenterName',
    'presenterPosition',
    'userRegisterToEvent',
    'eventSponsors',
  );

  const filter = [
    createFilterContains<MedicalEvent>({ name: 'title', value: search }),
    createCustomFilter({ name: 'eventDate', value: date }),
    createFilterArrays<MedicalEvent, 'medicalEventTagIDs'>({
      name: 'medicalEventTagIDs',
      value: tags,
      key: 'id',
    }),
    ...(!isDoctorUser && user?.expertTypeID
      ? [
          createFilterArrays<MedicalEvent, 'medicalEventTargetAudienceIDs'>({
            name: 'medicalEventTargetAudienceIDs',
            key: 'id',
            value: [user.expertTypeID],
          }),
        ]
      : []),
  ]
    .filter(Boolean)
    .join('&&');

  try {
    const {
      data: { value, count },
    } = yield* call(ServiceMedEvents.getAll, {
      select: selectParams,
      filter,
      skip,
      take,
      orderBy: getOrderBy(date),
    });
    yield* put(actionMedicalEventsGet.success({ data: value, count }));
  } catch (e: any) {
    yield* put(actionMedicalEventsGet.fail({ error: parseErrorData(e) }));
  }
}

export const sagasMedEvents = [takeLatest(actionMedicalEventsGet.request, fetchEvents)];
