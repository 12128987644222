import { Avatar, AvatarProps, Badge } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import style from './index.module.scss';

export interface AppAvatarProps extends Omit<AvatarProps, 'src'> {
  bgColor: string | undefined | false;
  isOnline: boolean;
  size?: 'xs' | 'md' | 'xm' | 'lg' | 'xl';
  src?: string | null;
}

export const AppAvatar: React.FC<AppAvatarProps> = ({
  size = 'md',
  bgColor,
  className,
  isOnline = false,
  src,
  ...rest
}) => {
  return (
    <Badge
      variant={'dot'}
      overlap={'circular'}
      invisible={!isOnline}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      className={clsx(style.badge, className, rest.onClick && style.clickable)}
      color={'primary'}
    >
      <Avatar
        style={{ backgroundColor: bgColor ? bgColor : undefined }}
        {...rest}
        src={src || undefined}
        className={clsx(style.avatar, style[`size-${size}`])}
        classes={{ img: style.image }}
      />
    </Badge>
  );
};
