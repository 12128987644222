import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PostInfo } from 'services/posts';
import { combineActions } from 'utils/sagas';
import { actionPostsCreate, actionPostsDelete, actionPostsRefresh } from '../posts';
import { addTag, removeTag, uncheckAllTags, verifyTags } from '../tags';

interface State {
  applyTags: boolean;
  isLoading: boolean;
  isInit: boolean;
  data: PostInfo[];
  error: null | Error;
}

export const initState = (): State => {
  return {
    applyTags: false,
    isLoading: false,
    isInit: false,
    data: [],
    error: null,
  };
};

const slice = createSlice({
  name: 'POSTS_LATEST',
  initialState: initState(),
  reducers: {
    requestGet(state) {
      state.isLoading = true;
      state.error = null;
    },
    successGet(state, action: PayloadAction<{ data: PostInfo[] }>) {
      state.isLoading = false;
      state.isInit = true;
      state.error = null;
      state.data = action.payload.data;
    },
    failGet(state, action: PayloadAction<{ error: Error }>) {
      state.isLoading = false;
      state.isInit = true;
      state.error = action.payload.error;
    },
    actionPostsLatestSetApplyTags(state, action: PayloadAction<boolean>) {
      state.applyTags = action.payload;
      state.isInit = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actionPostsDelete.fulfilled, (state, action) => {
      state.isInit = false;
    });
    builder.addCase(actionPostsCreate.fulfilled, (state, action) => {
      state.isInit = false;
    });
    builder.addCase(actionPostsRefresh.fulfilled, (state, action) => {
      state.data = state.data.map((post) =>
        post.id === action.payload.data.id ? action.payload.data : post,
      );
    });

    builder.addMatcher(
      (action) => {
        return [addTag.type, removeTag.type, verifyTags.type, uncheckAllTags.type].some(
          (itemAction) => itemAction === action.type,
        );
      },
      (state) => {
        state.isInit = state.applyTags ? false : state.isInit;
      },
    );
  },
});

const actions = slice.actions;
export const { actionPostsLatestSetApplyTags } = actions;

export const actionPostsLatestGet = combineActions(
  actions.requestGet,
  actions.successGet,
  actions.failGet,
);

export const reducerPostsLatest = slice.reducer;
