import { ValueFileUploader } from 'utils/file-uploader';

const API = 'ArticlePictures';

export const API_POST_PICTURES = {
  GET_DYNAMIC: `${API}/GetArticlePicturesDynamic`,
  POST: `${API}/AddArticlePicture`,
  DELETE: (ID: string) => `${API}/RemoveArticlePicture/${ID}`,
};

export class PostPicture {
  articlePictureID: string | null = null;
  articleID!: string | null;
  picture: ValueFileUploader = '';
  linkURL?: string = undefined;
  rank = 0;
}
