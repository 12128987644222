import { MedicalEvent, ServiceMedEvents } from 'services/medical-events';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { createFilterArrays, createFilterBoolean, parseErrorData } from 'utils';
import { selectAccountsPermissions, selectAccountsUser } from '../accounts';
import { selectCheckedTagIDs } from '../tags';
import { selectMedEventsLatestApplyTags } from './selectors';
import { actionMedicalEventsLatestGet } from './slice';

function* fetchMedEventsLatest() {
  const tagIDs = yield* select(selectCheckedTagIDs);
  const applyTags = yield* select(selectMedEventsLatestApplyTags);
  const { isDoctorUser } = yield* select(selectAccountsPermissions);
  const user = yield* select(selectAccountsUser);

  let filter = [
    ...(!isDoctorUser && user?.expertTypeID
      ? [
          createFilterArrays<MedicalEvent, 'medicalEventTargetAudienceIDs'>({
            name: 'medicalEventTargetAudienceIDs',
            key: 'id',
            value: [user.expertTypeID],
          }),
        ]
      : []),
    ...(applyTags
      ? [
          '(' +
            [
              createFilterBoolean<MedicalEvent>({ name: 'displayForAll', value: true }),
              `(${[
                createFilterBoolean<MedicalEvent>({ name: 'displayForAll', value: false }),
                createFilterArrays<MedicalEvent, 'medicalEventTagIDs'>({
                  name: 'medicalEventTagIDs',
                  key: 'id',
                  value: tagIDs,
                }),
              ]
                .filter((v) => !!v)
                .join('&&')})`,
            ].join('||') +
            ')',
        ]
      : []),
  ]
    .filter((v) => v)
    .join('&&');

  try {
    const {
      data: { value },
    } = yield* call(ServiceMedEvents.getLatest, {
      filter,
      orderBy: 'EventDate',
    });
    yield put(actionMedicalEventsLatestGet.success({ data: value }));
  } catch (e: any) {
    yield put(actionMedicalEventsLatestGet.fail({ error: parseErrorData(e) }));
  }
}

export const sagasMedEventsLatest = [
  takeLatest(actionMedicalEventsLatestGet.request, fetchMedEventsLatest),
];
