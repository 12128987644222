import { useMap } from 'hooks/use-map';
import { useCallback, useMemo } from 'react';

interface UseSelectBaseProps<T extends Record<string, any> = { id: string }> {
  options?: T[];
}

interface UseSelectSingleProps<T extends Record<string, any> = { id: string }>
  extends UseSelectBaseProps<T> {
  multiple?: false;
  value?: string;
  onChange?: (v: string) => void;
}
interface UseSelectMultipleProps<T extends Record<string, any> = { id: string }>
  extends UseSelectBaseProps<T> {
  multiple: true;
  value?: string[];
  onChange?: (v: string[], option?: T) => void;
  options?: T[];
}

const onChangeDef = () => {};
type UseSelectProps<T extends Record<string, any> = { id: string }> =
  | UseSelectMultipleProps<T>
  | UseSelectSingleProps<T>;

export const useSelect = <T extends Record<string, any> = { id: string }>(
  props: UseSelectProps<T>,
) => {
  const { options = [], multiple, onChange: _onChange = onChangeDef } = props;

  const values = useMemo(() => {
    if (Array.isArray(props.value)) {
      return props.value;
    } else if (props.value) {
      return [props.value];
    } else {
      return [];
    }
  }, [props.value]);
  const mapSelected = useMap(values);

  const onChangeItem = useCallback(
    (v: string) => {
      if (multiple) {
        const newValues = mapSelected[v] ? values.filter((value) => value !== v) : [...values, v];
        // @ts-ignore
        _onChange(newValues);
      } else {
        // @ts-ignore
        _onChange(v);
      }
    },
    [_onChange, multiple, values, mapSelected],
  );

  const isSelectedAll = useMemo(() => {
    return values.length === options.length;
  }, [values.length, options.length]);
  const onSelectAll = useCallback(() => {
    if (multiple) {
      // @ts-ignore
      _onChange(isSelectedAll ? [] : options.map((item) => item.id));
    }
  }, [_onChange, multiple, options, isSelectedAll]);

  return { mapSelected, onChangeItem, onSelectAll, isSelectedAll };
};
