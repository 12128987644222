import clsx from 'clsx';
import { NativeScroll } from 'components/native-scroll';
import React from 'react';
import style from './index.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
}

export const AppDialogContent: React.FC<Props> = ({ className, children }) => {
  return (
    <NativeScroll>
      <div className={clsx(style.wrap, className)}>{children}</div>
    </NativeScroll>
  );
};
