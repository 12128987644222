import { Theme, useMediaQuery } from '@mui/material';
import { useAppDispatch, useAppSelector, usePermissions } from 'hooks';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { selectAccountsState } from 'store/accounts';
import {
  actionChatGetConversations,
  ChatContextProvider,
  CustomChantButton,
  CustomChantLayout,
  selectChatIsOpen,
  selectChatStatuses,
} from '../cutom-chat';
import { ProviderChatAuthUser } from '../cutom-chat/provider/auth-user';

const element = document.body;

interface Props {
  appUserID: string;
}
const AppChat: React.FC<Props> = ({ appUserID }) => {
  const isOpen = useAppSelector(selectChatIsOpen);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('is-chat-open');
    }
    return () => {
      document.body.classList.remove('is-chat-open');
    };
  }, [isOpen]);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { isLoading, isInit } = useAppSelector(selectChatStatuses);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isInit && !isLoading) {
      dispatch(actionChatGetConversations.request());
    }
  }, [isOpen, isInit, isLoading, dispatch]);

  const { hideChatButton } = usePermissions();

  return ReactDOM.createPortal(
    <ProviderChatAuthUser appUserID={appUserID}>
      {!isMobile && !hideChatButton && <CustomChantButton />}
      <CustomChantLayout />
    </ProviderChatAuthUser>,
    element,
  );
};

interface ProviderProps {
  children: React.ReactNode;
}
export const AppChatProvider: React.FC<ProviderProps> = ({ children }) => {
  const { user } = useAppSelector(selectAccountsState);
  const { hideChatComponent } = usePermissions();

  return (
    <ChatContextProvider>
      {!hideChatComponent && user?.appIdentityUserID && (
        <AppChat appUserID={user.appIdentityUserID} />
      )}
      {children}
    </ChatContextProvider>
  );
};
