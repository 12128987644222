import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  LicenseInfo,
  ServiceAuth,
  UserWithMixins,
  USER_STATUS_ID,
  USER_STATUS_KEY,
} from 'services/auth';
import { ServiceCurrentUser } from 'services/current-user';
import { parseErrorData } from 'utils';
import { updateAuthTokens } from 'utils/cookies';
import { AppAsyncThunkConfig } from '../index';
import { selectAccountSignIn, selectAccountsPermissions, selectAccountsUser } from './selectors';

export const actionAuthWelcomeMessage = createAsyncThunk<void, void, AppAsyncThunkConfig>(
  `AUTH/welcome`,
  async () => {
    try {
      await ServiceAuth.welcomeUser();
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);

export const actionAuthLogin = createAsyncThunk<void, { Code: string }, AppAsyncThunkConfig>(
  `AUTH/login`,
  async (payload, { getState, dispatch }) => {
    const { mobilePhone } = selectAccountSignIn(getState());
    try {
      if (!mobilePhone) {
        throw new Error('MobilePhone: Unexpected behaviour');
      }
      const {
        data: {
          jwt: { token, refreshToken },
          expires,
        },
      } = await ServiceAuth.loginWithCode({
        mobilePhone,
        ...payload,
      });
      updateAuthTokens({ token, refreshToken, expires });
      await dispatch(actionAuthGetCurrentUser()).unwrap();
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);

export const actionAuthGetCurrentUser = createAsyncThunk<UserWithMixins, void, AppAsyncThunkConfig>(
  `AUTH/getCurrentUser`,
  async () => {
    try {
      const { data } = await ServiceCurrentUser.getUser();
      return data;
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);

export const validateUserAction = createAsyncThunk<
  AxiosResponse<LicenseInfo>,
  { externalCertificateLicenseID: string },
  AppAsyncThunkConfig
>(`AUTH/validateUserAction`, async (data, { dispatch }) => {
  try {
    const { data: result } = await ServiceAuth.validateLicense(data);
    await dispatch(actionAuthGetCurrentUser());

    if (result === 'not-valid') {
      throw new Error('not-valid');
    }

    return await ServiceAuth.getLicenseInfo();
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionAuthChangeExpertType = createAsyncThunk<
  UserWithMixins,
  { expertTypeID: string },
  AppAsyncThunkConfig
>(`AUTH/actionAuthChangeExpertType`, async ({ expertTypeID }, { dispatch, getState }) => {
  try {
    const oldUser = selectAccountsUser(getState());

    if (!oldUser) {
      throw new Error('user is not exist');
    }

    await ServiceCurrentUser.updateCurrentUser(oldUser, {
      expertTypeID,
      userStatusID: USER_STATUS_ID.ONBOARD_NETWORK,
      externalCertificateLicenseID: '',
    });

    const { data: userData } = await ServiceCurrentUser.getUser();
    return userData;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const submitMainInfoUserAction = createAsyncThunk<
  UserWithMixins,
  UserWithMixins,
  AppAsyncThunkConfig
>(`AUTH/submitMainInfoUserAction`, async (data, { dispatch, getState }) => {
  const { isRequireLicenseVerification } = selectAccountsPermissions(getState());
  const oldUser = selectAccountsUser(getState());

  try {
    if (!oldUser) {
      throw new Error('user-is-not-exist');
    }
    if (isRequireLicenseVerification) {
      await ServiceCurrentUser.updateCurrentUserWithNames(oldUser, data);
    } else {
      await ServiceCurrentUser.updateCurrentUser(oldUser, data);
      await ServiceCurrentUser.setAsActive();
    }

    const { data: updatedUser } = await ServiceCurrentUser.getUser();

    const { isDoctorUser } = selectAccountsPermissions(getState());

    if (
      isDoctorUser &&
      oldUser.userStatusKey !== USER_STATUS_KEY.ACTIVE &&
      updatedUser.userStatusKey === USER_STATUS_KEY.ACTIVE
    ) {
      dispatch(actionAuthWelcomeMessage());
    }
    return updatedUser;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
export const actionAccountLogout = createAsyncThunk<void, void, AppAsyncThunkConfig>(
  `AUTH/logout`,
  async () => {
    try {
      await ServiceAuth.logout();
      updateAuthTokens();
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);
export const actionAccountGenerateCode = createAsyncThunk<
  void,
  { email: string; mobilePhone: string; expertTypeID?: string; medicalEventID?: string },
  AppAsyncThunkConfig
>(`AUTH/generateCode`, async (data) => {
  try {
    await ServiceAuth.signIn(data);
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
