const API = 'MediaUploads';

export const API_MEDIA_UPLOADS = {
  UPLOAD_FILE: `${API}/UploadFileToCloud`,
  UPLOAD_HTML: `${API}/UploadHtmlToCloud`,
  UPLOAD_IMAGE: `${API}/UploadImageToCloud`,
  REMOVE_FILE: (p: { filePath: string }) =>
    `${API}/RemoveFileFromCloud?filePath=${encodeURIComponent(p.filePath)}`,
};

export class MediaUploadFile {
  fileName: string = '';
  fileStreamString: string = '';
  filePath: string = '';
}
export class MediaUploadHtml {
  fileName: string = '';
  htmlContent: string = '';
  filePath: string = '';
}
