import { PostInfo, ServicePosts } from 'services/posts';
import { call, put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import {
  createFilterContains,
  createFilterEquals,
  createFilterNotEquals,
  createFilterSmartSearch,
  parseErrorData,
} from 'utils';
import { workerErrorNotifySaga } from 'utils/sagas';
import { selectAccountsPermissions, selectAccountsUser } from '../accounts';
import {
  actionPostsCreate,
  actionPostsDelete,
  actionPostsEdit,
  ARTICLE_CATEGORY_POSTS_AND_DISCUSSIONS_ID,
  calcPostsOrderBy,
} from '../posts';
import { selectPostsUsersState, selectPostsUsersStatePagination } from './selectors';
import { actionPostsUsersGet, actionPostsUsersGetMore, actionPostsUsersReset } from './slice';

function* getParams(userID: string) {
  const {
    orderBy,
    filters: { search, articleCategoryID },
  } = yield* select(selectPostsUsersState, userID);
  const { skip, take } = yield* select(selectPostsUsersStatePagination, userID);
  const { isAllowViewWebinars } = yield* select(selectAccountsPermissions);

  const filterSearch = [
    createFilterContains<PostInfo>({ name: ['companyName', 'html'], value: search }),
    createFilterSmartSearch<PostInfo>({ name: ['firstName', 'lastName'], value: search }),
  ]
    .filter((v) => !!v)
    .join('||');

  const filterCategory = isAllowViewWebinars
    ? createFilterNotEquals<PostInfo>({
        name: 'articleCategoryID',
        value: ARTICLE_CATEGORY_POSTS_AND_DISCUSSIONS_ID,
      })
    : createFilterEquals<PostInfo>({
        name: 'articleCategoryID',
        value: articleCategoryID === 'ALL' ? null : articleCategoryID,
      });

  const filter = [filterCategory, filterSearch].filter((v) => !!v).join('&&');

  return { filter, take, skip, orderBy: calcPostsOrderBy(orderBy) };
}

function* getPosts(action: ReturnType<typeof actionPostsUsersGet.request>) {
  const { userID } = action.payload;
  const params = yield* getParams(userID);

  try {
    const {
      data: { value, count },
    } = yield* call(ServicePosts.getByUserID, userID, params);
    yield* put(actionPostsUsersGet.success({ userID, data: value, count }));
  } catch (e: any) {
    yield* put(actionPostsUsersGet.fail({ userID, error: parseErrorData(e) }));
  }
}

function* getMorePosts(action: ReturnType<typeof actionPostsUsersGetMore.request>) {
  const { userID } = action.payload;
  const params = yield* getParams(userID);
  try {
    let { data } = yield* call(ServicePosts.getByUserID, userID, params);
    yield* put(actionPostsUsersGetMore.success({ userID, data: data.value, count: data.count }));
  } catch (e: any) {
    yield* put(actionPostsUsersGetMore.fail({ userID, error: parseErrorData(e) }));
  }
}

function* reset() {
  const user = yield* select(selectAccountsUser);
  if (!user) {
    throw new Error('unexpected-behaviour');
  }
  yield* put(actionPostsUsersReset({ userID: user.appIdentityUserID }));
}

export const sagasPostsUsers = [
  takeEvery(
    [actionPostsCreate.fulfilled, actionPostsEdit.fulfilled, actionPostsDelete.fulfilled],
    reset,
  ),
  takeLatest(actionPostsUsersGet.request, getPosts),
  takeLatest(actionPostsUsersGetMore.request, getMorePosts),

  takeEvery([actionPostsUsersGet.fail, actionPostsUsersGetMore.fail], workerErrorNotifySaga),
];
