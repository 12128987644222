import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Label } from 'services/labels';
import { combineActions } from 'utils/sagas';

interface State {
  isInit: boolean;
  isLoading: boolean;
  error: null | Error;
  data: Label[];
}

const initState = (): State => {
  return {
    isInit: false,
    isLoading: false,
    error: null,
    data: [],
  };
};

const slice = createSlice({
  name: 'LABELS',
  initialState: initState(),
  reducers: {
    requestGet(state) {
      state.isLoading = true;
      state.error = null;
    },
    successGet(state, action: PayloadAction<{ data: Label[] }>) {
      state.isLoading = false;
      state.isInit = true;
      state.data = action.payload.data;
    },
    failGet(state, action: PayloadAction<{ error: Error }>) {
      state.isLoading = false;
      state.isInit = true;
      state.error = action.payload.error;
    },
  },
});

const actions = slice.actions;

export const actionLabelsGet = combineActions(
  actions.requestGet,
  actions.successGet,
  actions.failGet,
);

export const reducerLabels = slice.reducer;
