import { format } from 'date-fns';
import { convertToDate, DateValue } from './dates';
import { ArrayType } from './types';

const prepareValue = (value?: string) => {
  return String(value).trim().replace(/"/gi, '\\"');
};
const createStart = (count: number, query: string) => {
  return count > 1 ? `(${query})` : query;
};

type Separator = '==' | '!=' | '>=' | '<=';
export const createFilter = <T>(
  name: keyof T | (keyof T)[],
  separator: Separator,
  value: any | any[],
) => {
  const names = Array.isArray(name) ? name : [name];
  let values = Array.isArray(value) ? value : [value];
  values = values.filter((v) => v !== '' && v !== null && v !== undefined);

  return createStart(
    Math.max(names.length, values.length),
    names
      .map((_n) => values.map((_v) => `${String(_n)}${separator}"${prepareValue(_v)}"`).join('||'))
      .join('||'),
  );
};

export const createFilterArray = <T>(
  name: keyof T | (keyof T)[],
  separator: Separator,
  value: any[],
) => {
  return value.length
    ? createStart(value.length, value.map((v) => createFilter<T>(name, separator, v)).join('||'))
    : undefined;
};
export const createFilterDate = <T extends object = {}>(
  name: keyof T,
  separator: Separator,
  value: DateValue,
) => {
  if (!value) {
    return undefined;
  }
  const FORMAT = 'yyyy,MM,dd,00,00,00';

  const start = format(convertToDate(value), FORMAT);
  return `${String(name)}${separator}DateTime(${start})`;
};
export const createFilterDateRange = <T extends object = {}>(name: keyof T, value: DateValue[]) => {
  const valueFilter = [
    createFilterDate<T>(name, '>=', value[0]),
    createFilterDate<T>(name, '<=', value[1]),
  ];
  return valueFilter.some((v) => v === undefined) ? undefined : mergeFilters(valueFilter, '&&');
};

export const createFilterContains = <T extends object = {}>(
  name: keyof T | (keyof T)[],
  value: any,
) => {
  const names = Array.isArray(name) ? name : [name];

  return value
    ? createStart(
        names.length,
        names.map((_n) => `${String(_n)}.contains("${prepareValue(value)}")`).join('||'),
      )
    : undefined;
};

export const createFilterDeep = <T, K extends keyof T = keyof T>(
  name: K,
  key: keyof ArrayType<T[K]>,
  value: string[],
) => {
  return value.length ? `${String(name)}.any(${value.map((v) => `${String(key)}=="${v}"`).join('||')})` : undefined;
};
export const mergeFilters = (filters: (string | undefined | false)[], separator: string) => {
  const filtered = filters.filter(Boolean);
  if (filtered.length === 0) {
    return undefined;
  }
  return createStart(filtered.length, filtered.join(separator));
};

export const createFilterSmart = <T>(name: (keyof T)[], value: string) => {
  const names = (Array.isArray(name) ? name : [name]) as (keyof T)[];
  if (!value) return undefined;

  const field = names.map((_name) => `${String(_name)}.replace(" ",String.Empty)`).join('+');

  return createFilterContains<any>(`(${field})`, String(value).replace(/ /g, ''));
};

export const createOrderBy = <T>(name: keyof T, order: 'asc' | 'desc') => {
  return `${String(name)} ${order}`;
};
