import { DialogInfo } from 'components/app-dialog/info';
import React from 'react';
import { apiPages } from 'services/pages';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const DialogTerms: React.FC<Props> = ({ isOpen, onClose }) => {
  const { data, isFetching } = apiPages.endpoints.getTerms.useQuery(undefined, { skip: !isOpen });

  return isOpen ? <DialogInfo data={data} isLoading={isFetching} onClose={onClose} /> : null;
};
