import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SearchIcon from '@mui/icons-material/Search';
import { Fab } from '@mui/material';
import { DialogInvite } from 'components/app-dialog';
import { AppHeaderMobilePanel } from 'components/app-header/components/header-mobile-panel';
import { AppLogo } from 'components/app-logo';
import { APP_ROUTES } from 'configs/routes';
import { useOpen, usePermissions } from 'hooks';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import style from './index.module.scss';

interface Props {
  onClick?: () => void;
}

export const AppHeaderMobile: React.FC<Props> = ({ onClick }) => {
  const push = useNavigate();
  const onSearch = useCallback(() => {
    push(APP_ROUTES.SEARCH().config);
  }, [push]);
  const { isOpen, onClose, onToggle } = useOpen();
  const { isPhysician } = usePermissions();
  return (
    <>
      <header className={style.wrap}>
        <div className={style.data}>
          {isPhysician && (
            <Fab size={'small'} classes={{ root: style.icon }} onClick={onToggle}>
              <GroupAddIcon fontSize={'inherit'} color={'secondary'} />
            </Fab>
          )}
          <Fab size={'small'} classes={{ root: style.icon }} onClick={onSearch}>
            <SearchIcon fontSize={'inherit'} color={'secondary'} />
          </Fab>
        </div>
        <AppLogo />
        <AppHeaderMobilePanel className={style.menu} />
      </header>
      {isOpen && <DialogInvite onClose={onClose} />}
    </>
  );
};
