import queryString from 'query-string';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

export const useRouterParams = <T extends Record<string, any> = {}>() => {
  const params = useParams();
  const location = useLocation();
  const searchParams = useMemo(() => {
    return queryString.parse(location.search, { parseNumbers: true, parseBooleans: true });
  }, [location.search]);

  return { ...params, ...searchParams } as T;
};
