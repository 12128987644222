import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectHasSpecialistsLatestHasData } from 'store/specialists-latest';
import { APP_ROUTES } from '../configs/routes';
import { selectAccountsPermissions, selectAccountsUser } from '../store/accounts';
import { useMatchPath } from './use-match-path';

const { SIGN_IN, SIGN_IN_CODE } = APP_ROUTES;
const HIDE_CHAT_ON_PATHS = [
  SIGN_IN.path,
  SIGN_IN_CODE.path,

  APP_ROUTES.SIGNUP_DOCTORS_3.path,
  APP_ROUTES.SIGNUP_DOCTORS_4.path,
  APP_ROUTES.SIGNUP_DOCTORS_5.path,

  APP_ROUTES.SIGNUP_EXPERTS_3.path,
  APP_ROUTES.SIGNUP_EXPERTS_4.path,
  APP_ROUTES.SIGNUP_EXPERTS_5.path,

  APP_ROUTES.SIGNUP_VISITORS_3.path,

  APP_ROUTES.NOT_FOUND.path,
  APP_ROUTES.MESSAGES_HUB.path,
];

export const usePermissions = () => {
  const user = useSelector(selectAccountsUser);
  const hideChatButton = useMatchPath(HIDE_CHAT_ON_PATHS);
  const userPermissions = useSelector(selectAccountsPermissions);
  const isAllowViewChat = useMemo(() => {
    return [!!user].every((v) => v);
  }, [user]);

  const hasSpecialist = useSelector(selectHasSpecialistsLatestHasData);

  return useMemo(() => {
    const permissions = {
      ...userPermissions,
    };
    const {
      isAllowViewPhysician,
      isAllowSearchPhysician,
      isAllowFollowPhysician,
      isAllowViewPharmaCompanyExpert,
      isAllowChatPhysician,
    } = permissions;

    const hideSearchComponent = [isAllowViewPhysician, isAllowSearchPhysician].some((v) => !v);
    const hideChatComponent = [isAllowChatPhysician, isAllowViewChat].some((v) => !v);
    const hideMessageHub = [isAllowViewPhysician, isAllowChatPhysician].some((v) => !v);

    const hideFollowing = [isAllowViewPhysician, isAllowFollowPhysician].some((v) => !v);
    const hideExperts = [isAllowViewPhysician, isAllowViewPharmaCompanyExpert, hasSpecialist].some(
      (v) => !v,
    );

    return {
      ...permissions,

      hideChatButton,
      hideSearchComponent,
      hideChatComponent,
      hideMessageHub,
      hideFollowing,
      hideExperts,
    };
  }, [isAllowViewChat, userPermissions, hasSpecialist, hideChatButton]);
};
