import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

export const useMatchPath = (path: string | string[]) => {
  const { pathname } = useLocation();
  return useMemo(() => {
    const paths = Array.isArray(path) ? path : [path];
    return paths.some((p) => matchPath(pathname, p));
  }, [path, pathname]);
};
