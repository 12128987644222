import { Button, FormControl, FormHelperText } from '@mui/material';
import { useChatMessageTyping, useChatSendMessage } from 'components/cutom-chat';
import DOMPurify from 'dompurify';
import { useDecorator, useTranslate } from 'hooks';
import JoditEditor from 'jodit-react';
import React, { useMemo } from 'react';
import { useController } from 'react-hook-form';
import style from './index.module.scss';

const MAX_LENGTH = 2000;

const CONFIG = {
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,

  showPlaceholder: false,
  buttons: 'bold,italic,underline,link',

  defaultActionOnPaste: 'insert_only_text',
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,

  height: 100,
  width: '100%',
  allowResizeX: false,
  allowResizeY: false,

  cleanHTML: {
    cleanOnPaste: true,
    removeEmptyElements: true,
    replaceNBSP: true,
    allowTags: {
      p: true,
      span: true,
      a: true,
      strong: true,
      div: true,
      em: true,
      u: true,
      br: true,
    },
  },

  limitChars: MAX_LENGTH,

  link: {
    noFollowCheckbox: false,
    openInNewTabCheckbox: false,
  },

  toolbarAdaptive: false,
  toolbarSticky: false,
};

interface Props {
  isNew: boolean;
  conversationID: string;
  userID: string;
}

export const MessageInput: React.FC<Props> = ({ conversationID, userID, isNew }) => {
  const { control, onSendMessage, loading, key } = useChatSendMessage({
    conversationID,
    appUserID: userID,
    isNew,
  });
  const { onTyping } = useChatMessageTyping({ conversationID, appUserID: userID, isNew });
  const { t } = useTranslate();

  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name: 'message',
    rules: {
      required: {
        value: true,
        message: t('required'),
      },
    },
  });

  const config = useMemo(() => {
    return {
      ...CONFIG,
      disabled: loading,
    };
  }, [loading]);

  const value = field.value;
  const text = useMemo(() => {
    return DOMPurify.sanitize(value, { KEEP_CONTENT: true, ALLOWED_TAGS: [] });
  }, [value]);

  const charsCount = text.length;

  const errorText = error?.message;
  const helperText = (errorText && t(errorText)) || `${charsCount} / ${MAX_LENGTH}`;

  const disableButton = charsCount === 0 || loading || charsCount > MAX_LENGTH;

  return (
    <FormControl error={Boolean(error)} className={style.wrap}>
      <JoditEditor
        key={key}
        {...field}
        onChange={useDecorator(field.onChange, onTyping)}
        config={config}
      />

      <div className={style.controls}>
        <FormHelperText>{helperText}</FormHelperText>
        <Button
          disabled={disableButton}
          variant={'contained'}
          color={'primary'}
          onClick={onSendMessage}
          style={{
            textTransform: 'uppercase',
          }}
        >
          {t('send')}
        </Button>
      </div>
    </FormControl>
  );
};
