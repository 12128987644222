import { useCallback, useMemo, useState } from 'react';
import { actionFollowingAdd } from '../store/following';
import { useAppDispatch } from './use-app-dispatch';
import { useCurrentUser } from './use-current-user';
import { useMountedRef } from './use-mounted-ref';
import { usePermissions } from './use-permissions';

interface Options {
  userExpertProfileID: string;
}
export const useFollowing = (options: Options) => {
  const { userExpertProfileID } = options;
  const { appIdentityUserID } = useCurrentUser();
  const dispatch = useAppDispatch();
  const [isFollowingLoading, setIsFollowingLoading] = useState(false);
  const mountedRef = useMountedRef();

  const onFollowingAdd = useCallback(async () => {
    setIsFollowingLoading(true);
    await dispatch(actionFollowingAdd({ id: userExpertProfileID }));
    if (!mountedRef.current) return;
    setIsFollowingLoading(false);
  }, [dispatch, userExpertProfileID, mountedRef]);

  const { isAllowFollowPhysician } = usePermissions();

  const isFollowingAccess = useMemo(() => {
    return [isAllowFollowPhysician, appIdentityUserID !== userExpertProfileID].every(Boolean);
  }, [isAllowFollowPhysician, appIdentityUserID, userExpertProfileID]);

  return { onFollowingAdd, isFollowingLoading, isFollowingAccess };
};
