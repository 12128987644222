import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistMigrate } from 'redux-persist/es/types';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { all } from 'typed-redux-saga';
import pack from '../../package.json';
import { chatReducer, chatSagas } from 'components/cutom-chat';
import { apiRtk } from 'services/apiRtk';
import { actionAccountLogout, reducerAccounts, sagasAccounts } from './accounts';
import { reducerFollowing, sagasFollowing } from './following';
import { reducerLatestFollowing, sagasLatestFollowing } from './following-latest';
import { reducerLabels, sagasLabels } from './labels';
import { reducerMedEvents, sagasMedEvents } from './medical-events';
import { reducerMedEventsLatest, sagasMedEventsLatest } from './medical-events-latest';
import { reducerOnlineUsers, sagasOnlineUser } from './online-users';
import { reducerPosts, sagasPosts } from './posts';
import { reducerPostsLatest, sagasPostsLatest } from './posts-latest';
import { reducerPostsUsers, sagasPostsUsers } from './posts-users';
import { reducerSpecialists, sagasSpecialists } from './specialists';
import { reducerSpecialistsLatest, sagasSpecialistsLatest } from './specialists-latest';
import { reducerSurveyLatest, sagasSurveyLatest } from './survey-latest';
import { reducerSurveys, sagasSurveys } from './surveys';
import { reducerTags } from './tags';
import { reducerUsers, sagasUsers } from './users';

const version = pack.version

const VERSION = parseInt(version.replace(/\./gi, ''));

const migrateStore: PersistMigrate = (state, currentVersion) => {
  if (VERSION === state?._persist.version) {
    return Promise.resolve(state);
  } else {
    return Promise.resolve(undefined);
  }
};

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  labels: reducerLabels,
  chat: chatReducer,
  onlineUsers: reducerOnlineUsers,

  auth: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'auth',
      storage,
      whitelist: ['MobilePhone', 'email', 'loginEventID'],
    },
    reducerAccounts,
  ),

  following: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'following',
      storage,
      whitelist: ['filters'],
    },
    reducerFollowing,
  ),
  latestFollowing: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'latestFollowing',
      storage,
      whitelist: ['applyTags'],
    },
    reducerLatestFollowing,
  ),

  tags: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'tags',
      storage,
      whitelist: ['checkedTagIDs'],
    },
    reducerTags,
  ),

  medEvents: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'medEvents',
      storage,
      whitelist: ['filters', 'take', 'page', 'registerToEventID'],
    },
    reducerMedEvents,
  ),
  medEventsLatest: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'medEventsLatest',
      storage,
      whitelist: ['applyTags'],
    },
    reducerMedEventsLatest,
  ),

  users: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'users',
      storage,
      whitelist: ['filters', 'take', 'page'],
    },
    reducerUsers,
  ),

  posts: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'posts',
      storage,
      whitelist: ['orderBy', 'newPost', 'filters'],
    },
    reducerPosts,
  ),
  postsUsers: reducerPostsUsers,
  postsLatest: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'postsLatest',
      storage,
      whitelist: ['applyTags'],
    },
    reducerPostsLatest,
  ),

  specialists: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'specialists',
      storage,
      whitelist: ['filters'],
    },
    reducerSpecialists,
  ),
  specialistsLatest: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'specialistsLatest',
      storage,
      whitelist: ['filters', 'applyTags'],
    },
    reducerSpecialistsLatest,
  ),

  surveys: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'surveys',
      storage,
      whitelist: ['pagination', 'filters'],
    },
    reducerSurveys,
  ),

  surveyLatest: reducerSurveyLatest,

  [apiRtk.reducerPath]: apiRtk.reducer,
});
const appReducer: typeof rootReducer = (state, action) => {
  if (action.type === actionAccountLogout.fulfilled.type) {
    requestAnimationFrame(() => {
      appPersistor.purge();
    });
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

export const appStore = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ thunk: true, serializableCheck: false, immutableCheck: false })
      .concat(sagaMiddleware)
      .concat(apiRtk.middleware);
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export const appPersistor = persistStore(appStore);

function* rootSaga() {
  yield all([
    ...sagasLabels,
    ...sagasPostsUsers,
    ...sagasAccounts,
    ...sagasMedEvents,
    ...sagasMedEventsLatest,
    ...sagasUsers,
    ...sagasSurveys,
    ...sagasSurveyLatest,
    ...sagasFollowing,
    ...sagasLatestFollowing,
    ...sagasPosts,
    ...sagasPostsLatest,
    ...sagasSpecialists,
    ...sagasSpecialistsLatest,
    ...chatSagas,
    ...sagasOnlineUser,
  ]);
}

sagaMiddleware.run(rootSaga);

export type AppState = ReturnType<typeof appStore.getState>;
export type AppDispatch = typeof appStore.dispatch;
export type AppAsyncThunkConfig = {
  state: AppState;
  dispatch: AppDispatch;
  serializedErrorType: Error;
};
