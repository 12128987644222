import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseGetQuery } from 'utils';
export const transformResponseDynamic = <T extends { value: any[] }>(data: T) => {
  return data.value;
};
export const transformResponseDynamicItem = <T extends { value: any[] }>(data: T) => {
  const item = data.value[0];
  if (!item) {
    throw new Error('record-not-found');
  }
  return item;
};

export enum API_RTK_TAGS {
  USER_TAGS = 'USER_TAGS',
  USER_INFO = 'USER_INFO',
}
export const apiRtk = createApi({
  reducerPath: 'apiRtk',
  baseQuery: axiosBaseGetQuery(),
  endpoints: () => ({}),
  tagTypes: [API_RTK_TAGS.USER_TAGS, API_RTK_TAGS.USER_INFO],
});
