import { useTranslate } from 'hooks';
import React from 'react';
import style from './index.module.scss';

interface Props {
}

export const CustomChatNoConversations: React.FC<Props> = () => {
  const { t } = useTranslate();
  return <div className={style.wrap}>{t('no-conversations')}</div>;
};
