import { APP_ROUTES } from 'configs/routes';
import { useCurrentUser, usePermissions } from 'hooks';
import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { USER_STATUS_KEY } from 'services/auth';

const { SIGNUP_DOCTORS_3, SIGNUP_EXPERTS_3, SIGNUP_VISITORS_3 } = APP_ROUTES;

interface Props {
  children: React.ReactNode;
}
export const RouteUserStatusActive: React.FC<Props> = ({ children }) => {
  const { userStatusKey } = useCurrentUser();
  const { isDoctorUser, isRegularUser, isExpertUser } = usePermissions();

  const to = useMemo(() => {
    if (isDoctorUser) {
      return { ...SIGNUP_DOCTORS_3({ ignoreType: false }).config };
    } else if (isRegularUser) {
      return { ...SIGNUP_VISITORS_3({ ignoreType: false }).config };
    } else if (isExpertUser) {
      return { ...SIGNUP_EXPERTS_3({ ignoreType: false }).config };
    } else {
      throw new Error('Can not identity a user type');
    }
  }, [isDoctorUser, isRegularUser, isExpertUser]);

  if (userStatusKey !== USER_STATUS_KEY.ACTIVE) {
    return <Navigate to={to} />;
  }

  return <>{children}</>;
};
