import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PostInfo, PostModel } from 'services/posts';
import { setToModel } from 'utils';
import { combineActions } from 'utils/sagas';
import { actionFollowingAdd, actionFollowingRemove } from '../following';
import { actionPostsCreate, actionPostsDelete, actionPostsRefresh } from './actions';

export enum POSTS_ORDER_BY {
  PUBLISH_DATE = 'PUBLISH_DATE',
  MOST_POPULAR = 'MOST_POPULAR',
}

interface Filters {
  search: string;
  articleCategoryID: string | 'ALL';
}

interface State {
  orderBy: POSTS_ORDER_BY;
  isInit: boolean;
  isLoading: boolean;
  data: PostInfo[];
  newPost: PostModel;
  error: null | Error;
  filters: Filters;
  pagination: {
    take: number;
    count: number;
    page: number;
  };
}

const initState = (): State => {
  return {
    orderBy: POSTS_ORDER_BY.PUBLISH_DATE,
    isInit: false,
    isLoading: false,
    data: [],
    newPost: new PostModel(),
    error: null,
    pagination: {
      take: 10,
      count: 0,
      page: 1,
    },
    filters: {
      search: '',
      articleCategoryID: 'ALL',
    },
  };
};

const slice = createSlice({
  name: 'POSTS',
  initialState: initState(),
  reducers: {
    requestGet(state) {
      state.isLoading = true;
      state.error = null;
      state.pagination = initState().pagination;
    },
    successGet(state, action: PayloadAction<{ data: PostInfo[]; count: number }>) {
      state.isLoading = false;
      state.isInit = true;
      state.error = null;
      state.data = action.payload.data;
      state.pagination.count = action.payload.count;
    },
    failGet(state, action: PayloadAction<{ error: Error }>) {
      state.isLoading = false;
      state.isInit = true;
      state.error = action.payload.error;
    },

    requestGetMore(state) {
      state.isLoading = true;
      state.error = null;
      state.pagination.page = state.pagination.page + 1;
    },
    successGetMore(state, action: PayloadAction<{ data: PostInfo[]; count: number }>) {
      state.isLoading = false;
      state.error = null;
      state.data = [...state.data, ...action.payload.data];
      state.pagination.count = action.payload.count;
    },
    failGetMore(state, action: PayloadAction<{ error: Error }>) {
      state.isLoading = false;
      state.error = action.payload.error;
      state.pagination.page = Math.max(1, state.pagination.page - 1);
    },

    actionPostsSetFilters(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    actionPostsSetOrder(state, action: PayloadAction<POSTS_ORDER_BY>) {
      state.orderBy = action.payload;
    },
    actionPostsSetNewPostData(state, action: PayloadAction<Partial<PostModel>>) {
      state.newPost = setToModel(new PostModel(), { ...state.newPost, ...action.payload });
    },
    actionPostsTrackVideo(state, action: PayloadAction<{ articleID: string }>) {},
  },
  extraReducers: (builder) => {
    builder.addCase(actionPostsDelete.fulfilled, (state, action) => {
      state.data = state.data.filter((post) => post.id !== action.payload.id);
    });
    builder.addCase(actionPostsCreate.fulfilled, (state, action) => {
      state.newPost = new PostModel();
      state.isInit = false;
    });
    builder.addCase(actionPostsRefresh.fulfilled, (state, action) => {
      state.data = state.data.map((post) =>
        post.id === action.payload.data.id ? action.payload.data : post,
      );
    });

    builder.addCase(actionFollowingRemove.fulfilled, (state, action) => {
      const { id } = action.payload;
      state.data = state.data.map((item) =>
        item.userExpertProfileID === id ? { ...item, following: false } : item,
      );
    });
    builder.addCase(actionFollowingAdd.fulfilled, (state, action) => {
      const { id } = action.payload;
      state.data = state.data.map((item) =>
        item.userExpertProfileID === id ? { ...item, following: true } : item,
      );
    });
  },
});

const actions = slice.actions;
export const {
  actionPostsSetFilters,
  actionPostsSetOrder,
  actionPostsSetNewPostData,
  actionPostsTrackVideo,
} = actions;

export const actionPostsGet = combineActions(
  actions.requestGet,
  actions.successGet,
  actions.failGet,
);

export const actionPostsGetMore = combineActions(
  actions.requestGetMore,
  actions.successGetMore,
  actions.failGetMore,
);

export const reducerPosts = slice.reducer;
