import clsx from 'clsx';
import React from 'react';
import style from './index.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode
}

export const AppDialogFooter: React.FC<Props> = ({ className, children }) => {
  return <div className={clsx(style.wrap, className)}>{children}</div>;
};
