import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PostInfo } from 'services/posts';
import { setToModel } from 'utils';
import { combineActions } from 'utils/sagas';
import { actionFollowingAdd, actionFollowingRemove } from '../following';
import { actionPostsRefresh, POSTS_ORDER_BY } from '../posts';

interface Filters {
  search: string;
  articleCategoryID: string | 'ALL';
}

interface StateItem {
  orderBy: POSTS_ORDER_BY;
  isInit: boolean;
  isLoading: boolean;
  data: PostInfo[];
  error: null | Error;
  filters: Filters;
  pagination: {
    take: number;
    count: number;
    page: number;
  };
}

export const initStatePostsUsersItem = (): StateItem => {
  return {
    orderBy: POSTS_ORDER_BY.PUBLISH_DATE,
    isInit: false,
    isLoading: false,
    data: [],
    error: null,
    pagination: {
      take: 10,
      count: 0,
      page: 1,
    },
    filters: {
      search: '',
      articleCategoryID: 'ALL',
    },
  };
};

interface State {
  [userID: string]: StateItem;
}

const initialState: State = {};

const slice = createSlice({
  name: 'POSTS_USERS',
  initialState,
  reducers: {
    requestGet(state, action: PayloadAction<{ userID: string }>) {
      const userID = action.payload.userID;
      const itemState = state[userID];
      state[userID] = setToModel(initStatePostsUsersItem(), {
        ...itemState,
        isLoading: true,
        error: null,
        pagination: initStatePostsUsersItem().pagination,
      });
    },
    successGet(state, action: PayloadAction<{ userID: string; data: PostInfo[]; count: number }>) {
      const userID = action.payload.userID;
      const itemState = state[userID];
      if (itemState) {
        itemState.isLoading = false;
        itemState.isInit = true;
        itemState.data = action.payload.data;
        itemState.pagination.count = action.payload.count;
      }
    },
    failGet(state, action: PayloadAction<{ userID: string; error: Error }>) {
      const userID = action.payload.userID;
      const itemState = state[userID];
      if (itemState) {
        itemState.isLoading = false;
        itemState.isInit = true;
        itemState.error = action.payload.error;
      }
    },

    requestGetMore(state, action: PayloadAction<{ userID: string }>) {
      const userID = action.payload.userID;
      const itemState = state[userID];
      if (itemState) {
        itemState.isLoading = true;
        itemState.error = null;
        itemState.pagination.page = itemState.pagination.page + 1;
      }
    },
    successGetMore(
      state,
      action: PayloadAction<{ userID: string; data: PostInfo[]; count: number }>,
    ) {
      const userID = action.payload.userID;
      const itemState = state[userID];
      if (itemState) {
        itemState.isLoading = false;
        itemState.error = null;
        itemState.data = [...itemState.data, ...action.payload.data];
        itemState.pagination.count = action.payload.count;
      }
    },
    failGetMore(state, action: PayloadAction<{ userID: string; error: Error }>) {
      const userID = action.payload.userID;
      const itemState = state[userID];
      if (itemState) {
        itemState.isLoading = false;
        itemState.error = action.payload.error;
        itemState.pagination.page = Math.max(1, itemState.pagination.page - 1);
      }
    },

    actionPostsUsersSetFilters(
      state,
      action: PayloadAction<{ userID: string; data: Partial<Filters> }>,
    ) {
      const { userID, data } = action.payload;
      const itemState = state[userID];
      if (itemState) {
        itemState.filters = { ...itemState.filters, ...data };
      }
    },
    actionPostsUsersSetOrder(
      state,
      action: PayloadAction<{ userID: string; data: POSTS_ORDER_BY }>,
    ) {
      const { userID, data } = action.payload;
      const itemState = state[userID];
      if (itemState) {
        itemState.orderBy = data;
      }
    },
    actionPostsUsersReset(state, action: PayloadAction<{ userID: string }>) {
      const { userID } = action.payload;
      delete state[userID];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actionPostsRefresh.fulfilled, (state, action) => {
      const { data } = action.payload;
      const itemState = state[data.userExpertProfileID];

      if (itemState) {
        itemState.data = itemState.data.map((item) =>
          item.userExpertProfileID === data.userExpertProfileID ? data : item,
        );
      }
    });

    builder.addCase(actionFollowingRemove.fulfilled, (state, action) => {
      const { id } = action.payload;
      const itemState = state[id];
      if (itemState) {
        itemState.data = itemState.data.map((item) =>
          item.userExpertProfileID === id ? { ...item, following: false } : item,
        );
      }
    });
    builder.addCase(actionFollowingAdd.fulfilled, (state, action) => {
      const { id } = action.payload;
      const itemState = state[id];
      if (itemState) {
        itemState.data = itemState.data.map((item) =>
          item.userExpertProfileID === id ? { ...item, following: true } : item,
        );
      }
    });
  },
});

const actions = slice.actions;
export const { actionPostsUsersSetFilters, actionPostsUsersSetOrder, actionPostsUsersReset } =
  actions;

export const actionPostsUsersGet = combineActions(
  actions.requestGet,
  actions.successGet,
  actions.failGet,
);

export const actionPostsUsersGetMore = combineActions(
  actions.requestGetMore,
  actions.successGetMore,
  actions.failGetMore,
);

export const reducerPostsUsers = slice.reducer;
