const API = 'CurrentAppUserProfile';

export const API_CURRENT_USER = {
  GET_USER: `${API}/GetCurrentAppUser`,
  ADD_TO_VIEW_LOG: `${API}/AddToViewLog`,
  SET_AS_ACTIVE: `${API}/SetCurrentAppUserAsActive`,
  PATCH: `${API}/PatchCurrentUser`,
  ADD_TO_FOLLOWING: (id: string) => `${API}/AddToFollowing/${id}`,
  REMOVE_FROM_FOLLOWING: (id: string) => `${API}/RemoveFromFollowing/${id}`,

  TAG_GET: `${API}/GetCurrentUserExpertProfileTagsDynamic`,
  TAG_CREATE: `${API}/CreateCurrentUserExpertProfileTag`,
  TAG_DELETE: ({ id }: { id: string }) => `${API}/DeleteCurrentUserExpertProfileTag/${id}`,

  FOLLOWERS_GET: `${API}/GetCurrentUserExpertProfileFollowersDynamic`,

  SPEAK_LANGUAGE_GET: `${API}/GetCurrentUserExpertProfileSpeakLanguagesDynamic`,
  SPEAK_LANGUAGE_CREATE: `${API}/CreateCurrentUserExpertProfileSpeakLanguage`,
  SPEAK_LANGUAGE_DELETE: ({ id }: { id: string }) =>
    `${API}/DeleteCurrentUserExpertProfileSpeakLanguage/${id}`,
  TRACK_VIDEO: `${API}/AddPlayVideoToViewLog`,
};

export interface UserLanguages {
  speakLanguageID: string;
  appIdentityUserID: string;
  id: string;
}

export interface UserTag {
  tagID: string;
  appIdentityUserID: string;
  id: string;
  title: string;
}
