import { ServiceSpecialists, Specialist, SpecialistFull } from 'services/specialists';
import { call, debounce, put, select, takeEvery } from 'typed-redux-saga';
import {
  createFilterArrays,
  createFilterContains,
  createFilterEquals,
  parseErrorData,
} from 'utils';
import { workerErrorNotifySaga } from 'utils/sagas';
import { selectSpecialistsFilters, selectSpecialistsPagination } from './selectors';
import { actionSpecialistsGet } from './slice';

function* fetchSpecialists() {
  const { search, primaryMedicalFieldID, tagIDs } = yield* select(selectSpecialistsFilters);
  const { skip, take } = yield* select(selectSpecialistsPagination);

  const filter = [
    createFilterContains<SpecialistFull>({
      name: ['firstName', 'lastName', 'position', 'subject'],
      value: search,
    }),
    createFilterEquals<SpecialistFull>({
      name: 'primaryMedicalFieldID',
      value: primaryMedicalFieldID,
    }),
    createFilterArrays<SpecialistFull, 'userExpertProfileTagIDs'>({
      name: 'userExpertProfileTagIDs',
      key: 'id',
      value: tagIDs,
    }),
  ]
    .filter((v) => !!v)
    .join(' && ');
  const selectParams = Object.keys(new Specialist()).join(',');

  try {
    const {
      data: { value, count },
    } = yield* call(ServiceSpecialists.getAllDynamic, {
      select: selectParams,
      filter,
      skip,
      take,
    });
    yield put(actionSpecialistsGet.success({ data: value, count }));
  } catch (e: any) {
    yield put(actionSpecialistsGet.fail({ error: parseErrorData(e) }));
  }
}

export const sagasSpecialists = [
  debounce(500, actionSpecialistsGet.request, fetchSpecialists),
  takeEvery([actionSpecialistsGet.fail], workerErrorNotifySaga),
];
