import { Button, Divider, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { DialogPrivacy, DialogTerms } from 'components/dialogs';
import { useOpen, useTranslate } from 'hooks';
import React from 'react';
import style from './index.module.scss';

export const AppFooterMain: React.FC = () => {
  const { t } = useTranslate();
  const { isOpen: isOpenPrivacy, onOpen: onOpenPrivacy, onClose: onClosePrivacy } = useOpen();
  const { isOpen: isOpenTerms, onOpen: onOpenTerms, onClose: onCloseTerms } = useOpen();

  return (
    <footer className={style.wrap}>
      <div className={clsx(style.content, 'container')}>
        <Typography variant={'body2'}>{t('copyrights')}</Typography>
        <Stack
          divider={
            <Divider
              orientation={'vertical'}
              style={{ height: '1.7rem', borderColor: '#928592' }}
            />
          }
          spacing={0.1}
          direction={'row'}
          alignItems={'center'}
          mr={'-1.1rem'}
          ml={'-1.1rem'}
        >
          <Button size={'small'} color={'secondary'} className={'link-style'} onClick={onOpenTerms}>
            {t('terms')}
          </Button>
          <Button
            size={'small'}
            color={'secondary'}
            className={'link-style'}
            onClick={onOpenPrivacy}
          >
            {t('privacy')}
          </Button>
        </Stack>
        <DialogPrivacy isOpen={isOpenPrivacy} onClose={onClosePrivacy} />
        <DialogTerms isOpen={isOpenTerms} onClose={onCloseTerms} />
      </div>
    </footer>
  );
};
