import { createSelector } from 'reselect';
import { AppState } from '../index';

const selectState = (state: AppState) => state.medEventsLatest;

export const selectMedEventsState = createSelector(selectState, (state) => {
  return state;
});
export const selectMedEventsLatestStatuses = createSelector(
  selectMedEventsState,
  ({ isLoading, isInit, error, applyTags }) => {
    return { isLoading, isInit, error, applyTags };
  },
);
export const selectMedEventsLatestApplyTags = createSelector(
  selectMedEventsLatestStatuses,
  ({ applyTags }) => {
    return applyTags;
  },
);
export const selectMedEventsLatestData = createSelector(selectMedEventsState, ({ data }) => {
  return data;
});
