import { useCallback, useMemo } from 'react';
import { useNotify } from './use-notify';

interface IRequest {
  (...args: any[]): Promise<any>;
}

interface UseRequestNotifyProps<R extends IRequest> {
  withSuccess?: boolean;
  setLoading?: (v: boolean) => void;
  request: R;
  throwError?: boolean;
}

const defaultSetLoading = () => ({});
export const useRequestNotify = <R extends IRequest>({
  request,
  setLoading = defaultSetLoading,
  withSuccess = false,
  throwError = true,
}: UseRequestNotifyProps<R>) => {
  const { onError, onSuccess } = useNotify();
  const requestWrap = useCallback(
    async (...args: any[]) => {
      try {
        setLoading(true);
        const result = await request(...args);
        withSuccess && onSuccess();
        setLoading(false);
        return result;
      } catch (e: any) {
        setLoading(false);
        onError(e);
        if (throwError) {
          throw e;
        }
      }
    },
    [request, setLoading, withSuccess, throwError, onError, onSuccess],
  ) as R;

  return useMemo(() => {
    return {
      requestWrap,
    };
  }, [requestWrap]);
};
