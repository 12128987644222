const API = 'Surveys';

export const API_SURVEYS = {
  GET: `${API}/GetCurrentUserExpertProfileSurvey`,
  VOTE: ({ optionID }: { optionID: SurveyOption['id'] }) => `${API}/VoteForSurvey/${optionID}`,
  GET_ALL_DYNAMIC: `${API}/GetSurveysDynamic`,
};

export interface SurveyOption {
  id: string;
  title: string;
  color: string;
  rank: number;
  votes: number;
}

export interface SurveyFull {
  id: string;
  title: string;
  question: string;
  description: string;
  displayForAll: boolean;
  userSurveyOptionID: SurveyOption['id'];
  userVoteToSurvey: boolean;
  totalVotes: number;
  surveyOptionItems: SurveyOption[];
  surveyMedicalFieldTitles: string;
  surveyMedicalFieldIDs: { id: string }[];
  surveyTagTitles: string;
  surveyTagIDs: { id: string }[];
}

export class SurveyShort implements Partial<SurveyFull> {
  id = '';
  title = '';
  question = '';
  description = '';
  userSurveyOptionID = '';
  totalVotes = 0;
  surveyOptionItems: SurveyOption[] = [];
  rank: number = 0;
}

export class Survey extends SurveyShort {
  displayForAll = false;
  userVoteToSurvey = false;
}
