import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MedicalEvent } from 'services/medical-events';
import { combineActions } from 'utils/sagas';

type Model = Pick<
  MedicalEvent,
  | 'id'
  | 'eventDate'
  | 'eventTimeSlot'
  | 'title'
  | 'presenterName'
  | 'presenterPosition'
  | 'picture'
  | 'userRegisterToEvent'
  | 'eventSponsors'
>;

export enum MED_EVENT_CUSTOM_KEYS {
  THREE_MONTHS = 'THREE_MONTHS_',
  YEAR = 'YEAR_',
  ARCHIVE = 'ARCHIVE_',
}

export interface MedEventFilters {
  search: string;
  date: MED_EVENT_CUSTOM_KEYS;
  tags: string[];
}

interface State {
  registerToEventID: string | null;
  isLoading: boolean;
  isInit: boolean;
  data: Model[];
  error: null | Error;
  filters: MedEventFilters;
  take: number;
  page: number;
  count: number;
}

const initState = (): State => {
  return {
    registerToEventID: null,
    isLoading: false,
    isInit: false,
    data: [],
    error: null,
    filters: {
      search: '',
      date: MED_EVENT_CUSTOM_KEYS.THREE_MONTHS,
      tags: [],
    },
    take: 20,
    page: 1,
    count: 0,
  };
};

const slice = createSlice({
  name: 'MEDICAL_EVENTS',
  initialState: initState(),
  reducers: {
    requestGet(state, action: PayloadAction<{ page: number | undefined }>) {
      state.isLoading = true;
      state.error = null;
      state.page = action.payload.page ?? state.page;
    },
    successGet(state, action: PayloadAction<{ data: Model[]; count: number }>) {
      state.isLoading = false;
      state.isInit = true;
      state.error = null;
      state.count = action.payload.count;
      state.data = action.payload.data;
    },
    failGet(state, action: PayloadAction<{ error: Error }>) {
      state.isLoading = false;
      state.isInit = true;
      state.error = action.payload.error;
    },

    actionMedicalEventsSetFilters(state, action: PayloadAction<Partial<MedEventFilters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    actionMedicalEventsResetFilters(state) {
      state.filters = initState().filters;
    },
    actionMedicalEventsSetRegisterEventID(state, action: PayloadAction<string | null>) {
      state.registerToEventID = action.payload;
    },
  },
});

const actions = slice.actions;
export const {
  actionMedicalEventsSetFilters,
  actionMedicalEventsResetFilters,
  actionMedicalEventsSetRegisterEventID,
} = actions;

export const actionMedicalEventsGet = combineActions(
  actions.requestGet,
  actions.successGet,
  actions.failGet,
);

export const reducerMedEvents = slice.reducer;
