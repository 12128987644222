import { APP_ROUTES } from 'configs/routes';
import { useTranslate } from 'hooks';
import Logo from 'images/logo.svg';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import style from './index.module.scss';

interface AppLogoProps {
  showTitle?: boolean;
  onClick?: () => void;
}

const { DASHBOARD } = APP_ROUTES;

export const AppLogo: React.FC<AppLogoProps> = ({ showTitle = true, onClick }) => {
  const { t } = useTranslate();
  const push = useNavigate();

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    } else {
      push(DASHBOARD().config);
    }
  }, [onClick, push]);

  return (
    <div className={style.logo} onClick={handleClick}>
      <img src={Logo} alt="" />
      {showTitle && <span>{t('app-logo')}</span>}
    </div>
  );
};
