import { Popper, Theme, useMediaQuery } from '@mui/material';
import { useOpen } from 'hooks';
import React, { useRef } from 'react';
import { apiExpertProfiles } from 'services/expert-profiles';
import { UserTooltipCard } from './components';
import style from './index.module.scss';

interface Props {
  className?: string;
  children: React.ReactElement<any, any>;
  expertID: string;
  disableMobile?: boolean;
  debug?: boolean;
}

export const UserTooltip: React.FC<Props> = ({
  className,
  disableMobile = true,
  expertID,
  children,
  debug = false,
}) => {
  const { isOpen, onOpen, onClose } = useOpen();
  const ref = useRef<HTMLDivElement>(null);
  const { data, isFetching, isLoading } = apiExpertProfiles.endpoints.getUser.useQuery(
    { expertID: expertID },
    { skip: !isOpen },
  );

  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));

  const disabled = isMobile ? disableMobile : false;

  return (
    <div
      ref={ref}
      onMouseEnter={disabled ? undefined : onOpen}
      onMouseLeave={disabled ? undefined : onClose}
      className={className}
    >
      {children}
      {data && !disabled && (
        <Popper
          placement={'left'}
          className={style.popper}
          open={!debug ? isOpen : true}
          anchorEl={ref.current}
        >
          <UserTooltipCard data={data} isLoading={isFetching || isLoading} />
        </Popper>
      )}
    </div>
  );
};
