import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Chip, Typography } from '@mui/material';
import { AppSelect } from 'components/app-select';
import { RenderInput } from 'components/select/inputs';
import { RenderOption } from 'components/select/options';
import { useSelect, useSourceTags, useTranslate } from 'hooks';
import React, { useCallback } from 'react';
import VAR from 'styles/config.scss';
import style from './index.module.scss';

interface FormTagsProps {
  value: string[];
  onChange: (v: this['value']) => void;
}

export const FormTags: React.FC<FormTagsProps> = ({ value, onChange }) => {
  const { data: options, isLoading: isLoadingTags } = useSourceTags();
  const { mapSelected, onChangeItem } = useSelect({ options, value, onChange, multiple: true });

  const { t } = useTranslate();
  const factoryChangeItem = useCallback(
    (id: string) => {
      return () => onChangeItem(id);
    },
    [onChangeItem],
  );
  return (
    <>
      <AppSelect
        value={value}
        options={options}
        loading={isLoadingTags}
        renderInput={(props) => <RenderInput placeholder={t('search-tags')} {...props} />}
        renderOption={RenderOption}
        multiple={true}
        onChange={(v) => {
          onChange(v);
        }}
        disableClearable
        className={style.search}
        classes={{ input: style.input }}
      />
      <Typography
        component={'h3'}
        variant={'subtitle2'}
        mt={'1rem'}
        mb={'1.5rem'}
        color={VAR.colorGrey}
      >
        {t('choose-tags-instructions')}
      </Typography>
      <div className={style.tags}>
        {options.map((item) => {
          return (
            <Chip
              key={item.id}
              variant={mapSelected[item.id] ? undefined : 'outlined'}
              label={item.title}
              color={mapSelected[item.id] ? 'primary' : 'default'}
              icon={mapSelected[item.id] ? <RemoveIcon /> : <AddIcon className={style.iconAdd} />}
              onClick={factoryChangeItem(item.id)}
              classes={{
                root: style.chip,
                label: style.chipLabel,
              }}
            />
          );
        })}
      </div>
    </>
  );
};
