import { MenuItem } from '@mui/material';
import { AppAvatar } from 'components/app-avatar';
import { APP_ROUTES } from 'configs/routes';
import { useCurrentUser, useOnlineUsers } from 'hooks';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import style from './index.module.scss';

interface Props {
  className?: string;
}

export const ProfileLink: React.FC<Props> = ({ className }) => {
  const user = useCurrentUser();

  const { checkIsOnline } = useOnlineUsers();

  const push = useNavigate();
  const onEdit = useCallback(() => {
    push(APP_ROUTES.PROFILE().config);
  }, [push]);

  return (
    <MenuItem component={'div'} color={'secondary'} onClick={onEdit} className={style.wrap}>
      <div className={style.name} title={user.fullName}>
        {user.fullName}
      </div>
      <AppAvatar
        bgColor={user.color}
        isOnline={checkIsOnline(user.appIdentityUserID)}
        className={style.avatar}
        size={'md'}
        src={user.userPhoto}
      />
    </MenuItem>
  );
};
