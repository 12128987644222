import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Survey } from 'services/surveys';
import { combineActions } from 'utils/sagas';
import { actionSurveyLatestGetCount, actionSurveyLatestVote } from './actions';

interface State {
  survey: null | Survey;
  isLoading: boolean;
  isInit: boolean;
  error: null | Error;
  countUnvoted: number;
}

const initState = (): State => {
  return {
    survey: null,
    isLoading: false,
    isInit: false,
    error: null,
    countUnvoted: 0,
  };
};
const slice = createSlice({
  name: 'SURVEY_LATEST',
  initialState: initState(),
  reducers: {
    requestGet(state) {
      state.isLoading = true;
      state.error = null;
    },
    successGet(state, action: PayloadAction<Survey>) {
      state.isLoading = false;
      state.survey = action.payload;
      state.isInit = true;
    },
    failGet(state, action: PayloadAction<{ error: Error }>) {
      state.isLoading = false;
      state.error = action.payload.error;
      state.isInit = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actionSurveyLatestVote.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(actionSurveyLatestVote.fulfilled, (state, action) => {
      state.isLoading = false;
      state.survey = action.payload.data;
      state.countUnvoted = state.countUnvoted - 1;
    });
    builder.addCase(actionSurveyLatestVote.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(actionSurveyLatestGetCount.fulfilled, (state, action) => {
      state.countUnvoted = action.payload.count;
    });
  },
});

const actions = slice.actions;

export const actionSurveyLatestGet = combineActions(
  actions.requestGet,
  actions.successGet,
  actions.failGet,
);

export const reducerSurveyLatest = slice.reducer;
