import { createSelector } from 'reselect';
import { UserPermissions } from 'services/auth';
import { AppState } from '../index';

export const selectAccountsState = (state: AppState) => state.auth;

export const selectAccountSignIn = createSelector(selectAccountsState, ({ MobilePhone, email }) => {
  return { mobilePhone: MobilePhone, email };
});
export const selectAccountsLoading = createSelector(selectAccountsState, ({ loading }) => {
  return loading;
});
export const selectAccountsLoadingPatch = createSelector(
  selectAccountsState,
  ({ isLoadingPatch }) => {
    return isLoadingPatch;
  },
);
export const selectAccountsError = createSelector(selectAccountsState, ({ error }) => {
  return error;
});
export const selectAccountsUser = createSelector(selectAccountsState, ({ user }) => {
  return user;
});
export const selectAccountsPermissions = createSelector(selectAccountsState, ({ user }) => {
  const permissions = user?.userPermission || new UserPermissions();

  const isPhysician = user?.isPhysician || false;
  const isExpertUser = !isPhysician && !permissions.visitor;
  const isRegularUser = !isPhysician && permissions.visitor;
  const isDoctorUser = isPhysician;

  const IS_ALLOW_VIEW_ONLINE_USERS = permissions.isAllowViewPhysician;

  return {
    ...permissions,
    isPhysician,
    isDoctorUser,
    isExpertUser,
    isRegularUser,
    IS_ALLOW_VIEW_ONLINE_USERS,
  };
});
