import CloseIcon from '@mui/icons-material/Close';
import { IconButton, ListItem } from '@mui/material';
import { AppAvatar } from 'components/app-avatar';
import { ButtonMenuDots } from 'components/button-menu-dots';
import { APP_ROUTES } from 'configs/routes';
import { useOpen, useTranslate } from 'hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useChatOpen } from '../../hooks';
import style from './index.module.scss';

interface Props {
  appUserID: string;
  fullName: string;
  position?: string | null;
  picture?: string | null;
  isOnline: boolean;
  onDelete?: () => void;
  onClose?: () => void;
}

export const CustomChatHeader: React.FC<Props> = ({
  appUserID,
  fullName,
  position,
  picture,
  isOnline,
  onClose,
  onDelete,
}) => {
  const { t } = useTranslate();

  const { isOpen: isOpenDots, onOpen: onOpenDots, onClose: onCloseDots } = useOpen();

  const itemDots = [
    {
      title: t('delete-conversation'),
      onClick: onDelete,
    },
  ];

  const push = useNavigate();
  const { onClose: onCloseChat } = useChatOpen();
  const goToUser = () => {
    onCloseChat();
    push(APP_ROUTES.USER({ member: appUserID }).config);
  };

  return (
    <div className={style.header}>
      <div>
        <ListItem button className={style.user} onClick={goToUser}>
          <AppAvatar src={picture} isOnline={isOnline} bgColor={false} />
          <div className={style.cont}>
            <div className={style.name}>{fullName}</div>
            {position}
          </div>
        </ListItem>
      </div>
      <div>
        {onDelete && (
          <ButtonMenuDots
            items={itemDots}
            isOpen={isOpenDots}
            onClose={onCloseDots}
            onOpen={onOpenDots}
          />
        )}
        {onClose && (
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};
