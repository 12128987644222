const API = 'ExpertProfiles';

export const API_EXPERT_PROFILES = {
  GET_DYNAMIC: `${API}/GetExpertsDynamic`,
};

export class ExpertProfile {
  appIdentityUserID: string = '';
  titleOfNameID: string = '';
  titleOfNameTitle: string = '';
  firstName: string = '';
  lastName: string = '';
  userPhoto: string = '';
  position: string = '';
  primaryMedicalFieldTitle: string = '';
  secondaryMedicalFieldID: string = '';
  fullName: string = '';
  expertTypeID: string = '';
  userStatusID: string = '';
  primaryMedicalFieldID: string = '';
  secondaryMedicalFieldTitle: string = '';
  isHmo: boolean = false;
  hmoID: string = '';
  hmoTitle: string = '';
  hmoName: string = '';
  hmoCityID: string = '';
  hmoCityTitle: string = '';
  isHospital: boolean = false;
  hospitalID: string = '';
  hospitalTitle: string = '';
  hospitalPosition: string = '';
  hospitalDepartmentID: string = '';
  hospitalDepartmentTitle: string = '';
  isPrivateWorkPlaceName: boolean = false;
  privateWorkPlaceName: string = '';
  privateWorkPlaceMedicalPractice: string = '';
  otherWorkPlaceID: string = '';
  otherWorkPlaceTitle: string = '';
  fullNameEnglish: string = '';
  pharmaCompanyExpertSubject: string = '';
  userExpertProfileSpeakLanguageTitles: string = '';
  userExpertProfileTagTitles: string = '';
  userExpertProfileTagIDs!: { ID: string }[];
  userExpertProfileSpeakLanguageIDs!: { ID: string }[];
  externalCertificateValid = false;
  following = false;
  follower = false;
  isPharmaCompanyExpert = false;
  color = '';
}

export class UserBaseInfo {
  appIdentityUserID = '';
  titleOfNameID = '';
  titleOfNameTitle = '';
  firstName = '';
  lastName = '';
  userPhoto = '';
  position = '';
  fullName = '';
  primaryMedicalFieldTitle = '';
  color = '';
}

export class UserFollowingSmall implements Partial<ExpertProfile> {
  userPhoto = '';
  appIdentityUserID = '';
  fullNameEnglish = '';
  position = '';
  primaryMedicalFieldTitle = '';
  firstName = '';
  lastName = '';
  fullName = '';
  titleOfNameTitle = '';
  color = '';
}

export class UserFollowing extends UserFollowingSmall implements Partial<ExpertProfile> {
  titleOfNameTitle = '';
  userExpertProfileSpeakLanguageTitles = '';
  isHmo = false;
  isHospital = false;
  isPrivateWorkPlaceName = false;
  hmoTitle = '';
  hmoName = '';
  hmoCityTitle = '';
  hospitalTitle = '';
  hospitalDepartmentTitle = '';
  hospitalPosition = '';
  privateWorkPlaceName = '';
  privateWorkPlaceMedicalPractice = '';
  following = false;
  follower = false;
  readonly userExpertProfileTagIDs!: { ID: string }[];
}
