import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface StateTags {
  checkedTagIDs: string[];
}

const initState = (): StateTags => {
  return {
    checkedTagIDs: [],
  };
};
const slice = createSlice({
  name: 'TAGS',
  initialState: initState(),
  reducers: {
    uncheckAllTags(state) {
      state.checkedTagIDs = [];
    },
    addTag(state, action: PayloadAction<string>) {
      state.checkedTagIDs.push(action.payload);
    },
    removeTag(state, action: PayloadAction<string>) {
      state.checkedTagIDs = state.checkedTagIDs.filter((checkedID) => checkedID !== action.payload);
    },
    verifyTags(state, action: PayloadAction<string[]>) {
      const userTagIDs = action.payload;
      state.checkedTagIDs = state.checkedTagIDs.filter((checkedID) =>
        userTagIDs.some((userTagID) => userTagID === checkedID),
      );
    },
  },
});

export const { addTag, removeTag, uncheckAllTags, verifyTags } = slice.actions;

export const reducerTags = slice.reducer;
