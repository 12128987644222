import { useAppDispatch, useAppSelector } from 'hooks';
import { useCallback } from 'react';
import { actionChatSwitchChat, selectChatIsOpen } from '../store';

export const useChatOpen = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectChatIsOpen);
  const onOpen = useCallback(() => {
    dispatch(actionChatSwitchChat(true));
  }, [dispatch]);
  const onClose = useCallback(() => {
    dispatch(actionChatSwitchChat(false));
  }, [dispatch]);
  const onToggle = useCallback(() => {
    dispatch(actionChatSwitchChat(!isOpen));
  }, [dispatch, isOpen]);

  return { isOpen, onOpen, onClose, onToggle };
};
