import axios, { AxiosRequestConfig } from 'axios';
import React, { useEffect } from 'react';
import { API, prepareRequest } from 'utils';

const instances = [axios, API];
const patchDotNet5 = (conf: AxiosRequestConfig) => {
  if (conf.method === 'patch' && conf.data && typeof conf.data === 'object') {
    conf.data = Object.entries(prepareRequest(conf.data))
      .filter(([, value]) => value !== undefined)
      .map(([path, value]) => {
        return { path, value };
      });
  }
  return conf;
};

interface Props {
  children: React.ReactNode
}
export const AxiosInterceptorPatch: React.FC<Props> = ({ children }) => {
  useEffect(() => {
    instances.map((instance) => {
      return instance.interceptors.request.use(patchDotNet5);
    });
  }, []);

  return <>{children}</>;
};
