import { useMemo } from 'react';
import { useSourceExpertTypes } from './sources';

export const useExpertTypeInfo = (expertTypeID: string | undefined) => {
  const { map } = useSourceExpertTypes();

  const prefix = useMemo(() => {
    return String((expertTypeID && map[expertTypeID]?.licensePrefix) || '');
  }, [map, expertTypeID]);
  const externalLink = useMemo(() => {
    return expertTypeID && map[expertTypeID]?.externalLinkURL;
  }, [map, expertTypeID]);

  const mask = prefix;

  return {
    mask,
    prefix,
    externalLink,
  };
};
