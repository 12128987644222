import { TextFieldProps } from '@mui/material';
import { useCallback } from 'react';
import { UseFormStateReturn } from 'react-hook-form/dist/types';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { getHandlerKayPress } from 'utils/handlers';
import { decorateAfter, fieldToLabelKey } from 'utils/other';
import { useTranslate } from './use-translate';

interface Options {
  field: ControllerRenderProps<any, any>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<any>;
}

type Props = Omit<TextFieldProps, 'onChange'> & {
  onChange: () => void;
};

export const useFilterInputProps = (props: Props) => {
  const { t } = useTranslate();
  return useCallback(
    (options: Options) => {
      const { field } = options;
      return {
        ...props,
        ...field,
        onKeyPress: getHandlerKayPress('Enter', props.onChange),
        onBlur: decorateAfter(field.onBlur, props.onChange),
        onClear: props.onChange,
        label: t(fieldToLabelKey(field.name)),
      };
    },
    [props, t],
  );
};
