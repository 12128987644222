import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MedicalEvent } from 'services/medical-events';
import { combineActions } from 'utils/sagas';
import { addTag, removeTag, uncheckAllTags, verifyTags } from '../tags';

interface State {
  applyTags: boolean;
  isLoading: boolean;
  isInit: boolean;
  data: MedicalEvent[];
  error: null | Error;
}

export const initState = (): State => {
  return {
    applyTags: false,
    isLoading: false,
    isInit: false,
    data: [],
    error: null,
  };
};

const slice = createSlice({
  name: 'MEDICAL_EVENTS_LATEST',
  initialState: initState(),
  reducers: {
    requestGet(state) {
      state.isLoading = true;
      state.error = null;
    },
    successGet(state, action: PayloadAction<{ data: MedicalEvent[] }>) {
      state.isLoading = false;
      state.isInit = true;
      state.error = null;
      state.data = action.payload.data;
    },
    failGet(state, action: PayloadAction<{ error: Error }>) {
      state.isLoading = false;
      state.isInit = true;
      state.error = action.payload.error;
    },
    actionMedicalEventsLatestSetApplyTags(state, action: PayloadAction<boolean>) {
      state.applyTags = action.payload;
      state.isInit = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => {
        return [addTag.type, removeTag.type, verifyTags.type, uncheckAllTags.type].some(
          (itemAction) => itemAction === action.type,
        );
      },
      (state) => {
        state.isInit = state.applyTags ? false : state.isInit;
      },
    );
  },
});

const actions = slice.actions;
export const { actionMedicalEventsLatestSetApplyTags } = actions;

export const actionMedicalEventsLatestGet = combineActions(
  actions.requestGet,
  actions.successGet,
  actions.failGet,
);

export const reducerMedEventsLatest = slice.reducer;
