import { AppLoading } from 'components/loading';
import { useAppSelector, useTranslate } from 'hooks';
import React, { FC } from 'react';
import { selectAccountsState } from 'store/accounts';

interface Props {
  children: React.ReactNode;
}

export const AppLoadingProvider: FC<Props> = ({ children }) => {
  const auth = useAppSelector(selectAccountsState);
  const { isLoading } = useTranslate();

  return (
    <>
      {auth.init && children}
      <AppLoading active={(!auth.init && auth.loading) || isLoading} />
    </>
  );
};
