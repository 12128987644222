import { useAppSelector, useGetRequest } from 'hooks';
import React, { useEffect } from 'react';
import FullStory, { identify, setUserVars } from 'react-fullstory';
import { ServiceParameters } from 'services/parameters';
import { selectAccountSignIn, selectAccountsUser } from 'store/accounts';

const isDev = process.env.NODE_ENV === 'development';

interface FullStoryViewProps {
  code: string;
}

export const FullStoryView: React.FC<FullStoryViewProps> = ({ code }) => {
  const currentUser = useAppSelector(selectAccountsUser);
  const currentUserID = currentUser?.appIdentityUserID;

  useEffect(() => {
    if (currentUserID) {
      identify(currentUserID);
    } else {
      identify(false);
    }
  }, [currentUserID]);
  const currentUserFirstName = currentUser?.firstName;
  const currentUserLastName = currentUser?.lastName;

  useEffect(() => {
    if (currentUserFirstName || currentUserLastName) {
      setUserVars({
        isUser: true,
        displayName: [currentUserFirstName, currentUserLastName].filter((v) => !!v).join(' '),
        firstName: currentUserFirstName,
        lastName: currentUserLastName,
      });
    }
  }, [currentUserFirstName, currentUserLastName]);

  const { mobilePhone, email } = useAppSelector(selectAccountSignIn);
  useEffect(() => {
    if (mobilePhone) {
      setUserVars({
        mobilePhone,
      });
    }
  }, [mobilePhone]);

  useEffect(() => {
    if (email) {
      setUserVars({
        email,
      });
    }
  }, [email]);

  return <FullStory org={code} debug={isDev} />;
};

interface Props {
  children: React.ReactNode;
}
export const FullStoryProvider: React.FC<Props> = ({ children }) => {
  const { data, request } = useGetRequest({ request: ServiceParameters.getFullStoryCode });

  useEffect(() => {
    request();
  }, [request]);

  return (
    <>
      {data && <FullStoryView code={data} />}
      {children}
    </>
  );
};
