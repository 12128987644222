import { useMemo } from 'react';
import { apiParameters } from 'services/parameters';
import { apiAuth } from '../services/auth';
import { apiCurrentUser } from '../services/current-user';
import { apiPages } from '../services/pages';
import { ArrayModel } from '../utils';
import { useMap } from './use-map';

type DataType<T> = T extends { data?: infer U } ? U : unknown;

const useSourceData = <T extends { data?: any[] }>(v: T) => {
  const data = useMemo(() => {
    return (v.data || []) as Exclude<DataType<T>, undefined>;
  }, [v.data]);
  return { ...v, data };
};
const useSourceDataMap = <T extends { data: any[] }>(v: T, mapKey: keyof ArrayModel<T['data']>) => {
  const map = useMap(v.data as ArrayModel<T['data']>[], String(mapKey));
  return { ...v, map };
};

export const useSourceTitleOfNames = () => {
  return useSourceData(apiParameters.endpoints.getTitleOfNames.useQuery());
};
export const useSourceTags = () => {
  return useSourceData(apiParameters.endpoints.getTags.useQuery());
};
export const useSourceUserTags = () => {
  return useSourceData(apiCurrentUser.endpoints.getUserTags.useQuery());
};
export const useSourceCities = () => {
  return useSourceData(apiParameters.endpoints.getCities.useQuery());
};
export const useSourceDepartments = () => {
  return useSourceData(apiParameters.endpoints.getDepartments.useQuery());
};
export const useSourceHmos = () => {
  return useSourceData(apiParameters.endpoints.getHmos.useQuery());
};
export const useSourceHospitals = () => {
  return useSourceData(apiParameters.endpoints.getHospitals.useQuery());
};
export const useSourceMedicalFields = () => {
  return useSourceData(apiParameters.endpoints.getMedicalFields.useQuery());
};
export const useSourceMedicalFieldsDoctors = useSourceMedicalFields;

export const useSourceExperts = () => {
  return useSourceData(apiParameters.endpoints.getExperts.useQuery());
};
export const useSourceSpeakLanguages = () => {
  return useSourceData(apiParameters.endpoints.getSpeakLanguages.useQuery());
};
export const useSourceWorkPlaces = () => {
  return useSourceData(apiParameters.endpoints.getWorkPlaces.useQuery());
};
export const useSourceExpertTypes = () => {
  return useSourceDataMap(useSourceData(apiAuth.endpoints.getExpertTypes.useQuery()), 'id');
};
export const useSourceTargetAudiences = (eventID: string) => {
  return useSourceDataMap(
    useSourceData(apiAuth.endpoints.getTargetAudiences.useQuery(eventID)),
    'id',
  );
};
export const useSourceArticleCategories = () => {
  return useSourceDataMap(
    useSourceData(apiParameters.endpoints.getPostCategories.useQuery()),
    'id',
  );
};

export const useSourceExpertTypesOptions = (eventID: string | undefined | null) => {
  const sourceTargetAudience = useSourceData(
    apiAuth.endpoints.getTargetAudiences.useQuery(String(eventID), { skip: !eventID }),
  );
  const dataTargetAudiences = useMemo(() => {
    return sourceTargetAudience.data.map((item) => {
      return { ...item, id: item.expertTypeID };
    });
  }, [sourceTargetAudience.data]);
  const normalizedResTargetAudience = useMemo(() => {
    return {
      ...sourceTargetAudience,
      data: dataTargetAudiences,
    };
  }, [sourceTargetAudience, dataTargetAudiences]);

  const resTargetAudience = useSourceDataMap(normalizedResTargetAudience, 'id');
  const resExpertTypes = useSourceExpertTypes();

  return eventID ? resTargetAudience : resExpertTypes;
};

export const useGetSignUpDoctors = apiPages.endpoints.getSignUpDoctors.useQuery;
export const useGetSignUpEvents = apiPages.endpoints.getSingUpEvents.useQuery;
