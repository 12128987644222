import { Button, Dialog, Grid } from '@mui/material';
import { AppDialogContent } from 'components/dialogs/components/dialog-content';
import { AppDialogFooter } from 'components/dialogs/components/dialog-footer';
import { useTranslate } from 'hooks';
import React from 'react';
import { AppDialogTitle } from '../title';
import style from './index.module.scss';

interface Props {
  className?: string;
  onConfirm: () => void;
  onClose: () => void;
  Title?: React.ReactNode;
  children: React.ReactNode;
}

export const DialogConfirm: React.FC<Props> = ({ onClose, onConfirm, children }) => {
  const { t } = useTranslate();

  return (
    <Dialog open={true} onClose={onClose} maxWidth={'md'} scroll={'body'} fullWidth>
      <AppDialogTitle onClose={onClose} />

      <AppDialogContent>
        {children && <div className={style.title}>{children}</div>}
      </AppDialogContent>

      <AppDialogFooter>
        <Grid container justifyContent={'center'} columnSpacing={3} rowSpacing={1.5}>
          <Grid item>
            <Button color={'secondary'} onClick={onClose}>
              {t('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button variant={'contained'} color={'primary'} onClick={onConfirm}>
              {t('confirm')}
            </Button>
          </Grid>
        </Grid>
      </AppDialogFooter>
    </Dialog>
  );
};
