import { AppFooter } from 'components/app-footer';
import { AppHeader } from 'components/app-header';
import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import style from './index.module.scss';

export const PrivateLayout = memo(() => {
  return (
    <>
      <AppHeader />
      <main className={style.main}>
        <Outlet />
      </main>
      <AppFooter />
    </>
  );
});
