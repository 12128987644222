import { format } from 'date-fns';
import { useMemo } from 'react';
import { covertToDateZero } from '../utils';

export const useDateFormatSplit = (date?: string | Date | null) => {
  const _date = useMemo(() => {
    return covertToDateZero(date);
  }, [date]);
  const locales = useMemo(() => {
    return 'he';
  }, []);

  const year = useMemo(() => {
    return _date ? _date.toLocaleDateString(locales, { year: 'numeric' }) : '';
  }, [locales, _date]);
  const month = useMemo(() => {
    return _date ? _date.toLocaleDateString(locales, { month: 'short' }) : '';
  }, [locales, _date]);
  const day = useMemo(() => {
    return _date ? _date.toLocaleDateString(locales, { day: '2-digit' }) : '';
  }, [locales, _date]);

  const hours = useMemo(() => {
    return _date ? format(_date, 'HH') : '';
  }, [_date]);
  const minutes = useMemo(() => {
    return _date ? format(_date, 'mm') : '';
  }, [_date]);

  return useMemo(() => {
    return { year, month, day, hours, minutes };
  }, [year, month, day, hours, minutes]);
};
export const useDateFormat = (date?: string | Date | null) => {
  const { year, day, month } = useDateFormatSplit(date);

  return useMemo(() => {
    return [day, month, year].filter((v) => !!v).join(' ');
  }, [day, month, year]);
};
export const useDateFormatTime = (date?: string | Date | null) => {
  const { year, day, month, hours, minutes } = useDateFormatSplit(date);

  return useMemo(() => {
    return [day, month, year, `${hours}:${minutes}`].filter((v) => !!v).join(' ');
  }, [day, month, year, hours, minutes]);
};
