import { useCallback, useState } from 'react';
import { LicenseInfo } from 'services/auth';
import { validateUserAction } from 'store/accounts';
import { useAppDispatch } from './use-app-dispatch';
import { useMountedRef } from './use-mounted-ref';

export const useValidateLicense = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [licenseInfo, setLicenseInfo] = useState<LicenseInfo | null>(null);
  const dispatch = useAppDispatch();
  const mountedRef = useMountedRef();

  const onValidate = useCallback(
    async ({ externalCertificateLicenseID }: { externalCertificateLicenseID: string }) => {
      setIsLoading(true);
      setLicenseInfo(null);
      setError(null);
      const result = await dispatch(validateUserAction({ externalCertificateLicenseID }));

      if (!mountedRef.current) {
        return;
      }

      if (validateUserAction.fulfilled.match(result)) {
        setLicenseInfo(result.payload.data);
      }
      if (validateUserAction.rejected.match(result)) {
        setError(result.error);
      }

      setIsLoading(false);
    },
    [dispatch, mountedRef],
  );

  return { isLoading, setIsLoading, error, setError, licenseInfo, setLicenseInfo, onValidate };
};
