import { createSelector } from 'reselect';
import { AppState } from '../index';

const selectState = (state: AppState) => state.surveyLatest;

export const selectSurveyLatest = createSelector(selectState, ({ survey }) => {
  return survey;
});
export const selectSurveyLatestStatuses = createSelector(
  selectState,
  ({ isLoading, error, isInit, countUnvoted }) => {
    return { isLoading, error, isInit, countUnvoted };
  },
);
export const selectSurveyLatestVoted = createSelector(selectSurveyLatest, (survey) => {
  return survey ? Boolean(survey.userSurveyOptionID) : true;
});
export const selectSurveyLatestHasUnvoted = createSelector(
  selectSurveyLatestStatuses,
  ({ countUnvoted }) => {
    return countUnvoted > 1;
  },
);
export const selectSurveyLatestOptions = createSelector(selectSurveyLatest, (survey) => {
  return survey?.surveyOptionItems || [];
});
