import { API, BaseParams } from 'utils';
import { API_SURVEYS, Survey } from './models';

export * from './models';

class Service {
  async get() {
    return API.get<Survey>(API_SURVEYS.GET);
  }

  async getCountUnVoted() {
    const params: BaseParams = {
      select: 'id',
      filter: 'userSurveyOptionID == null',
      count: true,
    };
    const result = await API.get<{ count: number }>(API_SURVEYS.GET_ALL_DYNAMIC, { params });
    return { ...result, data: result.data.count };
  }

  private async vote(optionID: string) {
    await API.get(API_SURVEYS.VOTE({ optionID }));
  }

  async getByID(surveyID: string) {
    const result = await ServiceSurveys.getAllDynamic({ filter: `id == "${surveyID}"`, take: 1 });

    if (result.data.value.length === 0) {
      throw new Error('record-not-found');
    }
    return { ...result, data: result.data.value[0] };
  }

  async voteSurveyItem({ optionID, surveyID }: { surveyID: string; optionID: string }) {
    await ServiceSurveys.vote(optionID);
    return ServiceSurveys.getByID(surveyID);
  }

  async getAllDynamic(params: BaseParams) {
    const result = await API.get<{ value: Survey[]; count: number }>(API_SURVEYS.GET_ALL_DYNAMIC, {
      params,
    });
    return {
      ...result,
      data: {
        ...result.data,
        value: result.data.value.map((item) => ({
          ...item,
          totalVotes:
            item.totalVotes ||
            item.surveyOptionItems.reduce((acc, item) => {
              acc += item.votes;
              return acc;
            }, 0),
        })),
      },
    };
  }
}

export const ServiceSurveys = new Service();
