import { Box, BoxProps } from '@mui/material';
import clsx from 'clsx';
import { forwardRef, memo } from 'react';
import style from './index.module.scss';

interface Props extends BoxProps {
  className?: string;
  isVisible?: boolean;
}

const Component = forwardRef<typeof Box, Props>(
  ({ className, isVisible, children, onScroll, ...rest }, ref) => {
    return (
      <Box ref={ref} className={clsx(style.root, className, isVisible && style.visible)} {...rest}>
        {children}
      </Box>
    );
  },
);

export const NativeScroll = memo(Component) as typeof Component;
