import { createTypedRoute } from 'modules/typed-routes';
type SignInPayload = {
  eventID?: string;
  ignoreType: boolean;
};

export enum DASHBOARD_TAGS {
  SURVEY = 'SURVEY',
  FOLLOWING = 'FOLLOWING',
  TAGS = 'TAGS',
  EXPERTS = 'EXPERTS',
  MED_EVENTS = 'MED_EVENTS',
  DASHBOARD = 'DASHBOARD',
  ONLINE_USERS = 'ONLINE_USERS',
}

export const APP_ROUTES = {
  SIGNUP_EVENT: createTypedRoute<{ eventID: string }>('/sign-up/event/:eventID'),

  SIGN_IN: createTypedRoute<{ expertTypeID?: string; mobilePhone?: string } & SignInPayload>(
    '/sign-in',
  ),
  SIGN_IN_CODE: createTypedRoute<SignInPayload>('/sign-in/code'),

  SIGNUP_DOCTORS_3: createTypedRoute<SignInPayload>('/sign-up/doctors/step-3'),
  SIGNUP_DOCTORS_4: createTypedRoute<SignInPayload>('/sign-up/doctors/step-4'),
  SIGNUP_DOCTORS_5: createTypedRoute<SignInPayload>('/sign-up/doctors/step-5'),

  SIGNUP_EXPERTS_3: createTypedRoute<SignInPayload>('/sign-up/med-staffs/step-3'),
  SIGNUP_EXPERTS_4: createTypedRoute<SignInPayload>('/sign-up/med-staffs/step-4'),
  SIGNUP_EXPERTS_5: createTypedRoute<SignInPayload>('/sign-up/med-staffs/step-5'),

  SIGNUP_VISITORS_3: createTypedRoute<SignInPayload>('/sign-up/users/step-3'),

  SIGNUP_FINISH_CONTROLLER: createTypedRoute<{ eventID: string | undefined; ignoreType: boolean }>(
    '/_controller',
  ),

  DASHBOARD: createTypedRoute<{ tab?: DASHBOARD_TAGS; postDialog?: true } | void>('/'),

  EVENT_LIST: createTypedRoute('/events'),
  EVENT: createTypedRoute<{ eventID: string }>('/events/item'),
  EVENT_THANKS: createTypedRoute<{ eventID: string }>('/events/item/thanks'),

  ONLINE_USERS: createTypedRoute('/online-users'),
  USER: createTypedRoute<{ member: string }>('/member'),
  PROFILE: createTypedRoute('/profile'),
  MESSAGES_HUB: createTypedRoute('/messages'),

  NOT_FOUND: createTypedRoute('/not-found'),
  SEARCH: createTypedRoute('/search'),
  SURVEYS: createTypedRoute('/surveys'),
  SURVEY: createTypedRoute<{ surveyID: string }>('/surveys/:surveyID'),
  ARTICLE: createTypedRoute<{ articleID: string }>('/article/:articleID'),
  FOLLOWING: createTypedRoute('/following'),
  SPECIALISTS: createTypedRoute('/specialists'),
};
