import { Badge, ListItem } from '@mui/material';
import clsx from 'clsx';
import { useOnlineUsers, useTranslate } from 'hooks';
import React from 'react';
import { AppAvatar } from '../app-avatar';
import { ItemLabel } from '../item-lable';
import { UserTooltip } from '../user-tooltip';
import style from './index.module.scss';

interface AppUserSmallProps {
  appUserID: string;
  className?: string;
  photo?: string;
  fullName?: string;
  firstName: string | undefined;
  lastName: string | undefined;
  position?: string;
  medicalFocus?: string;
  onClick?: () => void;
  size?: 'small' | 'medium';
  avatarBgColor: string | undefined;
}

export const AppUserSmall: React.FC<AppUserSmallProps> = ({
  avatarBgColor,
  appUserID,
  photo,
  fullName,
  position,
  medicalFocus,
  onClick,
  size = 'medium',
  className,
}) => {
  const { t } = useTranslate();
  const { checkIsOnline } = useOnlineUsers();

  return (
    <UserTooltip expertID={appUserID} className={className}>
      <ListItem button onClick={onClick} className={clsx(style.user, style[size])}>
        <Badge
          invisible
          overlap={'circular'}
          variant={'dot'}
          color={'primary'}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <AppAvatar
            bgColor={avatarBgColor}
            isOnline={checkIsOnline(appUserID)}
            size={'xs'}
            src={photo}
          />
        </Badge>
        <div className={style.user_small_content}>
          <div className={style.name}>{fullName}</div>
          {position && <p>{position}</p>}
          <ItemLabel label={t('primary-medical-field')} value={medicalFocus} />
        </div>
      </ListItem>
    </UserTooltip>
  );
};
