import { toDate as fnsToDate } from 'date-fns';

export type DateValue = Date | string | number | null | undefined;

export const covertToDateZero = (value: DateValue) => {
  const date = convertToDate(value);
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    ),
  );
};
export const convertToDate = (value: DateValue) => {
  if (!value) {
    return getCurrentDate();
  }
  if (typeof value === 'string') {
    return new Date(value);
  }
  return fnsToDate(value);
};
export const convertToString = (value: DateValue) => {
  return value ? String(value) : '';
};
export const getCurrentDate = (hours = 8, minutes = 0, seconds = 0) => {
  const _date = new Date();
  _date.setHours(hours);
  _date.setMinutes(minutes);
  _date.setSeconds(seconds);
  return _date;
};
