import { ListItem } from '@mui/material';
import clsx from 'clsx';
import { AppAvatar } from 'components/app-avatar';
import React, { useCallback, useMemo } from 'react';
import { sanitizeChatMessage } from '../../utils';
import { ChatTyping } from '../typing';
import style from './index.module.scss';

interface Props {
  id: string;
  photo?: string | null;
  fullName: string;
  lastMessage: string;
  isOnline: boolean;
  isNotRead: boolean;
  hasNewMessage?: boolean;
  selected?: boolean;
  onClick: (id: string) => void;
  conversationID: string;
  isTyping: boolean;
}

export const CustomChatDialogItem: React.FC<Props> = ({
  id,
  isOnline,
  isNotRead,
  photo,
  lastMessage,
  selected,
  onClick,
  isTyping,
  fullName,
}) => {
  const handleClick = useCallback(() => {
    onClick(id);
  }, [onClick, id]);

  const cleanMessage = useMemo(() => {
    return sanitizeChatMessage(lastMessage);
  }, [lastMessage]);

  return (
    <ListItem
      className={style.wrap}
      disableGutters
      selected={selected}
      button
      onClick={handleClick}
    >
      <div className={clsx(style.user, isNotRead && style.notRead)}>
        <div>
          <AppAvatar src={photo} size={'xs'} isOnline={isOnline} bgColor={'transparent'} />
        </div>
        <div className={style.cont}>
          <div className={style.name}>{fullName}</div>
          <div className={style.message}>
            {isTyping ? (
              <ChatTyping />
            ) : (
              <span dangerouslySetInnerHTML={{ __html: cleanMessage }} />
            )}
          </div>
        </div>
      </div>
    </ListItem>
  );
};
