import CloseIcon from '@mui/icons-material/Close';
import { Dialog, Drawer, IconButton, Theme, useMediaQuery } from '@mui/material';
import { selectChatStatuses } from 'components/cutom-chat/store';
import { useAppSelector, useTranslate } from 'hooks';
import React from 'react';
import { useChatAutoSelect, useChatConversation, useChatOpen } from '../../hooks';
import { CustomChatConversations } from '../conversations';
import { CustomChantMessages } from '../messages';
import style from './index.module.scss';

const CustomChantLayoutMobile: React.FC = () => {
  const { conversationID, onSelect: onSelectConversation } = useChatConversation();
  const { onClose } = useChatOpen();
  const { t } = useTranslate();
  const { isLoading } = useAppSelector(selectChatStatuses);

  return (
    <>
      <div className={style.wrap}>
        <div className={style.header}>
          <div className={style.title}>{t('chat-messenger')}</div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={style.dialogs}>
          <CustomChatConversations
            isLoading={isLoading}
            conversationID={conversationID}
            onSelectConversation={onSelectConversation}
          />
        </div>
      </div>
      <Dialog open={Boolean(conversationID)} fullScreen={true}>
        <CustomChantMessages isShowClose />
      </Dialog>
    </>
  );
};
const CustomChantLayoutDesktop: React.FC = () => {
  const { t } = useTranslate();
  const { onClose } = useChatOpen();

  const { isLoading } = useAppSelector(selectChatStatuses);

  const { conversationID, onSelect: onSelectConversation } = useChatConversation();
  useChatAutoSelect();

  return (
    <div className={style.wrap}>
      <div className={style.header}>
        <div className={style.title}>{t('chat-messenger')}</div>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={style.messages}>
        <CustomChantMessages />
      </div>
      <div className={style.dialogs}>
        <CustomChatConversations
          isLoading={isLoading}
          conversationID={conversationID}
          onSelectConversation={onSelectConversation}
        />
      </div>
    </div>
  );
};

export const CustomChantLayout: React.FC = () => {
  const { isOpen, onClose } = useChatOpen();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Drawer open={isOpen} onClose={onClose} anchor={'left'} disablePortal disableEnforceFocus>
      {isMobile ? <CustomChantLayoutMobile /> : <CustomChantLayoutDesktop />}
    </Drawer>
  );
};
