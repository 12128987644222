import { useMemo } from 'react';

export const useMap = <T>(source: T[], key: any = 'id') => {
  return useMemo(() => {
    return source.reduce((acc, item) => {
      if (typeof item === 'object') {
        // @ts-ignore
        acc[item[key]] = item;
      } else {
        // @ts-ignore
        acc[String(item)] = item;
      }
      return acc;
    }, {} as Record<string, T | undefined>);
  }, [source, key]);
};
