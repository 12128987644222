import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Drawer, IconButton, Typography } from '@mui/material';
import { useTranslate } from 'hooks';
import React from 'react';
import VARS from 'styles/config.scss';
import style from './index.module.scss';

interface DrawerFilterProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  Footer?: React.ReactNode;
  children: React.ReactNode;
}

export const DrawerFilter: React.FC<DrawerFilterProps> = ({
  isOpen,
  onClose,
  children,
  Footer,
  title,
}) => {
  const { t } = useTranslate();
  return (
    <Drawer open={isOpen} onClose={onClose} anchor={'right'} className={style.box}>
      <div className={style.header}>
        <div className={style.title}>
          <FilterListIcon color={'secondary'} />
          <Typography fontSize={'1.8rem'} color={'secondary'}>
            {title || t('filter')}
          </Typography>
        </div>
        <div className={style.close}>
          <IconButton onClick={onClose} color={'inherit'} style={{ color: VARS.colorBlack30 }}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className={style.body}>{children}</div>
      {Boolean(Footer) && <div className={style.footer}>{Footer}</div>}
    </Drawer>
  );
};
