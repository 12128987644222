import { API, BaseParams, createFilterEquals } from 'utils';
import { createFilter } from 'utils/service-filters';
import { apiRtk, API_RTK_TAGS, transformResponseDynamicItem } from '../apiRtk';
import {
  API_EXPERT_PROFILES,
  ExpertProfile,
  UserBaseInfo,
  UserFollowing,
  UserFollowingSmall,
} from './models';

export * from './models';

class Service {
  async getDynamic<T = ExpertProfile>(params?: BaseParams) {
    return API.get<{ value: T[]; count: number }>(API_EXPERT_PROFILES.GET_DYNAMIC, {
      params: {
        ...params,
        count: true,
      },
    });
  }

  async getByID(id: string) {
    const { data } = await ServiceExpertProfiles.getDynamic({
      filter: `appIdentityUserID == "${id}"`,
      take: 1,
    });
    if (data.value.length === 0) {
      throw Error('record-not-found');
    }
    return { data: data.value[0] };
  }

  async getFollowing(params?: Omit<BaseParams, 'select'>) {
    const _params = {
      ...params,
      select: Object.keys(new UserFollowing()).join(','),
      filter: ['(following == true)', params?.filter].filter((v) => !!v).join('&&'),
    };
    return ServiceExpertProfiles.getDynamic(_params);
  }

  async getFollowingSmall(params?: Omit<BaseParams, 'select'>) {
    const _params = {
      ...params,
      select: Object.keys(new UserFollowingSmall()).join(','),
      filter: ['(following == true)', params?.filter].filter((v) => !!v).join('&&'),
    };
    return ServiceExpertProfiles.getDynamic(_params);
  }

  async getExperts(params?: Omit<BaseParams, 'select'>) {
    const _params = {
      ...params,
      select: Object.keys(new UserFollowing()).join(','),
    };
    return ServiceExpertProfiles.getDynamic(_params);
  }

  async getOnlineUsers(listIDs: string[]) {
    const PARTIAL_COUNT = 5;
    const select = Object.keys(new UserBaseInfo()).join(',');
    const partialList = [];

    for (let i = 0; i < listIDs.length; i = i + PARTIAL_COUNT) {
      partialList.push(listIDs.slice(i, i + PARTIAL_COUNT));
    }

    const result = await Promise.all(
      partialList.map((ids) => {
        return ServiceExpertProfiles.getDynamic<UserBaseInfo>({
          select,
          filter: ids
            .map((value) => createFilterEquals<ExpertProfile>({ name: 'appIdentityUserID', value }))
            .join('||'),
        });
      }),
    );
    const value = result.map(({ data: { value } }) => value).flat();
    return { data: { value } };
  }
  async getOnlineUser(userID: string) {
    const select = Object.keys(new UserBaseInfo()).join(',');

    const result = await ServiceExpertProfiles.getDynamic<UserBaseInfo>({
      select,
      filter: createFilterEquals<ExpertProfile>({ name: 'appIdentityUserID', value: userID }),
      take: 1,
    });

    if (!result.data.value[0]) {
      throw new Error('expert-online-user-not-found');
    }

    return { ...result, data: result.data.value[0] };
  }
}

export const ServiceExpertProfiles = new Service();

export const apiExpertProfiles = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<ExpertProfile, { expertID: string }>({
      query: ({ expertID }) => ({
        url: API_EXPERT_PROFILES.GET_DYNAMIC,
        params: {
          take: 1,
          filter: createFilter<ExpertProfile>('appIdentityUserID', '==', expertID),
        },
      }),
      transformResponse: transformResponseDynamicItem,
      providesTags: (res, err, arg) => [{ type: API_RTK_TAGS.USER_INFO, id: arg.expertID }],
    }),
  }),
});
