import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton } from '@mui/material';
import { AutocompleteRenderOption } from 'models/material';
import style from './index.module.scss';

export const RenderOption: AutocompleteRenderOption<{ id: string; title: string }> = (
  props,
  option,
  { selected },
) => {
  return (
    <li {...props}>
      <div className={style.wrap}>
        <span className={style.name}>{option.title}</span>
        <IconButton size={'small'} className={style.icon}>
          {selected ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
      </div>
    </li>
  );
};
