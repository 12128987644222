import FaceIcon from '@mui/icons-material/Face';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { Alert, AlertTitle, Button, Chip, Dialog, Grid, TextField } from '@mui/material';
import { AppDialogContent, AppDialogFooter } from 'components/dialogs';
import { AppDialogTitle } from 'components/dialogs/components/title';
import { useNotify, useTranslate } from 'hooks';
import { useFormInputProps } from 'hooks/use-form-input-props';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ServiceInvitations } from 'services/invitations';
import { findEmails } from 'utils';
import style from './index.module.scss';

interface AppDialogInviteProps {
  className?: string;
  onClose: () => void;
}

class Model {
  text: string = '';
}

export const DialogInvite: React.FC<AppDialogInviteProps> = ({ onClose: _onClose }) => {
  const { t } = useTranslate();
  const { control, handleSubmit, reset } = useForm({
    shouldUnregister: true,
    defaultValues: new Model(),
    mode: 'onSubmit',
  });
  const getInputProps = useFormInputProps();

  const validate = useCallback(
    (data: string) => {
      const list = findEmails(data);
      if (list.length === 0) {
        return t('rule-emails-not-found');
      }
      return undefined;
    },
    [t],
  );

  const [isLoading, setLoading] = useState(false);
  const [emailSentList, setEmailSentList] = useState<string[]>([]);

  const { onError } = useNotify();
  const onSubmit = useCallback(
    async (data: { text: string }) => {
      setLoading(true);
      try {
        const list = findEmails(data.text);
        await ServiceInvitations.inviteBalkMembers(list);
        setEmailSentList(list);
      } catch (e: any) {
        onError(e);
      } finally {
        setLoading(false);
      }
    },
    [onError],
  );

  const onClose = () => {
    setEmailSentList([]);
    reset();
    _onClose();
  };
  return (
    <Dialog open={true} onClose={onClose} maxWidth={'md'} scroll={'paper'} fullWidth>
      <AppDialogTitle Icon={<GroupAddIcon />} onClose={onClose}>
        {t('invite-doctors-to-join')}
      </AppDialogTitle>
      <AppDialogContent>
        {emailSentList.length > 0 ? (
          <Alert severity={'success'} className={style.alert} classes={{ message: style.message }}>
            <AlertTitle>
              <strong>{t('invitation-success')}</strong>
            </AlertTitle>
            <div className={style.list}>
              {emailSentList.map((email, i) => {
                return (
                  <Chip
                    key={i}
                    color={'primary'}
                    deleteIcon={<FaceIcon />}
                    label={
                      <a className={style.chipLink} href={`mailto:${email}`}>
                        {email}
                      </a>
                    }
                    onDelete={() => void 1}
                  />
                );
              })}
            </div>
          </Alert>
        ) : (
          <Controller
            name={'text'}
            control={control}
            render={(renderProps) => {
              const props = getInputProps(renderProps);
              return (
                <TextField
                  multiline={true}
                  rows={5}
                  {...props}
                  fullWidth
                  label={t('email-list')}
                  style={{ direction: 'ltr' }}
                  disabled={isLoading}
                />
              );
            }}
            rules={{
              required: true,
              validate: validate,
            }}
          />
        )}
      </AppDialogContent>
      <AppDialogFooter>
        <Grid container columnSpacing={3} rowSpacing={2} justifyContent={'flex-end'}>
          <Grid item>
            <Button
              variant={emailSentList.length > 0 ? 'contained' : undefined}
              color={emailSentList.length > 0 ? undefined : 'secondary'}
              onClick={onClose}
            >
              {t(emailSentList.length > 0 ? 'close' : 'cancel')}
            </Button>
          </Grid>
          {emailSentList.length === 0 && (
            <Grid item>
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={handleSubmit(onSubmit)}
                disabled={isLoading}
              >
                {t('send-an-invitation')}
              </Button>
            </Grid>
          )}
        </Grid>
      </AppDialogFooter>
    </Dialog>
  );
};
