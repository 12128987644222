export const actionCreator =
  <T extends string>(type: T) =>
  <P = void>() =>
  (payload: P) => ({ type, payload });

export interface SagaActions {
  request(...args: any[]): any;

  success(...args: any[]): any;

  fail(...args: any[]): any;
}

type PayloadAsync<T> = T extends Object ? T & { onSuccess?: () => void; onError?: () => void } : T;
export const asyncActionCreator =
  <TR extends string, TS extends string, TF extends string>(
    typeRequest: TR,
    typeSuccess: TS,
    typeFail: TF,
  ) =>
  <PR = void, PS = void, PF = void>() => ({
    request: actionCreator<TR>(typeRequest)<PayloadAsync<PR>>(),
    success: actionCreator<TS>(typeSuccess)<PS>(),
    fail: actionCreator<TF>(typeFail)<PF>(),
  });

export type ExtractAction<T extends (p: any) => void> = ReturnType<T>;
export type ExtractPayload<T extends (p: any) => void> = ExtractAction<T>;

export type ExtractAsyncAction<T extends SagaActions> =
  | ReturnType<T['request']>
  | ReturnType<T['success']>
  | ReturnType<T['fail']>;

export type ExtractPromise<T> = T extends Promise<infer U> ? U : never;
export type ExtractCall<T> = T extends (...args: any) => Promise<any>
  ? ExtractPromise<ReturnType<T>>
  : never;
