import { API, parseErrorData, prepareRecords } from 'utils';
import { apiRtk, API_RTK_TAGS, transformResponseDynamic } from '../apiRtk';
import { ServiceAuth, User, UserWithMixins } from '../auth';
import { uploadImage } from '../media-uploads';
import { API_CURRENT_USER, UserLanguages, UserTag } from './models';

export * from './models';

class Service {
  private static async patch(data: Partial<User>) {
    return API.patch<string>(API_CURRENT_USER.PATCH, data);
  }

  async trackVideo(articleID: string) {
    return API.post(API_CURRENT_USER.TRACK_VIDEO, { articleID });
  }

  async addFollowing(id: string) {
    return API.get<string>(API_CURRENT_USER.ADD_TO_FOLLOWING(id));
  }

  async addToViewLog(
    data: Partial<{ medicalEventID: string; articleID: string; surveyID: string }>,
  ) {
    try {
      return API.post(API_CURRENT_USER.ADD_TO_VIEW_LOG, data);
    } catch (e: any) {
      // TODO ERROR
    }
  }
  async removeFollowing(id: string) {
    return API.get<string>(API_CURRENT_USER.REMOVE_FROM_FOLLOWING(id));
  }

  async setAsActive() {
    return API.get<string>(API_CURRENT_USER.SET_AS_ACTIVE);
  }

  async getUser() {
    const result = await API.get<User>(API_CURRENT_USER.GET_USER);

    const [{ data: languages }] = await Promise.all([
      ServiceCurrentUser.getUserSpeakLanguages({ id: result.data.appIdentityUserID }),
    ]);

    return {
      ...result,
      data: { ...result.data, profilePicture: result.data.userPhoto, languages },
    };
  }

  async updateCurrentUserWithNames(oldData: UserWithMixins, newData: UserWithMixins) {
    await ServiceAuth.validateExpertName({
      firstName: newData.firstName,
      lastName: newData.lastName,
    });
    await ServiceCurrentUser.updateCurrentUser(oldData, newData);
  }
  async updateCurrentUser(oldData: UserWithMixins, newData: Partial<UserWithMixins>) {
    let dataToSave = { ...newData };
    if (dataToSave.languages) {
      const { postItems, deleteItems } = prepareRecords(
        oldData.languages,
        dataToSave.languages,
        'speakLanguageID',
      );

      await ServiceCurrentUser.deleteUserSpeakLanguages(deleteItems);
      await ServiceCurrentUser.createUserSpeakLanguages(postItems);

      delete dataToSave['languages'];
    }

    if (dataToSave.userPhoto) {
      dataToSave.userPhoto = await uploadImage(dataToSave.userPhoto, oldData.userPhoto);
    }

    if (Object.keys(dataToSave).length) {
      await Service.patch(dataToSave);
    }
    return 'done';
  }

  async getUserTags() {
    const result = await API.get<{ value: UserTag[] }>(API_CURRENT_USER.TAG_GET);
    return {
      ...result,
      data: result.data.value.map((item) => {
        return item;
      }),
    };
  }

  async createUserTag(data: { tagID: string }) {
    return API.post(API_CURRENT_USER.TAG_CREATE, data);
  }

  async deleteUserTag(data: { id: string }) {
    return API.delete(API_CURRENT_USER.TAG_DELETE(data));
  }
  async getUserSpeakLanguages({ id }: { id?: string }) {
    const result = await API.get<{ value: UserLanguages[] }>(API_CURRENT_USER.SPEAK_LANGUAGE_GET);
    return {
      ...result,
      data: result.data.value.map((item) => {
        return { ...item, appIdentityUserID: id || '' };
      }),
    };
  }
  async createUserSpeakLanguages(data: { speakLanguageID: string }[]) {
    return Promise.all(
      data.map((item) => {
        return API.post(API_CURRENT_USER.SPEAK_LANGUAGE_CREATE, item);
      }),
    );
  }
  async deleteUserSpeakLanguages(data: { id: string }[]) {
    return Promise.all(
      data.map((item) => {
        return API.delete(API_CURRENT_USER.SPEAK_LANGUAGE_DELETE(item));
      }),
    );
  }
}

export const ServiceCurrentUser = new Service();

export const apiCurrentUser = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getUserTags: builder.query<UserTag[], void>({
      query() {
        return {
          url: API_CURRENT_USER.TAG_GET,
          params: { orderBy: 'title asc' },
        };
      },
      providesTags: () => [{ type: API_RTK_TAGS.USER_TAGS }],
      transformResponse: transformResponseDynamic,
    }),
    updateUserTags: builder.mutation<void, string[]>({
      queryFn: async (tagIDs) => {
        try {
          const { data } = await ServiceCurrentUser.getUserTags();
          await Promise.all(
            data.map((item) => {
              return ServiceCurrentUser.deleteUserTag(item);
            }),
          );
          await Promise.all(
            tagIDs.map((tagID) => {
              return ServiceCurrentUser.createUserTag({ tagID });
            }),
          );
          return { data: undefined };
        } catch (e: any) {
          return { error: parseErrorData(e) };
        }
      },
      invalidatesTags: () => [{ type: API_RTK_TAGS.USER_TAGS }],
    }),
  }),
});
