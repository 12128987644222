import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Popover } from '@mui/material';
import { AppUserSmall } from 'components/app-user-small';
import { APP_ROUTES } from 'configs/routes';
import { useTranslate } from 'hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PostLike } from 'services/post-likes';
import { AppScrollbars } from '../../app-scrollbar';
import style from './index.module.scss';

interface DialogPostLikesProps {
  anchorEl: Element | null;
  onClose: () => void;
  likes: PostLike[];
}

export const DialogPostLikes: React.FC<DialogPostLikesProps> = ({ anchorEl, onClose, likes }) => {
  const { t } = useTranslate();

  const push = useNavigate();

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        style: { width: '24.6rem' },
      }}
    >
      <div className={style.popover_title}>
        <div className={style.title}>{t('likes')}</div>
        <IconButton size="small" className={style.close} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <AppScrollbars autoHeight autoHeightMin="1%" autoHeightMax="21rem">
        <div className={style.popover_content}>
          <div className={style.list}>
            {likes.map(
              (
                { userPhoto, position, fullName, appIdentityUserID, firstName, lastName, color },
                i,
              ) => (
                <AppUserSmall
                  key={i}
                  avatarBgColor={color}
                  medicalFocus={''}
                  appUserID={appIdentityUserID}
                  photo={userPhoto}
                  position={position}
                  fullName={fullName}
                  firstName={firstName}
                  lastName={lastName}
                  onClick={() => push(APP_ROUTES.USER({ member: appIdentityUserID }).config)}
                />
              ),
            )}
          </div>
        </div>
      </AppScrollbars>
    </Popover>
  );
};
