import {
  actionChannel,
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
  throttle,
} from 'modules/typed-saga';
import { ServiceAuth, USER_STATUS_KEY } from 'services/auth';
import { ServiceCurrentUser } from 'services/current-user';
import { parseErrorData, setToModel } from 'utils';
import { updateAuthTokens } from 'utils/cookies';
import { notifyErrorSaga, workerErrorNotifySaga, workerErrorNotifyThunk } from 'utils/sagas';
import {
  actionAccountGenerateCode,
  actionAccountLogout,
  actionAuthChangeExpertType,
  actionAuthGetCurrentUser,
  actionAuthLogin,
} from './actions';
import { selectAccountsUser } from './selectors';
import { activateUserActions, patchUserActions, resetUserAction } from './slice';

function* patchUser(p: ReturnType<typeof patchUserActions.request>) {
  const { payload } = p;
  const user = yield* select(selectAccountsUser);
  try {
    if (!user) {
      throw new Error('user-not-exist');
    }
    yield* call(ServiceCurrentUser.updateCurrentUser, user, payload);
    let { data } = yield* call(ServiceCurrentUser.getUser);
    yield* put(patchUserActions.success(setToModel(payload, data)));
  } catch (e: any) {
    let { data } = yield* call(ServiceCurrentUser.getUser);
    yield* put(patchUserActions.fail(setToModel(payload, data)));
    yield* notifyErrorSaga(e);
  }
}

function* activateUser() {
  const prevUser = yield* select(selectAccountsUser);

  try {
    yield* call(ServiceCurrentUser.setAsActive);
    let { data } = yield* call(ServiceCurrentUser.getUser);

    if (data.userStatusKey !== USER_STATUS_KEY.ACTIVE) {
      throw new Error('ERROR_UPDATE_USER_STATUS_KEY');
    }
    yield* put(activateUserActions.success({ ...data }));

    if (prevUser && prevUser.userStatusKey !== USER_STATUS_KEY.ACTIVE) {
      ServiceAuth.welcomeUser();
    }
  } catch (e: any) {
    yield* put(activateUserActions.fail(parseErrorData(e)));
    yield* notifyErrorSaga(e);
  }
}

function* workerGetUser() {
  yield* put(actionAuthGetCurrentUser());
}

function* watchReset() {
  yield updateAuthTokens();
}

function* watchPatch() {
  const requestChan = yield* actionChannel(patchUserActions.request);
  while (true) {
    const action = yield* take(requestChan);
    yield* call(patchUser, action);
  }
}

export const sagasAccounts = [
  watchPatch(),
  takeLatest(activateUserActions.request, activateUser),
  takeLatest(
    [actionAccountLogout.fulfilled, activateUserActions.success, activateUserActions.fail],
    workerGetUser,
  ),
  takeLatest(resetUserAction, watchReset),
  takeLatest([activateUserActions.success, activateUserActions.fail], workerGetUser),
  takeEvery(
    [
      actionAuthChangeExpertType.rejected,
      actionAccountLogout.rejected,
      actionAccountGenerateCode.rejected,
      actionAuthLogin.rejected,
    ],
    workerErrorNotifyThunk,
  ),
  throttle(3000, patchUserActions.request, workerGetUser),
  takeLatest([activateUserActions.fail.type], workerErrorNotifySaga),
];
