import {
  actionChatUserConnected,
  actionCustomChatUsersListSet,
  selectChatOnlineUserIDs,
  selectChatOnlineUsers,
} from 'components/cutom-chat';
import { USER_STATUS_KEY } from 'services/auth';
import { ServiceExpertProfiles } from 'services/expert-profiles';
import { call, put, select, takeLatest, throttle } from 'typed-redux-saga';
import { selectAccountsPermissions, selectAccountsUser } from '../accounts';
import { actionOnlineUsersGetUser } from './actions';
import { actionGetOnlineUsers } from './slice';

function* getOnlineUsers() {
  const listUserIDs = yield* select(selectChatOnlineUserIDs);

  try {
    const {
      data: { value },
    } = yield* call(ServiceExpertProfiles.getOnlineUsers, listUserIDs);

    const mapUserIDs = yield* select(selectChatOnlineUsers);

    const users = value.filter((item) => mapUserIDs[item.appIdentityUserID]);
    yield put(actionGetOnlineUsers.success(users));
  } catch (e: any) {
    yield put(actionGetOnlineUsers.fail(e));
  }
}

function* workerFetch() {
  const user = yield* select(selectAccountsUser);
  const { IS_ALLOW_VIEW_ONLINE_USERS } = yield* select(selectAccountsPermissions);

  if (user && user.userStatusKey === USER_STATUS_KEY.ACTIVE && IS_ALLOW_VIEW_ONLINE_USERS) {
    yield put(actionGetOnlineUsers.request());
  }
}

function* watchUserConnected(action: ReturnType<typeof actionChatUserConnected>) {
  const { IS_ALLOW_VIEW_ONLINE_USERS } = yield* select(selectAccountsPermissions);

  if (IS_ALLOW_VIEW_ONLINE_USERS) {
    yield* put(actionOnlineUsersGetUser({ id: action.payload }));
  }
}

export const sagasOnlineUser = [
  takeLatest(actionChatUserConnected, watchUserConnected),
  throttle(5000, [actionCustomChatUsersListSet], workerFetch),
  takeLatest(actionGetOnlineUsers.request, getOnlineUsers),
];
