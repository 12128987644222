import CheckIcon from '@mui/icons-material/Check';
import { Button, Dialog, DialogContent, Theme, useMediaQuery } from '@mui/material';
import { AppDialogFooter, AppDialogTitle } from 'components/dialogs';
import { NativeScroll } from 'components/native-scroll';
import { useTranslate } from 'hooks';
import React from 'react';
import { Page } from 'services/pages';
import { AppLoading } from '../../loading';
import style from './index.module.scss';

interface Props {
  onClose: () => void;
  data: Page | undefined;
  isLoading: boolean;
}

export const DialogInfo: React.FC<Props> = ({ onClose, data, isLoading }) => {
  const { t } = useTranslate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Dialog
      open={true}
      maxWidth={'lg'}
      fullWidth={!isMobile}
      fullScreen={isMobile}
      scroll={'paper'}
      onClose={onClose}
    >
      <AppDialogTitle onClose={onClose}>{data?.title}</AppDialogTitle>

      <NativeScroll>
        <DialogContent>
          <div
            dangerouslySetInnerHTML={{ __html: data?.html || '' }}
            className={style.dialogText}
          />
        </DialogContent>
      </NativeScroll>
      <AppDialogFooter>
        <div style={{ textAlign: 'center' }}>
          <Button
            color={'primary'}
            variant={'contained'}
            onClick={onClose}
            startIcon={<CheckIcon />}
          >
            {t('agree')}
          </Button>
        </div>
      </AppDialogFooter>
      <AppLoading active={isLoading} />
    </Dialog>
  );
};
