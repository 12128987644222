import { Fab, Tooltip } from '@mui/material';
import { useAppSelector, useTranslate } from 'hooks';
import { ReactComponent as SmsIcon } from 'images/messages-icon.svg';
import React from 'react';
import { useChatOpen } from '../../hooks';
import { selectChatConversationsUnRead, selectChatHasNewMessages } from '../../store';
import style from './index.module.scss';

const ChatTooltipTitle: React.FC = () => {
  const count = useAppSelector(selectChatConversationsUnRead);
  const { t } = useTranslate();
  return (
    <div className={style.tooltip}>
      {t('you-have')} <span className={style.badge}>{count}</span>{' '}
      {count > 1 ? t('unread-messages') : t('unread-message')}
    </div>
  );
};
export const CustomChantButton: React.FC = () => {
  const { onOpen } = useChatOpen();

  const hasNewMessages = useAppSelector(selectChatHasNewMessages);
  return (
    <div className={style.wrap} onClick={onOpen}>
      <Tooltip
        PopperProps={{ disablePortal: true, placement: 'bottom' }}
        open={hasNewMessages}
        title={<ChatTooltipTitle />}
        arrow
        classes={{
          tooltip: style.mTooltip,
          arrow: style.mArrow,
        }}
      >
        <Fab color={'primary'}>
          <SmsIcon />
        </Fab>
      </Tooltip>
    </div>
  );
};
