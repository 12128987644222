import { setToModel } from 'utils';
import { isFileLike, ValueFileUploader } from 'utils/file-uploader';
import { base64ToFileStream, getRandomString } from 'utils/other';
import { API } from 'utils/services';
import { PatchPartial } from 'utils/types';
import { API_MEDIA_UPLOADS, MediaUploadFile, MediaUploadHtml } from './models';

export const MEDIA_PREFIX = '@@CLOUD@@';

export * from './models';

class Service {
  async remove(filePath: string) {
    return API.delete(API_MEDIA_UPLOADS.REMOVE_FILE({ filePath: filePath }));
  }

  async uploadFile(data: PatchPartial<MediaUploadFile, 'fileStreamString'>) {
    return ServiceMediaUploads.upload(API_MEDIA_UPLOADS.UPLOAD_FILE, data);
  }

  async uploadHtml(data: PatchPartial<MediaUploadHtml, 'htmlContent'>) {
    return API.post<MediaUploadHtml>(API_MEDIA_UPLOADS.UPLOAD_HTML, { ...data });
  }

  async uploadImage(data: PatchPartial<MediaUploadFile, 'fileStreamString'>) {
    return ServiceMediaUploads.upload(API_MEDIA_UPLOADS.UPLOAD_IMAGE, data);
  }

  async getHtml(url: string) {
    const response = await fetch(url);
    const text = await response.text();
    return { data: text };
  }

  private async upload(url: string, data: PatchPartial<MediaUploadFile, 'fileStreamString'>) {
    const fileName = `${MEDIA_PREFIX}__${data.fileName ? data.fileName : getRandomString(20)}`;
    const fileStreamString = base64ToFileStream(data.fileStreamString);
    return API.post<MediaUploadFile>(
      url,
      setToModel(new MediaUploadFile(), { ...data, fileName, fileStreamString }),
    );
  }
}

export const ServiceMediaUploads = new Service();

const uploadFlowActions = async <
  N extends ValueFileUploader | undefined | null = undefined,
  O extends string | undefined | null = undefined,
>(
  newValue: N,
  oldValue: O,
  uploader: (v: Exclude<N, undefined>) => Promise<{ data: { filePath: string | null } }>,
) => {
  let result = undefined;

  // @ts-ignore
  if (oldValue === newValue) {
    return Promise.resolve(result);
  }

  if (oldValue && (newValue || newValue === '')) {
    // @ts-ignore
    await ServiceMediaUploads.remove(oldValue);
    result = '';
  }

  if (newValue) {
    const {
      data: { filePath },
    } = await uploader(newValue as Exclude<N, undefined>);
    result = filePath;
  }

  return Promise.resolve(result);
};

const uploadFileLike = (
  value: ValueFileUploader | null,
  uploader: (
    v: Exclude<ValueFileUploader, string | undefined | null>,
  ) => Promise<{ data: { filePath: string } }>,
) => {
  if (isFileLike(value)) {
    return uploader(value);
  }
  return Promise.resolve({ data: { filePath: value } });
};

export const uploadHtml = async (
  newValue: string | undefined,
  oldValue: string | undefined | null,
  fileName?: string,
) => {
  return uploadFlowActions(newValue, oldValue, async (htmlContent) => {
    return ServiceMediaUploads.uploadHtml({ htmlContent, fileName });
  });
};
export const uploadImage = async (
  newValue: ValueFileUploader | undefined | null,
  oldValue: string | undefined | null,
) => {
  return uploadFlowActions(newValue, oldValue, async (value) => {
    return uploadFileLike(value, ({ value: fileStreamString, name }) =>
      ServiceMediaUploads.uploadImage({ fileStreamString, fileName: name }),
    );
  });
};
export const uploadFile = async (
  newValue: ValueFileUploader | undefined | null,
  oldValue: string | undefined | null,
) => {
  return uploadFlowActions(newValue, oldValue, async (value) => {
    return uploadFileLike(value, ({ value: fileStreamString, name }) =>
      ServiceMediaUploads.uploadFile({ fileStreamString, fileName: name }),
    );
  });
};
