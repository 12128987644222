import { AxiosResponse } from 'axios';
import {
  API,
  BaseParams,
  createFilterEquals,
  createSelectParam,
  getPostAndDeleteRecords,
  prepareRecords,
  setToModel,
} from 'utils';
import { PostPicture, ServicePostPictures } from '../post-pictures';
import { API_POSTS, PatchPostModel, PostInfo, PostModel } from './models';

export * from './models';

class Service {
  async getAll(params?: BaseParams) {
    const select = createSelectParam<PostInfo>(
      'id',
      'expertArticle',
      'pharmaCompanyUserID',
      'userExpertProfileID',
      'companyName',
      'logo',
      'publishDate',
      'userPhoto',
      'firstName',
      'lastName',
      'fullName',
      'video',
      'html',
      'picture',
      'isOwner',
      'isUserReact',
      'following',
      'totalInteractives',
      'totalComments',
      'reactions',
      'color',
      'pictures',
    );
    const postFields = [select, 'commnents as comments', 'reactions.Count() as totalReactions'];
    return API.get<{ value: PostInfo[]; count: number }>(API_POSTS.GET_DYNAMIC, {
      params: {
        ...params,
        select: postFields.join(','),
        count: true,
      },
    });
  }

  async getByID(id: string) {
    const filter = `id == "${id}"`;
    const params = {
      filter,
      take: 1,
    };
    const {
      data: { value },
    } = await ServicePosts.getAll(params);

    if (value.length === 0) {
      throw new Error('record-not-found');
    }

    return { data: value[0] };
  }

  async getByUserID(userExpertProfileID: string, params?: BaseParams) {
    const _params = {
      ...params,
      filter: [
        createFilterEquals<PostInfo>({ name: 'userExpertProfileID', value: userExpertProfileID }),
        params?.filter,
      ]
        .filter((v) => !!v)
        .join('&&'),
    };
    return ServicePosts.getAll(_params);
  }

  async getArticle(id: string): Promise<AxiosResponse<PostInfo>> {
    return await API.get<PostInfo>(API_POSTS.GET(id));
  }

  async deletePost(id: string) {
    return API.delete(API_POSTS.DELETE(id));
  }

  async updatePictures(oldPictures: PostPicture[], newPictures: PostPicture[]) {
    const { postItems, deleteItems } = prepareRecords(oldPictures, newPictures, 'articlePictureID');

    return Promise.all([
      ...deleteItems.map(({ articlePictureID }) => ServicePostPictures.delete(articlePictureID)),
      ...postItems.map((item) => ServicePostPictures.create(item)),
    ]);
  }

  async createPost(data: PostModel) {
    let { pictures, ...rest } = { ...data };
    const {
      data: { id: articleID },
    } = await API.post<PostModel & { id: string }>(API_POSTS.POST, rest);
    pictures = pictures.map((item) => ({ ...item, articleID }));
    await ServicePosts.updatePictures([], pictures);
  }

  async createPostUserExpertProfile(articleID: string, appIdentityUserID: string) {
    return API.post(API_POSTS.POST_USER, { articleID, appIdentityUserID });
  }

  async deletePostUserExpertProfile(articleID: string, userExpertID: string) {
    return API.delete(API_POSTS.DELETE_USER(articleID, userExpertID));
  }

  async createPostTag(articleID: string, tagID: string) {
    return API.post(API_POSTS.POST_TAG, { articleID, tagID });
  }

  async deletePostTag(articleID: string, tagID: string) {
    return API.delete(API_POSTS.DELETE_TAG(articleID, tagID));
  }

  async updatePost(id: string, oldPost: PostModel, _newPost: PostModel) {
    let { pictures: newPictures, ...newPost } = { ..._newPost };
    await ServicePosts.updatePictures(oldPost.pictures, newPictures);

    await API.put(API_POSTS.PUT, { ...setToModel(new PatchPostModel(), newPost), articleID: id });

    const { postIDs: tagToCreateIDs, deleteIDs: tagToDeleteIDs } = getPostAndDeleteRecords(
      oldPost.tagIDs,
      newPost.tagIDs,
    );
    await Promise.all(tagToCreateIDs.map((tagID) => ServicePosts.createPostTag(id, tagID)));
    await Promise.all(tagToDeleteIDs.map((tagID) => ServicePosts.deletePostTag(id, tagID)));

    const { postIDs: userToCreateIDs, deleteIDs: userToDeleteIDs } = getPostAndDeleteRecords(
      oldPost.userExpertProfileIDs,
      newPost.userExpertProfileIDs,
    );

    await Promise.all(
      userToCreateIDs.map((userID) => ServicePosts.createPostUserExpertProfile(id, userID)),
    );
    await Promise.all(
      userToDeleteIDs.map((userID) => ServicePosts.deletePostUserExpertProfile(id, userID)),
    );
  }
}

export const ServicePosts = new Service();
