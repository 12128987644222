import { APP_ROUTES } from 'configs/routes';
import { useAppSelector } from 'hooks';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { selectAccountsUser } from 'store/accounts';

interface Props {
  children: React.ReactNode;
}
export const RouteRequireAuth: React.FC<Props> = ({ children }) => {
  const user = useAppSelector(selectAccountsUser);

  if (!user) {
    return <Navigate to={APP_ROUTES.SIGN_IN({ ignoreType: false }).config} replace={true} />;
  }
  return <>{children}</>;
};
