import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import HomeIcon from '@mui/icons-material/Home';
import SmsRoundedIcon from '@mui/icons-material/SmsRounded';
import { Badge, Button } from '@mui/material';
import clsx from 'clsx';
import { selectChatConversationsUnRead } from 'components/cutom-chat';
import { APP_ROUTES } from 'configs/routes';
import { useAppSelector, useMatchPath, useTranslate } from 'hooks';
import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import style from './index.module.scss';

export const AppFooterMobile = memo(() => {
  const { t } = useTranslate();
  const countNewMassages = useAppSelector(selectChatConversationsUnRead);
  const push = useNavigate();
  const onDashboard = useCallback(() => {
    push(APP_ROUTES.DASHBOARD().config);
  }, [push]);
  const onMessage = useCallback(() => {
    push(APP_ROUTES.MESSAGES_HUB().config);
  }, [push]);
  const onNewPost = useCallback(() => {
    push(APP_ROUTES.DASHBOARD({ postDialog: true }).config);
  }, [push]);

  return (
    <footer className={style.wrap}>
      <div
        className={clsx(
          style.item,
          useMatchPath(APP_ROUTES.MESSAGES_HUB.path) && style.itemCurrent,
        )}
        onClick={onMessage}
      >
        <Button size={'small'} color={'inherit'} classes={{ root: style.button }}>
          <Badge
            badgeContent={countNewMassages}
            classes={{
              badge: style.badge,
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <SmsRoundedIcon classes={{ root: 'flip-horizontally' }} />
          </Badge>
        </Button>
        <div className={style.name}>{t('message')}</div>
      </div>

      <div className={style.item} onClick={onNewPost}>
        <Button size={'small'} color={'inherit'} classes={{ root: style.button }}>
          <AddPhotoAlternateIcon color={'inherit'} />
        </Button>
        <div className={style.name}>{t('new-post')}</div>
      </div>

      <div
        className={clsx(style.item, useMatchPath(APP_ROUTES.DASHBOARD.path) && style.itemCurrent)}
        onClick={onDashboard}
      >
        <Button size={'small'} color={'inherit'} classes={{ root: style.button }}>
          <HomeIcon color={'inherit'} />
        </Button>
        <div className={style.name}>{t('home')}</div>
      </div>
    </footer>
  );
});
