import { createSelector } from 'reselect';
import { calcPaginationState } from 'utils';
import { AppState } from '../index';

const selectState = (state: AppState) => state.specialistsLatest;

export const selectSpecialistsLatest = createSelector(selectState, (state) => {
  return state;
});
export const selectSpecialistsLatestStatuses = createSelector(
  selectState,
  ({ isLoading, isInit, error, applyTags }) => {
    return { isLoading, isInit, error, applyTags };
  },
);
export const selectSpecialistsLatestApplyTags = createSelector(
  selectSpecialistsLatestStatuses,
  ({ applyTags }) => {
    return applyTags;
  },
);

export const selectSpecialistsLatestFilters = createSelector(
  selectSpecialistsLatest,
  ({ filters }) => {
    return filters;
  },
);
export const selectSpecialistsLatestData = createSelector(selectSpecialistsLatest, ({ data }) => {
  return data;
});

export const selectSpecialistsLatestHasFilters = createSelector(
  selectSpecialistsLatestFilters,
  ({ search }) => {
    return Boolean(search);
  },
);
export const selectHasSpecialistsLatestHasData = createSelector(
  selectSpecialistsLatestHasFilters,
  selectSpecialistsLatestData,
  (hasFilters, data) => {
    return !Boolean(hasFilters) && data.length > 0;
  },
);
export const selectSpecialistsLatestPagination = createSelector(
  selectSpecialistsLatest,
  ({ take, page, count }) => {
    return calcPaginationState({ take, page, count });
  },
);
