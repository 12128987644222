import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserFollowing } from 'services/expert-profiles';
import { combineActions } from 'utils/sagas';
import { actionFollowingAdd, actionFollowingRemove } from '../following';

interface Filters {
  search: string;
  primaryMedicalFieldIDs: string[];
  secondaryMedicalFieldIDs: string[];
  hmoIDs: string[];
  languageIDs: string[];
}

interface State {
  loading: boolean;
  users: UserFollowing[];
  error: null | Error;
  filters: Filters;
  take: number;
  page: number;
  count: number;
}

const initState = (): State => {
  return {
    loading: false,
    users: [],
    error: null,
    filters: {
      search: '',
      primaryMedicalFieldIDs: [],
      secondaryMedicalFieldIDs: [],
      hmoIDs: [],
      languageIDs: [],
    },
    take: 20,
    page: 1,
    count: 0,
  };
};

const slice = createSlice({
  name: 'USERS',
  initialState: initState(),
  reducers: {
    getUsersRequest(state, action: PayloadAction<{ page: number | undefined }>) {
      state.loading = true;
      state.error = null;
      state.page = action.payload.page ?? state.page;
    },
    getUsersSuccess(state, action: PayloadAction<{ data: UserFollowing[]; count: number }>) {
      state.loading = false;
      state.users = action.payload.data;
      state.count = action.payload.count;
    },
    getUsersError(state, action: PayloadAction<{ error: Error }>) {
      state.loading = false;
      state.error = action.payload.error;
    },

    setUsersFiltersAction(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetUsersFiltersAction(state) {
      state.filters = initState().filters;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actionFollowingRemove.fulfilled, (state, action) => {
      const { id } = action.payload;
      state.users = state.users.map((item) =>
        item.appIdentityUserID === id ? { ...item, following: false } : item,
      );
    });
    builder.addCase(actionFollowingAdd.fulfilled, (state, action) => {
      const { id } = action.payload;
      state.users = state.users.map((item) =>
        item.appIdentityUserID === id ? { ...item, following: true } : item,
      );
    });
  },
});

const actions = slice.actions;

export const { setUsersFiltersAction, resetUsersFiltersAction } = actions;

export const getUsersActions = combineActions(
  actions.getUsersRequest,
  actions.getUsersSuccess,
  actions.getUsersError,
);

export const reducerUsers = slice.reducer;
