import { API, BaseParams } from 'utils';
import { API_SPECIALISTS, Specialist } from './models';

export * from './models';

class Service {
  async getAllDynamic(params: BaseParams) {
    return API.get<{ value: Specialist[]; count: number }>(API_SPECIALISTS.GET, {
      params: { ...params, count: true },
    });
  }
}

export const ServiceSpecialists = new Service();
