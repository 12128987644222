import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SmsRoundedIcon from '@mui/icons-material/SmsRounded';
import { Badge, IconButton } from '@mui/material';
import clsx from 'clsx';
import { ProfileLink } from 'components/app-header/components/profile-link';
import { AppLogo } from 'components/app-logo';
import { selectChatConversationsUnRead } from 'components/cutom-chat';
import { APP_ROUTES } from 'configs/routes';
import { useAppDispatch, useAppSelector, usePermissions } from 'hooks';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { actionAccountLogout } from 'store/accounts';
import style from './index.module.scss';

interface AppHeaderProps {
  className?: string;
}

export const HEADER_ID = 'app-header';

export const AppHeaderMain: React.FC<AppHeaderProps> = ({ className }) => {
  const push = useNavigate();
  const dispatch = useAppDispatch();
  const onLogout = useCallback(() => {
    dispatch(actionAccountLogout());
  }, [dispatch]);

  const countNewMassages = useAppSelector(selectChatConversationsUnRead);

  const { isDoctorUser, hideMessageHub } = usePermissions();

  return (
    <header className={clsx(style.header, className)} id={HEADER_ID}>
      <div className={clsx('container', style.content)}>
        <AppLogo showTitle={isDoctorUser} />
        <div className={style.data}>
          {!hideMessageHub && (
            <IconButton color={'secondary'} onClick={() => push(APP_ROUTES.MESSAGES_HUB().config)}>
              <Badge
                badgeContent={countNewMassages}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                classes={{
                  badge: style.messageBadge,
                }}
              >
                <SmsRoundedIcon />
              </Badge>
            </IconButton>
          )}
          <ProfileLink />
          <IconButton color={'secondary'} onClick={onLogout}>
            <ExitToAppIcon />
          </IconButton>
        </div>
      </div>
    </header>
  );
};
