export const DOMAIN = (() => {
  if (window.location.hostname === 'localhost') {
    return 'localhost';
  } else {
    // @ts-ignore
    return window.authCookieDomain_ || `.${window.location.hostname}`;
  }
})();

export const setCookies = (name: string, data = '', expires = '', domain = '') => {
  if (data) {
    document.cookie = `${name}=${encodeURIComponent(data)};${
      expires ? ` expires=${new Date(expires).toUTCString()};` : ''
    } secure; domain=${domain ? domain : DOMAIN}; samesite=lax; path=/;`;
  } else {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; secure; domain=${
      domain ? domain : DOMAIN
    }; samesite=lax; path=/;`;
  }
};

export const getCookies = (name: string) => {
  if (!name) return undefined;
  const matches = document.cookie.match(
    new RegExp(
      // eslint-disable-next-line
      '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)',
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const updateAuthTokens = ({
  token,
  expires,
  refreshToken,
}: { token?: string; refreshToken?: string; expires?: string } = {}) => {
  setCookies(window.authCookieName_, token, expires, window.location.hostname);
  setCookies(window.authRefreshCookieName_, refreshToken, expires, window.location.hostname);
  setCookies(window.authDateCookieName_, expires, expires, window.location.hostname);
};
