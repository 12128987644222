import axios from 'axios';
import React, { useEffect } from 'react';
import { API } from 'utils';
import { getCookies } from 'utils/cookies';

interface AxiosInterceptorsTokenProps {
  children: React.ReactNode
}

export const AxiosInterceptorsToken: React.FC<AxiosInterceptorsTokenProps> = ({ children }) => {
  useEffect(() => {
    const ids = [axios, API].map((instance) => {
      const id = instance.interceptors.request.use((config) => {
        const token = getCookies(window.authCookieName_ || '');
        if (token && config) {
          config = {
            ...config,
            headers: {
              ...config.headers,
              'Authorization': 'Bearer ' + token
            }
          }
        }
        return config;
      });
      return { id, instance };
    });

    return () => {
      ids.forEach(({ instance, id }) => {
        instance.interceptors.request.eject(id);
      });
    };
  }, []);

  return <>{children}</>;
};
