const API = 'PharmaCompanyExperts';

export const API_SPECIALISTS = {
  GET: `${API}/GetPharmaCompanyExpertsDynamic`,
};

export class SpecialistPreview {
  appIdentityUserID: string = '';
  firstName = '';
  lastName = '';
  fullName = '';
  titleOfNameTitle = '';
  userPhoto = '';
  position = '';
  subject = '';
  primaryMedicalFieldTitle = '';
  color = '';
}

export class Specialist extends SpecialistPreview {
  fullNameEnglish = '';
  pharmaCompanyName = '';
}

export interface SpecialistFull extends Specialist {
  titleOfNameID: string;
  titleOfNameTitle: string;
  secondaryMedicalFieldID: string;
  expertTypeID: string;
  primaryMedicalFieldID: string;
  fullNameEnglish: string;
  pharmaCompanyID: string;
  pharmaCompanyName: string;
  userExpertProfileTagTitles: string;
  userExpertProfileTagIDs: { id: string }[];
  following: true;
  follower: true;
}
