import SearchIcon from '@mui/icons-material/Search';
import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor';
import { Button, Dialog, Grid, Theme, useMediaQuery } from '@mui/material';
import { AppInput } from 'components/app-input';
import { AppSelect } from 'components/app-select';
import { AppDialogContent, AppDialogFooter } from 'components/dialogs';
import { AppDialogTitle } from 'components/dialogs/components/title';
import { APP_ROUTES } from 'configs/routes';
import {
  useAppDispatch,
  useAppSelector,
  useFilterForm,
  useFilterInputProps,
  useFilterSelectProps,
  useSourceHmos,
  useSourceMedicalFieldsDoctors,
  useSourceSpeakLanguages,
  useTranslate,
} from 'hooks';
import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  getUsersActions,
  resetUsersFiltersAction,
  selectHasUsersFilters,
  selectUsersFilters,
  setUsersFiltersAction,
} from 'store/users';
import { DrawerFilter } from '../../drawer-filter';

interface DialogSearchProps {
  onClose: () => void;
}

interface FiltersProps extends DialogSearchProps {
  onSearch: () => void;
}

interface DialogSearchMobileProps extends FiltersProps {
  onReset: () => void;
  disabledClear: boolean;
}

const SharedFilters: React.FC = () => {
  const { t } = useTranslate();
  const dispatch = useAppDispatch();

  const defaultValues = useAppSelector(selectUsersFilters);

  const onUpdate = useCallback(
    (data: typeof defaultValues) => {
      dispatch(setUsersFiltersAction(data));
    },
    [dispatch],
  );

  const { onChangeItem, ...rest } = useFilterForm({ defaultValues, onUpdate });

  const { control } = rest;

  const getInputProps = useFilterInputProps({ onChange: onChangeItem });
  const getSelectProps = useFilterSelectProps({ onChange: onChangeItem });

  const sourceMedicalFields = useSourceMedicalFieldsDoctors();
  const sourceHmo = useSourceHmos();
  const sourceSpeakLanguages = useSourceSpeakLanguages();

  return (
    <Grid container rowSpacing={1} columnSpacing={1.6}>
      <Grid item xs={12}>
        <Controller
          name={'search'}
          control={control}
          render={(renderProps) => (
            <AppInput
              {...getInputProps(renderProps)}
              label={t('search-doctor-expert')}
              InputProps={{
                endAdornment: <SearchIcon color={'disabled'} />,
              }}
              autoFocus
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Controller
          name={'primaryMedicalFieldIDs'}
          control={control}
          render={(renderProps) => (
            <AppSelect
              {...getSelectProps(renderProps)}
              label={t('primary-medical-field')}
              options={sourceMedicalFields.data}
              loading={sourceMedicalFields.isLoading}
              multiple
              limitTags={1}
              disableClearable
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Controller
          name={'secondaryMedicalFieldIDs'}
          control={control}
          render={(renderProps) => (
            <AppSelect
              {...getSelectProps(renderProps)}
              label={t('secondary-medical-field')}
              options={sourceMedicalFields.data}
              loading={sourceMedicalFields.isLoading}
              multiple
              limitTags={1}
              disableClearable
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Controller
          name={'hmoIDs'}
          control={control}
          render={(renderProps) => (
            <AppSelect
              {...getSelectProps(renderProps)}
              label={t('hmo-title')}
              options={sourceHmo.data}
              loading={sourceHmo.isLoading}
              multiple
              limitTags={1}
              disableClearable
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Controller
          name={'languageIDs'}
          control={control}
          render={(renderProps) => (
            <AppSelect
              {...getSelectProps(renderProps)}
              label={t('speak-languages')}
              options={sourceSpeakLanguages.data}
              loading={sourceSpeakLanguages.isLoading}
              multiple
              limitTags={1}
              disableClearable
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

const DialogSearchDesktop: React.FC<FiltersProps> = ({ onClose, onSearch }) => {
  const { t } = useTranslate();

  return (
    <Dialog open={true} onClose={onClose} maxWidth={'md'} scroll={'paper'} fullWidth>
      <AppDialogTitle onClose={onClose}>{t('search-dialog-title')}</AppDialogTitle>
      <AppDialogContent>
        <SharedFilters />
      </AppDialogContent>
      <AppDialogFooter>
        <Grid container rowSpacing={2.3} columnSpacing={3} justifyContent={'flex-end'}>
          <Grid item>
            <Button onClick={onClose} color={'secondary'}>
              {t('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button variant={'contained'} color={'primary'} onClick={onSearch}>
              {t('search')}
            </Button>
          </Grid>
        </Grid>
      </AppDialogFooter>
    </Dialog>
  );
};

export const DialogSearchMobile: React.FC<DialogSearchMobileProps> = ({
  onClose,
  onReset,
  onSearch,
  disabledClear,
}) => {
  const { t } = useTranslate();
  return (
    <DrawerFilter
      isOpen={true}
      onClose={onClose}
      Footer={
        <Grid container sx={{ justify: 'space-between' }}>
          <Grid item>
            <Button onClick={onSearch} variant={'contained'} color={'primary'}>
              {t('search')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={onReset}
              variant={'contained'}
              endIcon={<YoutubeSearchedForIcon />}
              disabled={disabledClear}
            >
              {t('clear')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <SharedFilters />
    </DrawerFilter>
  );
};

export const DialogSearch: React.FC<DialogSearchProps> = ({ onClose }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const dispatch = useAppDispatch();
  const onReset = () => dispatch(resetUsersFiltersAction());
  const push = useNavigate();
  const onSearch = () => {
    push(APP_ROUTES.SEARCH().config);
    onClose();
    dispatch(getUsersActions.request({ page: 1 }));
  };
  const hasFilterValue = useAppSelector(selectHasUsersFilters);

  return isMobile ? (
    <DialogSearchMobile
      onClose={onClose}
      onReset={onReset}
      onSearch={onSearch}
      disabledClear={!hasFilterValue}
    />
  ) : (
    <DialogSearchDesktop onClose={onClose} onSearch={onSearch} />
  );
};
