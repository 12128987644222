import { AppChatProvider } from 'components/chat';
import { ErrorBoundary } from 'components/error-boundary';
import {
  AppDictionaryProvider,
  AppLoadingProvider,
  AppMetaProvider,
  AppNotificationsProvider,
  AppThemeProvider,
  AuthProvider,
  AxiosInterceptors,
  FullStoryProvider,
  PageDefaultProvider,
} from 'contexts';
import { FC } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import 'react-image-lightbox/style.css';
// store
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { AppRoutes } from 'router';
import { appPersistor, appStore } from 'store';
// style
import 'styles/app.global.scss';
// import 'styles/index.scss';

declare global {
  interface Window {
    authCookieName_: string;
    authRefreshCookieName_: string;
    authDateCookieName_: string;
  }
}

const App: FC = () => {
  window.authCookieName_ = 'vimed_auth_token_network';
  window.authRefreshCookieName_ = 'vimed_auth_token_refresh_network';
  window.authDateCookieName_ = 'vimed_auth_token_data_network';

  return (
    <AppMetaProvider>
      <Provider store={appStore}>
        <PersistGate persistor={appPersistor} loading={null}>
          <ErrorBoundary>
            <DndProvider backend={HTML5Backend}>
              <BrowserRouter>
                <AxiosInterceptors>
                  <AuthProvider>
                    <AppThemeProvider>
                      <AppDictionaryProvider>
                        <FullStoryProvider>
                          <AppNotificationsProvider>
                            <PageDefaultProvider>
                              <AppChatProvider>
                                <AppLoadingProvider>
                                  <AppRoutes />
                                </AppLoadingProvider>
                              </AppChatProvider>
                            </PageDefaultProvider>
                          </AppNotificationsProvider>
                        </FullStoryProvider>
                      </AppDictionaryProvider>
                    </AppThemeProvider>
                  </AuthProvider>
                </AxiosInterceptors>
              </BrowserRouter>
            </DndProvider>
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </AppMetaProvider>
  );
};

export default App;
