import { API, BaseParams } from 'utils';
import { API_MED_EVENTS, MedicalEvent } from './models';

export * from './models';

class Service {
  async getLatest(params?: BaseParams) {
    return ServiceMedEvents.getAll({ take: 3, ...params });
  }

  async getAll(params?: BaseParams) {
    return API.get<{ value: MedicalEvent[]; count: number }>(API_MED_EVENTS.GET_DYNAMIC, {
      params: {
        ...params,
        count: true,
      },
    });
  }

  async getByID(id: string) {
    const filter = `id == "${id}"`;
    const params = {
      filter,
      take: 1,
    };
    const {
      data: { value },
    } = await ServiceMedEvents.getAll(params);

    if (value.length === 0) {
      throw new Error('record-not-found');
    }

    return { data: value[0] };
  }

  async register(id: string) {
    return API.post(API_MED_EVENTS.JOIN_TO_EVENT, { medicalEventID: id });
  }

  async unregister(id: string) {
    return API.delete(API_MED_EVENTS.UN_JOIN_TO_EVENT(id));
  }
}

export const ServiceMedEvents = new Service();
