import { createSelector } from 'reselect';
import { Label } from 'services/labels';
import { AppState } from '../index';

const selectLabelsState = (state: AppState) => state.labels;
export const selectLabelsStatus = createSelector(selectLabelsState, (state) => {
  return {
    isInit: state.isInit,
    isLoading: state.isLoading,
    data: state.data,
  };
});
export const selectMapLabels = createSelector(selectLabelsStatus, ({ data }) => {
  const map: Record<string, Label> = {};
  return data.reduce((acc, item) => {
    acc[item.labelKey] = item;
    return acc;
  }, map);
});
