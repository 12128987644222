import { API } from 'utils';
import { API_INVITATIONS } from './models';

export * from './models';

class Service {
  inviteBalkMembers(emailList: string[]) {
    if (emailList.length === 0) {
      throw new Error('email-list-is-empty');
    }
    return API.post(
      API_INVITATIONS.INVITE_BALK_MEMBERS,
      emailList.map((email) => ({ email })),
    );
  }
}

export const ServiceInvitations = new Service();
