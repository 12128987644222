import { createAsyncThunk } from '@reduxjs/toolkit';
import { ServiceCurrentUser } from 'services/current-user';
import { parseErrorData } from 'utils';
import { AppAsyncThunkConfig } from '../index';

export const actionFollowingAdd = createAsyncThunk<
  { id: string },
  { id: string },
  AppAsyncThunkConfig
>(`FOLLOWING/add`, async ({ id }, { dispatch }) => {
  try {
    await ServiceCurrentUser.addFollowing(id);
    return { id };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionFollowingRemove = createAsyncThunk<
  { id: string },
  { id: string },
  AppAsyncThunkConfig
>(`FOLLOWING/remove`, async ({ id }, { dispatch }) => {
  try {
    await ServiceCurrentUser.removeFollowing(id);
    return { id };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
