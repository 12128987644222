import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectChatOnlineUsers } from 'components/cutom-chat';
import { ServiceExpertProfiles, UserBaseInfo } from 'services/expert-profiles';
import { parseErrorData } from 'utils';
import { AppAsyncThunkConfig } from '../index';

export const actionOnlineUsersGetUser = createAsyncThunk<
  UserBaseInfo | null,
  { id: string },
  AppAsyncThunkConfig
>(`ONLINE_USERS/getUser`, async ({ id }, { getState }) => {
  try {
    const { data } = await ServiceExpertProfiles.getOnlineUser(id);
    const mapOnlineUsers = selectChatOnlineUsers(getState());
    return mapOnlineUsers[id] ? data : null;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
