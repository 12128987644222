import { AxiosResponse } from 'axios';
import { API } from 'utils';
import { apiRtk } from '../apiRtk';
import { API_PAGES, BasePage, Page, PageOnboardingEvent, PageOnboardingEvents } from './models';

export * from './models';

class Service {
  private static async _withHTML<T extends { htmlURL: string } = BasePage>(url: string) {
    const result = await API.get<T>(url);
    let html = null;
    if (result.data.htmlURL) {
      const { data: HTML } = await ServicePages.getHtml({ url: result.data.htmlURL });
      html = HTML;
    }
    return { ...result, data: { ...result.data, html } };
  }
  async getPrivacy() {
    return Service._withHTML(API_PAGES.GET_PRIVACY);
  }

  async getTerms() {
    return Service._withHTML(API_PAGES.GET_TERMS);
  }

  async getSignUp() {
    return Service._withHTML(API_PAGES.GET_SIGN_UP);
  }

  async getPageWithEvent(eventID: string): Promise<AxiosResponse<PageOnboardingEvents>> {
    const result = await Service._withHTML<PageOnboardingEvent>(
      API_PAGES.GET_ONBOARDING_WITH_EVENT(eventID),
    );
    const upcomingEvents = result.data.upcomingEvent ? [result.data.upcomingEvent] : [];
    return { ...result, data: { ...result.data, upcomingEvents } };
  }

  async getPageWithEvents() {
    return Service._withHTML<PageOnboardingEvents>(API_PAGES.GET_ONBOARDING_WITH_EVENTS);
  }

  async getHtml(p: { url: string }) {
    const { url } = p;
    const response = await fetch(url);
    const text = await response.text();
    return { data: text };
  }
}

export const ServicePages = new Service();
export const apiPages = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getTerms: builder.query<Page, void>({
      queryFn: ServicePages.getTerms,
    }),
    getPrivacy: builder.query<Page, void>({
      queryFn: ServicePages.getPrivacy,
    }),
    getSignUpDoctors: builder.query<Page, void>({
      queryFn: ServicePages.getSignUp,
    }),
    getSingUpEvents: builder.query<PageOnboardingEvents, string | undefined | void>({
      queryFn: (eventID) => {
        return eventID ? ServicePages.getPageWithEvent(eventID) : ServicePages.getPageWithEvents();
      },
    }),
  }),
});
