import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { isValid } from 'date-fns';
import { ArrayType } from './types';

export const BASE_URL = process.env.REACT_APP_API_SERVER as string;
export const API = axios.create({
  baseURL: `${BASE_URL}/api`,
});

export const axiosBaseGetQuery =
  (): BaseQueryFn<Pick<AxiosRequestConfig, 'params' | 'url'>, unknown, Error> =>
  async ({ url, params }) => {
    try {
      const result = await API.get(url || '', { params });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: parseErrorData(err),
      };
    }
  };

const prepareValue = (value?: string) => {
  return String(value).trim().replace(/"/gi, '\\"');
};
const createStart = (count: number, query: string) => {
  return count > 1 ? `(${query})` : query;
};

export interface BaseParams {
  select?: string;
  filter?: string;
  orderBy?: string;
  take?: number;
  skip?: number;
  count?: boolean;
}

type IsTrue<T extends any, S, E> = T extends true ? S : E;

export type DynamicResult<T extends any, P extends BaseParams | undefined = undefined> = P extends {
  count: infer U;
}
  ? IsTrue<U, { value: T[]; count: number }, { value: T[] }>
  : { value: T[] };

export const createFilterSmartSearch = <T>({
  name,
  value,
}: {
  name: keyof T | (keyof T)[];
  value: any;
}) => {
  const names = Array.isArray(name) ? name : [name];
  if (!value) return null;

  const field = names.map((_name) => `${String(_name)}.replace(" ",String.Empty)`).join('+');
  return createFilterContains({ name: `(${field})`, value: String(value).replace(/ /g, '') });
};

export const createFilterEquals = <T extends object = {}>({
  name,
  value,
}: {
  name: keyof T | (keyof T)[];
  value: any;
}) => {
  const names = Array.isArray(name) ? name : [name];

  return value
    ? createStart(
        names.length,
        names.map((_n) => `${String(_n)}=="${prepareValue(value)}"`).join('||'),
      )
    : undefined;
};
export const createFilterNotEquals = <T extends object = {}>({
  name,
  value,
}: {
  name: keyof T | (keyof T)[];
  value: any;
}) => {
  const names = Array.isArray(name) ? name : [name];

  return value
    ? createStart(
        names.length,
        names.map((_n) => `${String(_n)}!="${prepareValue(value)}"`).join('||'),
      )
    : undefined;
};
export const createFilterBoolean = <T extends object = {}>({
  name,
  value,
}: {
  name: keyof T | (keyof T)[];
  value: boolean;
}) => {
  const names = Array.isArray(name) ? name : [name];

  return createStart(names.length, names.map((_n) => `${String(_n)}==${value}`).join('||'));
};
export const createFilterContains = <T extends object = {}>({
  name,
  value,
}: {
  name: keyof T | (keyof T)[];
  value: any;
}) => {
  const names = Array.isArray(name) ? name : [name];

  return value
    ? `(${names.map((_n) => `${String(_n)}.contains("${prepareValue(value)}")`).join('||')})`
    : null;
};
export const createFilterDateRange = <T extends object = {}>({
  name,
  value,
}: {
  name: keyof T;
  value: [string, string];
}) => {
  const [start, end] = value;
  return `(${String(name)}>=DateTime(${start})&&${String(name)}<=DateTime(${end}))`;
};
export const createFilterArrays = <T, K extends keyof T>({
  name,
  key,
  value,
}: {
  name: K;
  key: keyof ArrayType<T[K]>;
  value: string[];
}) => {
  return value.length
    ? `(${String(name)}.any(${value.map((v) => `${String(key)}=="${v}"`).join('||')}))`
    : null;
};
export const createFilterDate = <T extends object = {}>({
  name,
  value,
}: {
  name: keyof T;
  value?: string | Date;
}) => {
  if (!value) {
    return null;
  }
  const date = (isValid(value) ? value : new Date(value)) as Date;
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDay();

  const dateTimeStart = `${year},${month},${day},00,00,00`;
  const dateTimeEnd = `${year},${month},${day},23,59,59`;

  return createFilterDateRange<T>({ name, value: [dateTimeStart, dateTimeEnd] });
};

export const createSelectParam = <T extends Record<string, any>>(...args: (keyof T)[]) => {
  return args.join(',');
};

export const getPostAndDeleteRecords = (oldRecords: string[], newRecords: string[]) => {
  const oldSet = new Set(oldRecords);
  const newSet = new Set(newRecords);

  const stayIDs: string[] = [];
  const postIDs: string[] = [];
  const deleteIDs: string[] = [];

  [...oldRecords, ...newRecords].forEach((id) => {
    if (newSet.has(id) && !oldSet.has(id)) {
      postIDs.push(id);
    } else if (!newSet.has(id) && oldSet.has(id)) {
      deleteIDs.push(id);
    } else {
      stayIDs.push(id);
    }
  });
  return {
    stayIDs,
    postIDs,
    deleteIDs,
  };
};

export const prepareRecords = <T extends Record<string, any>>(
  oldRecords: T[],
  newRecords: T[],
  mainField: keyof T,
) => {
  const postItems = newRecords.filter((item) => item[mainField] === null);
  const deleteItems = [] as T[];
  const patchItems = [] as T[];

  const mapNewRecordsIds = new Map(
    newRecords.filter((item) => item[mainField]).map((item) => [item[mainField], item]),
  );
  const mapOldRecordsIds = new Map(oldRecords.map((item) => [item[mainField], item]));

  Array.from(mapNewRecordsIds.entries()).forEach(([id, item]) => {
    const isInOld = mapOldRecordsIds.get(id);
    if (!isInOld) {
      postItems.push(item);
    }
  });

  Array.from(mapOldRecordsIds.entries()).forEach(([id, item]) => {
    const isInNewItem = mapNewRecordsIds.get(id);
    if (!isInNewItem) {
      deleteItems.push(item);
    }
  });

  Array.from(mapNewRecordsIds.entries()).forEach(([id, item]) => {
    const oldItem = mapOldRecordsIds.get(id);
    if (oldItem) {
      if (JSON.stringify(oldItem) !== JSON.stringify(item)) {
        patchItems.push(item);
      }
    }
  });

  return {
    postItems,
    deleteItems,
    patchItems,
  };
};

export const parseErrorData = <T = string>(error: AxiosError<T> | Partial<Error>): Error => {
  if (!error) {
    return new Error('error');
  }
  if ('isAxiosError' in error) {
    const errorData = error.response?.data;

    if (!errorData) {
      return new Error('error');
    }

    if (typeof errorData === 'string') {
      return new Error(errorData);
    }
    return { ...new Error('error'), ...errorData } as Error;
  }
  return new Error(error.message);
};

export const isRtkMutationRejected = <T = Error>(
  mutationResult: any,
): mutationResult is { error: T } => {
  return Boolean(mutationResult && mutationResult.error);
};
export const isRtkMutationFulfilled = <T>(mutationResult: any): mutationResult is { data: T } => {
  return Boolean(
    mutationResult && mutationResult.hasOwnProperty && mutationResult.hasOwnProperty('data'),
  );
};
