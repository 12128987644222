import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, ListItemText, Menu, MenuItem, SvgIconProps } from '@mui/material';
import clsx from 'clsx';
import React, { CSSProperties, useRef } from 'react';
import style from './index.module.scss';

interface Item {
  title: string | React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
}

export interface ButtonMenuDotsProps {
  size?: 'small' | 'medium';
  items: Item[];
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  className?: string;
  sizeIcon?: SvgIconProps['fontSize'];
}

export const ButtonMenuDots: React.FC<ButtonMenuDotsProps> = ({
  items,
  isOpen,
  onClose,
  onOpen,
  size,
  className,
  sizeIcon,
}) => {
  const ref = useRef<any>();
  return items.length ? (
    <>
      <IconButton className={clsx(className, style.dots)} size={size} ref={ref} onClick={onOpen}>
        <MoreVertIcon fontSize={sizeIcon} />
      </IconButton>
      <Menu
        anchorEl={ref.current}
        open={isOpen}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {items.map(({ title, onClick, disabled, className, style }, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              onClick && onClick();
              onClose();
            }}
            disabled={disabled}
            className={className}
            style={style}
          >
            <ListItemText>{title}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  ) : null;
};
