import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Specialist } from 'services/specialists';
import { combineActions } from 'utils/sagas';

interface Filters {
  search: string;
  primaryMedicalFieldID: string;
  tagIDs: string[];
  online: boolean;
}

interface State {
  isInit: boolean;
  isLoading: boolean;
  error: Error | null;
  data: Specialist[];
  filters: Filters;
  take: number;
  page: number;
  count: number;
}

export const initState = (): State => {
  return {
    isInit: false,
    isLoading: false,
    error: null,
    data: [],
    filters: {
      search: '',
      primaryMedicalFieldID: '',
      tagIDs: [],
      online: false,
    },
    take: 20,
    page: 1,
    count: 0,
  };
};
const slice = createSlice({
  name: 'SPECIALISTS',
  initialState: initState(),
  reducers: {
    requestGet(state, action: PayloadAction<{ page: number | undefined }>) {
      state.isLoading = true;
      state.error = null;
      state.page = action.payload.page ?? state.page;
    },
    successGet(state, action: PayloadAction<{ data: Specialist[]; count: number }>) {
      state.isLoading = false;
      state.isInit = true;
      state.error = null;
      state.count = action.payload.count;
      state.data = action.payload.data;
    },
    failGet(state, action: PayloadAction<{ error: Error }>) {
      state.isLoading = false;
      state.isInit = true;
      state.error = action.payload.error;
    },

    actionSpecialistsSetFilters(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    actionSpecialistsResetFilters(state) {
      state.filters = initState().filters;
    },
  },
});

const actions = slice.actions;
export const { actionSpecialistsSetFilters, actionSpecialistsResetFilters } = actions;

export const actionSpecialistsGet = combineActions(
  actions.requestGet,
  actions.successGet,
  actions.failGet,
);

export const reducerSpecialists = slice.reducer;
