import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { LabelTranslateProps } from '../dictionary';
import { selectLabelsStatus, selectMapLabels } from '../store/labels';
import { calcTranslate } from '../utils';

export const useTranslate = () => {
  const mapLabels = useSelector(selectMapLabels);
  const { isLoading } = useSelector(selectLabelsStatus);

  const t = useCallback(
    (key: string) => {
      return mapLabels[key]?.title || key;
    },
    [mapLabels],
  );

  const tp = useCallback(
    (...props: LabelTranslateProps) => {
      const [key, payload] = props;
      return calcTranslate(t(key), payload);
    },
    [t],
  );

  return { t, tp, isLoading };
};

export const useTypedTranslate = () => {
  const { t: trans } = useTranslate();
  const t = useCallback(
    (...props: LabelTranslateProps) => {
      const [key, payload] = props;
      return calcTranslate(trans(key), payload);
    },
    [trans],
  );
  return { t };
};
