import { useEffect, useState } from 'react';

export const useTimer = () => {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const timerID =
      timer > 0
        ? setTimeout(() => {
            setTimer((v) => v - 1);
          }, 1000)
        : undefined;
    return () => {
      timerID && clearTimeout(timerID);
    };
  }, [timer]);

  return { timer, setTimer };
};
