import { useMemo } from 'react';
import { useOpen } from './use-open';
import { useTranslate } from './use-translate';

interface Options {
  hasTags: boolean;
  applyTags: boolean;
  onViewMore?: () => void;
  onApplyTags: (v: boolean) => void;
}

export const useCardDotsMenu = (p: Options) => {
  const { hasTags, applyTags, onViewMore, onApplyTags } = p;
  const { isOpen, onOpen, onClose } = useOpen();
  const { t } = useTranslate();

  const menuItems = useMemo(() => {
    return [
      ...(onViewMore
        ? [
            {
              title: t('view-more'),
              onClick: onViewMore,
            },
          ]
        : []),
      ...(hasTags
        ? [
            {
              title: t(applyTags ? 'un-select-tags' : 'apply-tags'),
              onClick: () => {
                onApplyTags(!applyTags);
              },
            },
          ]
        : []),
    ];
  }, [t, onViewMore, onApplyTags, hasTags, applyTags]);

  return useMemo(() => {
    return {
      isOpen,
      onOpen,
      onClose,
      menuItems,
    };
  }, [isOpen, onOpen, onClose, menuItems]);
};
