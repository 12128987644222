import { createSelector } from 'reselect';
import { calcPaginationState } from 'utils';
import { AppState } from '../index';

export const selectUsersState = (state: AppState) => state.users;

export const selectUsers = createSelector(selectUsersState, ({ users }) => {
  return users;
});
export const selectUsersFilters = createSelector(selectUsersState, ({ filters }) => {
  return filters;
});
export const selectUsersLoading = createSelector(selectUsersState, ({ loading }) => {
  return loading;
});
export const selectHasUsersFilters = createSelector(selectUsersState, ({ filters }) => {
  return Object.values(filters).some((item: string | string[]) => {
    return Array.isArray(item) ? Boolean(item.length) : Boolean(item);
  });
});
export const selectUsersPagination = createSelector(selectUsersState, ({ take, page, count }) => {
  return calcPaginationState({ take, page, count });
});
