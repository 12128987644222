import { call, put, takeEvery, takeLatest } from 'modules/typed-saga';
import { ServiceSurveys } from 'services/surveys';
import { parseErrorData } from 'utils';
import { workerErrorNotifySaga, workerErrorNotifyThunk } from 'utils/sagas';
import { actionSurveyLatestGetCount, actionSurveyLatestVote } from './actions';
import { actionSurveyLatestGet } from './slice';

function* getSurvey() {
  try {
    let { data } = yield* call(ServiceSurveys.get);
    yield* put(actionSurveyLatestGet.success(data));
  } catch (e: any) {
    yield* put(actionSurveyLatestGet.fail({ error: parseErrorData(e) }));
  }
}

function* watchGetSurvey() {
  yield* put(actionSurveyLatestGetCount());
}

export const sagasSurveyLatest = [
  takeLatest(actionSurveyLatestGet.request.type, watchGetSurvey),
  takeLatest(actionSurveyLatestGet.request.type, getSurvey),
  takeEvery([actionSurveyLatestGet.fail], workerErrorNotifySaga),
  takeEvery([actionSurveyLatestVote.rejected], workerErrorNotifyThunk),
];
