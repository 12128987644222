import { PostInfo, ServicePosts } from 'services/posts';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { parseErrorData } from 'utils';
import { createFilter, createFilterDeep, createOrderBy, mergeFilters } from 'utils/service-filters';
import { selectAccountsPermissions } from '../accounts';
import { ARTICLE_CATEGORY_POSTS_AND_DISCUSSIONS_ID } from '../posts';
import { selectCheckedTagIDs } from '../tags';
import { selectPostsLatestApplyTags } from './selectors';
import { actionPostsLatestGet } from './slice';

function* fetchPosts() {
  const tagIDs = yield* select(selectCheckedTagIDs);
  const applyTags = yield* select(selectPostsLatestApplyTags);
  const { isAllowViewWebinars } = yield* select(selectAccountsPermissions);

  const filters = mergeFilters(
    [
      isAllowViewWebinars &&
        createFilter<PostInfo>(
          'articleCategoryID',
          '!=',
          ARTICLE_CATEGORY_POSTS_AND_DISCUSSIONS_ID,
        ),
      applyTags && createFilterDeep<PostInfo, 'tags'>('tags', 'tagID', tagIDs),
    ],
    '&&',
  );

  try {
    const {
      data: { value },
    } = yield* call(ServicePosts.getAll, {
      filter: filters,
      orderBy: createOrderBy<PostInfo>('totalComments', 'desc'),
      take: 3,
    });
    yield put(actionPostsLatestGet.success({ data: value }));
  } catch (e: any) {
    yield put(actionPostsLatestGet.fail({ error: parseErrorData(e) }));
  }
}

export const sagasPostsLatest = [takeLatest(actionPostsLatestGet.request, fetchPosts)];
