import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserFollowingSmall } from 'services/expert-profiles';
import { combineActions } from 'utils/sagas';
import { actionFollowingAdd, actionFollowingRemove } from '../following';
import { addTag, removeTag, uncheckAllTags, verifyTags } from '../tags';

interface State {
  applyTags: boolean;
  isInit: boolean;
  isLoading: boolean;
  error: null | Error;
  data: UserFollowingSmall[];
}

const initState = (): State => {
  return {
    applyTags: false,
    isInit: false,
    isLoading: false,
    error: null,
    data: [],
  };
};
const slice = createSlice({
  name: 'FOLLOWING_LATEST',
  initialState: initState(),
  reducers: {
    getRequest(state) {
      state.isLoading = true;
      state.error = null;
    },
    getSuccess(state, action: PayloadAction<{ data: UserFollowingSmall[] }>) {
      state.isLoading = false;
      state.isInit = true;
      state.data = action.payload.data;
    },
    getError(state, action: PayloadAction<{ error: Error }>) {
      state.isLoading = false;
      state.isInit = true;
      state.error = action.payload.error;
    },

    actionLatestFollowingApplyTags(state, action: PayloadAction<boolean>) {
      state.applyTags = action.payload;
      state.isInit = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => {
        return [addTag.type, removeTag.type, verifyTags.type, uncheckAllTags.type].some(
          (itemAction) => itemAction === action.type,
        );
      },
      (state) => {
        state.isInit = state.applyTags ? false : state.isInit;
      },
    );
    builder.addMatcher(
      (action) => {
        return [actionFollowingRemove.fulfilled.type, actionFollowingAdd.fulfilled.type].some(
          (itemAction) => itemAction === action.type,
        );
      },
      (state) => {
        state.isInit = false;
      },
    );
  },
});

const actions = slice.actions;

export const { actionLatestFollowingApplyTags } = actions;

export const actionsLatestFollowingGet = combineActions(
  actions.getRequest,
  actions.getSuccess,
  actions.getError,
);
export const reducerLatestFollowing = slice.reducer;
