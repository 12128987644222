import { ServiceLabels } from 'services/labels';
import { call, put, takeLatest } from 'typed-redux-saga';
import { parseErrorData } from 'utils';
import { actionLabelsGet } from './slice';

function* labelsFlow() {
  try {
    const { data: languageID } = yield call(ServiceLabels.getDefaultLanguageID);
    const {
      data: { value: data },
    } = yield* call(ServiceLabels.getLabels, languageID);
    yield* put(actionLabelsGet.success({ data }));
  } catch (e: any) {
    yield* put(actionLabelsGet.fail({ error: parseErrorData(e) }));
  }
}

export const sagasLabels = [takeLatest(actionLabelsGet.request, labelsFlow)];
