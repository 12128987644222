import { KeyboardEvent, useCallback } from 'react';

export const useOnEnter = <T extends Function = () => void>(handle: T) => {
  return useCallback(
    (e: KeyboardEvent<any>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        handle(e);
      }
    },
    [handle],
  );
};
