import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserFollowing } from 'services/expert-profiles';
import { combineActions } from 'utils/sagas';
import { actionFollowingAdd, actionFollowingRemove } from './actions';

interface State {
  isInit: boolean;
  isLoading: boolean;
  error: null | Error;
  data: UserFollowing[];
  take: number;
  page: number;
  count: number;
}

const initState = (): State => {
  return {
    isInit: false,
    isLoading: false,
    error: null,
    data: [],
    take: 20,
    page: 1,
    count: 0,
  };
};
const slice = createSlice({
  name: 'FOLLOWING',
  initialState: initState(),
  reducers: {
    getRequest(state, action: PayloadAction<{ page: number | undefined }>) {
      state.isLoading = true;

      state.error = null;
      state.page = action.payload.page ?? state.page;
    },
    getSuccess(state, action: PayloadAction<{ data: UserFollowing[]; count: number }>) {
      state.isLoading = false;
      state.isInit = true;
      state.data = action.payload.data;
      state.count = action.payload.count;
    },
    getError(state, action: PayloadAction<{ error: Error }>) {
      state.isLoading = false;
      state.isInit = true;
      state.error = action.payload.error;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => {
        return [actionFollowingRemove.fulfilled.type, actionFollowingAdd.fulfilled.type].some(
          (itemAction) => {
            return itemAction === action.type;
          },
        );
      },
      (state, action) => {
        state.isInit = false;
      },
    );
  },
});

const actions = slice.actions;

export const actionFollowingGet = combineActions(
  actions.getRequest,
  actions.getSuccess,
  actions.getError,
);
export const reducerFollowing = slice.reducer;
