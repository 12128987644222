import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actionChatUserDisconnected } from 'components/cutom-chat';
import { UserBaseInfo } from 'services/expert-profiles';
import { combineActions } from 'utils/sagas';
import { actionOnlineUsersGetUser } from './actions';

export interface StoreOnlineUsers {
  loading: boolean;
  error: null | Error;
  listUsers: UserBaseInfo[];
}

const initState = (): StoreOnlineUsers => {
  return {
    loading: false,
    error: null,
    listUsers: [],
  };
};
const slice = createSlice({
  name: 'ONLINE_USERS',
  initialState: initState(),
  reducers: {
    requestGetLatestUsersList(state) {
      state.loading = true;
    },
    successGetLatestUsersList(state, action: PayloadAction<UserBaseInfo[]>) {
      state.loading = false;
      state.listUsers = action.payload;
    },
    failGetLatestUsersList(state, action: PayloadAction<Error>) {
      state.loading = false;
    },
  },
  extraReducers: (build) => {
    build.addCase(actionChatUserDisconnected, (state, action) => {
      state.listUsers = state.listUsers.filter(
        ({ appIdentityUserID }) => appIdentityUserID !== action.payload,
      );
    });
    build.addCase(actionOnlineUsersGetUser.fulfilled, (state, action) => {
      const list = action.payload ? [...state.listUsers, action.payload] : state.listUsers;
      const listMap = new Map(list.map((item) => [item.appIdentityUserID, item]));
      state.listUsers = Array.from(listMap.values()).map((value) => value);
    });
  },
});
const actions = slice.actions;

export const actionGetOnlineUsers = combineActions(
  actions.requestGetLatestUsersList,
  actions.successGetLatestUsersList,
  actions.failGetLatestUsersList,
);

export const reducerOnlineUsers = slice.reducer;
