import { createSelector } from 'reselect';
import { AppState } from '../index';

const selectState = (state: AppState) => state.postsLatest;

export const selectPostsLatestState = createSelector(selectState, (state) => {
  return state;
});
export const selectPostsLatestStatuses = createSelector(
  selectPostsLatestState,
  ({ isLoading, isInit, error, applyTags }) => {
    return { isLoading, isInit, error, applyTags };
  },
);
export const selectPostsLatestApplyTags = createSelector(
  selectPostsLatestStatuses,
  ({ applyTags }) => {
    return applyTags;
  },
);
export const selectPostsLatestData = createSelector(selectPostsLatestState, ({ data }) => {
  return data;
});
