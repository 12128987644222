import { AsideEventItemProps } from 'components/layouts/signup/components/aside-events/item';
import DOMPurify from 'dompurify';
import { PageItem, UpcomingEvent } from 'services/pages';
import { PostInfo } from 'services/posts';
import { AsideInfoItem } from 'components/layouts';
import { PatchModel } from './model';
import { set } from 'date-fns';
import { convertToDate, DateValue } from './dates';

export const adapterUpcomingEventsToAsideItems = (data: UpcomingEvent[]): AsideEventItemProps[] => {
  return data.map((item) => {
    return {
      picture: item.picture,
      title: item.title,
      name: item.presenterName,
      position: item.presenterPosition,
      timeSlot: item.eventTimeSlot,
      date: item.eventDate,
    };
  });
};
export const adapterPageItemsToAsideItems = (data: PageItem[]): AsideInfoItem[] => {
  return data.map((item) => {
    return {
      picture: item.picture,
      title: item.title,
      desc: item.description,
    };
  });
};
export const adapterPostData = <T extends PatchModel<PostInfo, 'id'>>(post: T) => {
  const postDescription = DOMPurify.sanitize(post.html || '', {
    ADD_ATTR: ['target'],
    ALLOWED_TAGS: ['b', 'strong', 'ul', 'li', 'a', 'p', 'br'],
    ALLOWED_ATTR: ['style', 'href', 'target'],
  }).replace(/\n/gi, '</br>');

  return {
    ...post,
    postAuthorName: (post.expertArticle ? post.fullName : post.companyName) || 'unknown-name',
    postAuthorPhoto: post.expertArticle ? post.userPhoto : post.logo,
    postDate: post.publishDate,
    postDescription,
    postPicture: post.picture,
    postTotalComments: post.totalComments,
    postTotalReactions: post.totalReactions,
    postVideo: post.video,
    pictures: post.pictures || [],
  };
};

const createEventDate = (date: DateValue, options: { timeslot: string }) => {
  const { timeslot } = options;
  const [hours, minutes] = timeslot.split(':');

  return set(convertToDate(date), {
    hours: parseInt(hours),
    minutes: parseInt(minutes),
    seconds: 0,
    milliseconds: 0,
  });
};
export const normalizeEventData = <T extends { eventDate: string; eventTimeSlot: string }>(
  event: T,
) => {
  const [start, end] = event.eventTimeSlot.split('-');

  return {
    ...event,
    fromDate: createEventDate(event.eventDate, { timeslot: start }),
    toDate: createEventDate(event.eventDate, { timeslot: end }),
  };
};
