const API = 'MedicalEvents';

export const API_MED_EVENTS = {
  GET_DYNAMIC: `${API}/GetMedicalEventsDynamic`,
  JOIN_TO_EVENT: `${API}/CurrentUserExpertProfileJoinMedicalEvent`,
  UN_JOIN_TO_EVENT: (id: MedicalEvent['id']) =>
    `${API}/CurrentUserExpertProfileUnjoinMedicalEvent/${id}`,
};

export interface EventProgramItem {
  title: string;
  timeSlot: string;
  sponsorName: string;
  description: string;
  rank: number;
}

export interface EventSponsor {
  title: string;
  logo: string;
  rank: string;
}

export class MedicalEventBase {
  id: string = '';
  eventDate: string = '';
  eventTimeSlot: string = '';
  title: string = '';
  presenterName: string = '';
  presenterPosition: string = '';
  picture: string = '';
  userRegisterToEvent: boolean = false;
}

export class MedicalEvent extends MedicalEventBase {
  banner: string = '';
  linkURL: string = '';
  addEventLinkURL: string = '';
  userExpertProfileMedicalEventID: string = '';
  medicalEventTagTitles: string = '';
  medicalEventTagIDs: { id: string }[] = [];
  medicalEventTargetAudienceIDs: { id: string }[] = [];
  eventProgramItems: EventProgramItem[] = [];
  eventSponsors: EventSponsor[] = [];
  displayForAll!: boolean;
  presenterPicture: string = '';
}
