import { useRef } from 'react';
import { ArgumentTypes } from '../utils';

export const useCallbackDebounce = <T extends Function>(cb: T, delay: number = 300) => {
  const timeoutRef = useRef<any>();

  function clearTimer() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  }

  return (...args: ArgumentTypes<T>) => {
    clearTimer();
    timeoutRef.current = setTimeout(() => cb(...args), delay);
  };
};
