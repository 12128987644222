import { useAppSelector } from 'hooks';
import React from 'react';
import { selectAccountsState } from 'store/accounts';
import { EmptyLayout } from '../empty';
import { PrivateLayout } from '../private';

interface Classes {
  root: string;
}

interface Props {
  className?: string;
  classes?: Partial<Classes>;
}

export const PrivateOrEmptyLayout: React.FC<Props> = ({ className, classes }) => {
  const auth = useAppSelector(selectAccountsState);
  return auth.error || !auth.user ? <EmptyLayout /> : <PrivateLayout />;
};
