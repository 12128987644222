import { AppLoading } from 'components/loading';
import { useAppDispatch, useAppSelector } from 'hooks';
import React, { useEffect } from 'react';
import { actionLabelsGet, selectLabelsStatus } from 'store/labels';

interface Props {
  children: React.ReactNode;
}
export const AppDictionaryProvider: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { isInit, isLoading } = useAppSelector(selectLabelsStatus);

  useEffect(() => {
    if (!isInit && !isLoading) {
      dispatch(actionLabelsGet.request());
    }
  }, [dispatch, isInit, isLoading]);

  return isLoading ? <AppLoading active={true} /> : <>{children}</>;
};
