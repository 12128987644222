import { createSelector } from 'reselect';
import { calcPaginationState } from 'utils';
import { AppState } from '../index';

const selectState = (state: AppState) => state.surveys;

export const selectSurveysStatuses = createSelector(selectState, ({ isLoading, error, isInit }) => {
  return { isLoading, error, isInit };
});
export const selectSurveyList = createSelector(selectState, ({ data, isLoading }) => {
  return { data, isLoading };
});
export const selectSurveyListPagination = createSelector(selectState, ({ pagination }) => {
  return calcPaginationState(pagination);
});
export const selectSurveyListFilters = createSelector(selectState, ({ filters }) => {
  return filters;
});
export const selectHasSurveyListFilters = createSelector(selectSurveyListFilters, (filters) => {
  return Object.values(filters).some((item: string | string[]) => {
    return Array.isArray(item) ? Boolean(item.length) : Boolean(item);
  });
});
