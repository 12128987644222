import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, TextField, TextFieldProps } from '@mui/material';
import { forwardRef, useCallback, useMemo, useRef } from 'react';
import style from './index.module.scss';

interface IAppInputProps {
  onClear?: () => void;
  disableClearable?: boolean;
  error?: boolean;
}

export interface AppInputProps extends IAppInputProps, Omit<TextFieldProps, 'error'> {}

export const AppInput = forwardRef<any, AppInputProps>(
  ({ error, disableClearable, onClear, fullWidth = true, ...rest }, refInput) => {
    const { disabled, value, defaultValue } = rest;

    const showClearable = useMemo(() => {
      return !disabled && !disableClearable && (!!value || !!defaultValue);
    }, [disabled, disableClearable, value, defaultValue]);

    const ref = useRef<HTMLInputElement>();

    const _onClear = useCallback(() => {
      if (ref.current) {
        let input = ref.current;
        let lastValue = input.value;
        input.value = '';
        let event = new Event('input', { bubbles: true });
        // @ts-ignore
        let tracker = input._valueTracker;
        if (tracker) tracker.setValue(lastValue);
        input.dispatchEvent(event);
      }
      if (onClear) {
        onClear();
      }
    }, [onClear]);

    return (
      <TextField
        ref={refInput}
        error={Boolean(error)}
        fullWidth={fullWidth}
        {...rest}
        inputRef={ref}
        InputProps={{
          ...rest.InputProps,
          endAdornment: (
            <>
              {showClearable && (
                <IconButton
                  onClick={_onClear}
                  tabIndex={-1}
                  className={`${style.button} MuiAutocomplete-popupIndicator`}
                >
                  <ClearIcon />
                </IconButton>
              )}
              {rest.InputProps?.endAdornment}
            </>
          ),
        }}
        className={style.wrap}
      />
    );
  },
);
