import { API, createFilterEquals, DynamicResult } from 'utils';
import { sanitizeChatMessage } from '../utils';
import { API_CHAT, Conversation, ConversationMessage } from './models';

export * from './models';

class Service {
  async getConversations() {
    return await API.get<DynamicResult<Conversation>>(API_CHAT.GET_CONVERSATIONS);
  }

  async getMessages(conversationID: string) {
    const result = await API.get<{ messages: ConversationMessage[] }>(
      API_CHAT.GET_CONVERSATION(conversationID),
      { params: { select: 'messages' } },
    );

    return { ...result, data: { value: result.data.messages, count: result.data.messages.length } };
  }

  async getConversation(conversationID: string) {
    const { data } = await API.get<DynamicResult<Conversation>>(API_CHAT.GET_CONVERSATIONS, {
      params: {
        filter: createFilterEquals<Conversation>({ name: 'conversationID', value: conversationID }),
      },
    });

    if (!data.value[0]) {
      throw new Error('record-not-found');
    }

    return { data: data.value[0] };
  }

  async startConversation(toAppUserID: string) {
    return API.get<string>(API_CHAT.START_CONVERSATION(toAppUserID));
  }
  async readConversation(conversationID: string) {
    return API.put<string>(API_CHAT.READ_CONVERSATION(conversationID));
  }
  async deleteConversation(conversationID: string) {
    return API.delete(API_CHAT.DELETE_CONVERSATION(conversationID));
  }
  async getMessage(messageID: string) {
    return API.get<ConversationMessage>(API_CHAT.GET_MESSAGE(messageID));
  }
  async addMessage(conversationID: string, message: string) {
    return API.post<ConversationMessage>(API_CHAT.ADD_MESSAGE, {
      conversationID,
      message: sanitizeChatMessage(message),
    });
  }
}

export const ServiceChat = new Service();
