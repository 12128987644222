import { useCallback, useMemo } from 'react';
import { actionChatStartChat, useChatOpen } from 'components/cutom-chat';
import { useCurrentUser } from './use-current-user';
import { usePermissions } from './use-permissions';
import { useTranslate } from './use-translate';
import {useAppDispatch} from "hooks/use-app-dispatch";

interface Props {
  userExpertProfileID: string;
  firstName: string;
  lastName: string;
  titleOfNameTitle: string;
  userPhoto?: string;
}

export const useStartChatAction = (props: Props) => {
  const dispatch = useAppDispatch();
  const { firstName, lastName, userPhoto, userExpertProfileID, titleOfNameTitle } = props;
  const { appIdentityUserID } = useCurrentUser();
  const { isAllowChatPhysician } = usePermissions();
  const canStartChat = useMemo(() => {
    return appIdentityUserID !== userExpertProfileID && isAllowChatPhysician;
  }, [appIdentityUserID, userExpertProfileID, isAllowChatPhysician]);

  const { onOpen } = useChatOpen();
  const onStartChat = useCallback(() => {
    dispatch(
      actionChatStartChat({
        appUserID: userExpertProfileID,
        firstName,
        lastName,
        titleOfName: titleOfNameTitle,
        profilePicture: userPhoto,
      }),
    );
    onOpen();
  }, [dispatch, onOpen, firstName, lastName, userPhoto, userExpertProfileID, titleOfNameTitle]);

  const { t } = useTranslate();
  const titleActionChat = t('start-chat');

  return { canStartChat, onStartChat, titleActionChat };
};
