import { Theme, useMediaQuery } from '@mui/material';
import { AppFooterMain, AppFooterMobile } from 'components/app-footer/components';
import React from 'react';

interface Props {
  isViewDesktop?: boolean;
}

export const AppFooter: React.FC<Props> = ({ isViewDesktop }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  return (
    <>
      {isViewDesktop ? (
        <AppFooterMain />
      ) : (
        <>{isMobile ? <AppFooterMobile /> : <AppFooterMain />}</>
      )}
    </>
  );
};
