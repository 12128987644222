import { useEffect, useRef } from 'react';

export const useMountedRef = () => {
  const ref = useRef<boolean>(true);

  useEffect(() => {
    return () => {
      ref.current = false;
    };
  }, []);

  return ref;
};
