import { call, put, select, takeEvery, takeLatest } from 'modules/typed-saga';
import { ServiceSurveys, SurveyFull, SurveyShort } from 'services/surveys';
import { createFilterArrays, createFilterContains, parseErrorData } from 'utils';
import { workerErrorNotifySaga } from 'utils/sagas';
import { selectSurveyListFilters, selectSurveyListPagination } from './selectors';
import { actionSurveysGet } from './slice';

function* getSurveys() {
  const { search, tagIDs, medicalFieldsIDs } = yield* select(selectSurveyListFilters);
  const { skip, take } = yield* select(selectSurveyListPagination);

  const filter = [
    createFilterContains<SurveyFull>({ name: ['title', 'question'], value: search }),
    createFilterArrays<SurveyFull, 'surveyMedicalFieldIDs'>({
      name: 'surveyMedicalFieldIDs',
      value: medicalFieldsIDs,
      key: 'id',
    }),
    createFilterArrays<SurveyFull, 'surveyTagIDs'>({
      name: 'surveyTagIDs',
      value: tagIDs,
      key: 'id',
    }),
  ]
    .filter((v) => !!v)
    .join(' && ');
  const selectParams = Object.keys(new SurveyShort())
    .filter((v) => v !== 'totalVotes')
    .join(',');

  try {
    const {
      data: { value, count },
    } = yield* call(ServiceSurveys.getAllDynamic, {
      select: selectParams,
      filter,
      skip,
      take,
      orderBy: 'rank asc',
    });
    yield* put(actionSurveysGet.success({ data: value, count }));
  } catch (e: any) {
    yield* put(actionSurveysGet.fail({ error: parseErrorData(e) }));
  }
}

export const sagasSurveys = [
  takeLatest(actionSurveysGet.request.type, getSurveys),
  takeEvery([actionSurveysGet.fail], workerErrorNotifySaga),
];
