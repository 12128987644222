import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../index';
export const COUNT_LATEST_ONLINE_USERS = 20;

export const selectOnlineUsersStore = (state: AppState) => state.onlineUsers;

export const selectOnlineUsers = createSelector(
  selectOnlineUsersStore,
  ({ listUsers, loading }) => {
    return { data: listUsers, loading };
  },
);
export const selectOnlineUsersLatest = createSelector(selectOnlineUsers, ({ data }) => {
  return { data: data.slice(0, COUNT_LATEST_ONLINE_USERS) };
});
