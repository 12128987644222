import { createSelector } from 'reselect';
import { calcPaginationState } from 'utils';
import { AppState } from '../index';
import { MedEventFilters, MED_EVENT_CUSTOM_KEYS } from './slice';
import { normalizeEventData } from '../../utils/adapters';

const selectState = (state: AppState) => state.medEvents;

export const selectMedEventsState = createSelector(selectState, (state) => {
  return state;
});
export const selectMedEventsStatuses = createSelector(
  selectMedEventsState,
  ({ isLoading, isInit, error, registerToEventID }) => {
    return { isLoading, isInit, error, registerToEventID };
  },
);
export const selectMedEventsRegisterID = createSelector(
  selectMedEventsStatuses,
  ({ registerToEventID }) => {
    return registerToEventID;
  },
);
export const selectMedEventsFilters = createSelector(selectMedEventsState, ({ filters }) => {
  return filters;
});
export const selectMedEventsData = createSelector(selectMedEventsState, ({ data }) => {
  return data.map(normalizeEventData);
});
export const selectHasMedEventsFilters = createSelector(selectMedEventsFilters, (filters) => {
  const keys = Object.keys(filters) as (keyof MedEventFilters)[];
  return keys.some((key) => {
    switch (key) {
      case 'search':
        return Boolean(filters['search']);
      case 'tags':
        return Boolean(filters['tags'].length);
      case 'date':
        return filters['date'] !== MED_EVENT_CUSTOM_KEYS.THREE_MONTHS;
      default:
        return false;
    }
  });
});
export const selectMedEventsPagination = createSelector(
  selectMedEventsState,
  ({ take, page, count }) => {
    return calcPaginationState({ take, page, count });
  },
);
