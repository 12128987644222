import { usePageMeta, usePageScripts } from 'hooks';
import React from 'react';
import { apiPages } from 'services/pages';

interface Props {
  children: React.ReactNode
}
export const PageDefaultProvider: React.FC<Props> = ({ children }) => {
  const { data } = apiPages.endpoints.getSignUpDoctors.useQuery();
  usePageMeta({ ...data });
  usePageScripts({ ...data });

  return <>{children}</>;
};
