import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Survey, SurveyShort } from 'services/surveys';
import { combineActions } from 'utils/sagas';

export class SurveyItemStore extends SurveyShort {
  isLoading: boolean = false;
}

interface Filters {
  search: string;
  medicalFieldsIDs: string[];
  tagIDs: string[];
}

export interface State {
  data: SurveyItemStore[];
  isLoading: boolean;
  isInit: boolean;
  error: null | Error;
  pagination: {
    take: number;
    page: number;
    count: number;
  };
  filters: Filters;
}

const initState = (): State => {
  return {
    data: [],
    isLoading: false,
    isInit: false,
    error: null,
    pagination: {
      take: 20,
      page: 1,
      count: 0,
    },
    filters: {
      search: '',
      medicalFieldsIDs: [],
      tagIDs: [],
    },
  };
};
const slice = createSlice({
  name: 'SURVEYS',
  initialState: initState(),
  reducers: {
    requestGet(state, action: PayloadAction<{ page: number } | undefined>) {
      state.isLoading = true;
      state.error = null;
      state.pagination.page = (action.payload && action.payload.page) || state.pagination.page;
    },
    successGet(state, action: PayloadAction<{ data: Survey[]; count: number }>) {
      state.isLoading = false;
      state.data = action.payload.data.map((item) => ({ ...item, isLoading: false }));
      state.pagination.count = action.payload.count;
    },
    failGet(state, action: PayloadAction<{ error: Error }>) {
      state.isLoading = false;
      state.error = action.payload.error;
    },

    actionSurveysSetFilters(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    actionSurveysResetFilters(state) {
      state.filters = initState().filters;
    },
  },
  extraReducers: (builder) => {},
});

const actions = slice.actions;

export const actionSurveysGet = combineActions(
  actions.requestGet,
  actions.successGet,
  actions.failGet,
);

export const { actionSurveysResetFilters, actionSurveysSetFilters } = slice.actions;

export const reducerSurveys = slice.reducer;
