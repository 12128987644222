import { useDateFormatSplit, useTranslate } from 'hooks';
import React, { useMemo } from 'react';
import style from './index.module.scss';

interface Props {
  className?: string;
  date: string;
}

export const MessageDate: React.FC<Props> = ({ date }) => {
  const { day: dayMessage, month: monthMessage, year: yearMessage } = useDateFormatSplit(date);
  const nowDate = useMemo(() => new Date(), []);
  const { day: dayNow, month: monthNow, year: yearNow } = useDateFormatSplit(nowDate);

  const { t } = useTranslate();

  const _date = useMemo(() => {
    if (dayNow === dayMessage && monthNow === monthMessage && yearNow === yearMessage) {
      return t('today');
    }
    return [dayMessage, monthMessage, yearMessage].filter((v) => v).join(' ');
  }, [dayMessage, monthMessage, yearMessage, dayNow, monthNow, yearNow, t]);
  return (
    <div className={style.box}>
      <div>{_date}</div>
    </div>
  );
};
