import { useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { selectChatConversationsList } from '../store';
import { useChatConversation } from './use-chat-conversation';

export const useChatAutoSelect = () => {
  const { conversationID, onSelect } = useChatConversation();
  const conversationList = useAppSelector(selectChatConversationsList);

  const firstConversation = conversationList[0];
  const firstConversationID = firstConversation?.conversation.conversationID;

  useEffect(() => {
    if (!conversationID && firstConversationID) {
      onSelect(firstConversationID);
    }
  }, [conversationID, firstConversationID, onSelect]);
};
