import { API } from 'utils';
import { getFileInfo } from 'utils/file-uploader';
import { ServiceMediaUploads } from '../media-uploads';
import { API_POST_PICTURES, PostPicture } from './models';

export * from './models';

class Service {
  async getByPostID(postID: string) {
    return API.get<{ value: PostPicture[] }>(API_POST_PICTURES.GET_DYNAMIC, {
      params: {
        articleID: postID,
        orderBy: 'rank desc',
      },
    });
  }

  async create(props: PostPicture) {
    const { fileName, value } = getFileInfo(props.picture);
    const {
      data: { filePath },
    } = await ServiceMediaUploads.uploadImage({
      fileName,
      fileStreamString: value,
    });
    return API.post(API_POST_PICTURES.POST, {
      ...props,
      articlePictureID: undefined,
      picture: filePath,
    });
  }

  async delete(articlePictureID: string | null) {
    if (!articlePictureID) {
      return;
    }
    return API.delete(API_POST_PICTURES.DELETE(articlePictureID));
  }
}

export const ServicePostPictures = new Service();
