const API = 'Pages';

export const API_PAGES = {
  GET_SIGN_UP: `${API}/GetOnboarding`,
  GET_PRIVACY: `${API}/GetPrivacyPolicy`,
  GET_TERMS: `${API}/GetTermsAndConditions`,
  GET_ONBOARDING_WITH_EVENTS: `${API}/GetOnboardingWithEvents`,
  GET_ONBOARDING_WITH_EVENT: (eventID: string) => `${API}/GetOnboardingWithEvent/${eventID}`,
};

export interface BasePage {
  id: string;
  languageID: string;
  title: string;
  htmlURL: string;
  metaTitle: string;
  metaDescription: string;
  metaKeyWords: string;
  generalBodyScripts: string | null;
  generalFooterScripts: string | null;
  generalHeaderScripts: string | null;
  items: PageItem[];
}
export interface Page extends BasePage {
  html: string | null;
}

export interface PageItem {
  id: string;
  title: string;
  description: string;
  picture: string;
  rank: number;
}

export interface UpcomingEvent {
  id: string;
  eventDate: string;
  eventTimeSlot: string;
  title: string;
  presenterName: string;
  presenterPosition: string;
  picture: string;
  banner: string;
  eventProgramItems: any[];
  eventSponsors: any[];
  presenterPicture: string;
}
export interface PageOnboardingEvents extends Omit<BasePage, 'items'> {
  upcomingEvents: UpcomingEvent[];
}
export interface PageOnboardingEvent extends Omit<BasePage, 'items'> {
  upcomingEvent: UpcomingEvent;
}
