import { AppLoading } from 'components/loading';
import { APP_ROUTES } from 'configs/routes';
import { EmptyLayout, PrivateLayout, PrivateOrEmptyLayout } from 'layouts';
import React from 'react';
import { RouteObject } from 'react-router-dom';
import { RouteRequireAuth } from './required-auth';
import { RouteUserStatusActive } from './user-status-active';

const DashboardPage = React.lazy(() => import('pages/dashboard'));
const SearchPage = React.lazy(() => import('pages/search'));
const ControllerPage = React.lazy(() => import('pages/_controller'));
const SignInPage = React.lazy(() => import('pages/sign-in/step-phone'));
const SignInCodePage = React.lazy(() => import('pages/sign-in/step-code'));

const SignUpDoctorStep3Page = React.lazy(() => import('pages/sign-up/doctors-1'));
const SignUpDoctorStep4Page = React.lazy(() => import('pages/sign-up/doctors-2'));
const SignUpDoctorStep5Page = React.lazy(() => import('pages/sign-up/doctors-3'));

const SignUpExpertStep3Page = React.lazy(() => import('pages/sign-up/experts-1'));
const SignUpExpertStep4Page = React.lazy(() => import('pages/sign-up/experts-2'));
const SignUpExpertStep5Page = React.lazy(() => import('pages/sign-up/experts-3'));

const SignUpVisitorStep3Page = React.lazy(() => import('pages/sign-up/visitors-1'));

const ProfilePage = React.lazy(() => import('pages/profile-edit'));

const EventListPage = React.lazy(() => import('pages/medical-events'));
const EventPage = React.lazy(() => import('pages/medical-event'));
const EventLandingPage = React.lazy(() => import('pages/medical-event-landing'));
const EventThanksPage = React.lazy(() => import('pages/medical-event-thanks'));

const UserPage = React.lazy(() => import('pages/user'));
const SpecialistsPage = React.lazy(() => import('pages/specialists'));
const FollowingPage = React.lazy(() => import('pages/following'));
const OnlineUsersPage = React.lazy(() => import('pages/online-users'));

const PostPage = React.lazy(() => import('pages/post'));

const SurveyListPage = React.lazy(() => import('pages/survey-list'));
const SurveyPage = React.lazy(() => import('pages/survey'));

const MessageHubPage = React.lazy(() => import('pages/messages-hub'));

const NotFoundPage = React.lazy(() => import('pages/not-found'));

export const routes: RouteObject[] = [
  {
    element: (
      <RouteRequireAuth>
        <PrivateLayout />
      </RouteRequireAuth>
    ),
    children: [
      {
        path: APP_ROUTES.DASHBOARD.path,
        element: (
          <RouteUserStatusActive>
            <React.Suspense fallback={<AppLoading />}>
              <DashboardPage />
            </React.Suspense>
          </RouteUserStatusActive>
        ),
      },
      {
        path: APP_ROUTES.SEARCH.path,
        element: (
          <RouteUserStatusActive>
            <React.Suspense fallback={<AppLoading />}>
              <SearchPage />
            </React.Suspense>
          </RouteUserStatusActive>
        ),
      },
      {
        path: APP_ROUTES.SIGNUP_FINISH_CONTROLLER.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <ControllerPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PROFILE.path,
        element: (
          <RouteUserStatusActive>
            <React.Suspense fallback={<AppLoading />}>
              <ProfilePage />
            </React.Suspense>
          </RouteUserStatusActive>
        ),
      },
      {
        path: APP_ROUTES.EVENT_LIST.path,
        element: (
          <RouteUserStatusActive>
            <React.Suspense fallback={<AppLoading />}>
              <EventListPage />
            </React.Suspense>
          </RouteUserStatusActive>
        ),
      },
      {
        path: APP_ROUTES.EVENT.path,
        element: (
          <RouteUserStatusActive>
            <React.Suspense fallback={<AppLoading />}>
              <EventPage />
            </React.Suspense>
          </RouteUserStatusActive>
        ),
      },
      {
        path: APP_ROUTES.EVENT_THANKS.path,
        element: (
          <RouteUserStatusActive>
            <React.Suspense fallback={<AppLoading />}>
              <EventThanksPage />
            </React.Suspense>
          </RouteUserStatusActive>
        ),
      },
      {
        path: APP_ROUTES.USER.path,
        element: (
          <RouteUserStatusActive>
            <React.Suspense fallback={<AppLoading />}>
              <UserPage />
            </React.Suspense>
          </RouteUserStatusActive>
        ),
      },
      {
        path: APP_ROUTES.SPECIALISTS.path,
        element: (
          <RouteUserStatusActive>
            <React.Suspense fallback={<AppLoading />}>
              <SpecialistsPage />
            </React.Suspense>
          </RouteUserStatusActive>
        ),
      },
      {
        path: APP_ROUTES.FOLLOWING.path,
        element: (
          <RouteUserStatusActive>
            <React.Suspense fallback={<AppLoading />}>
              <FollowingPage />
            </React.Suspense>
          </RouteUserStatusActive>
        ),
      },
      {
        path: APP_ROUTES.ONLINE_USERS.path,
        element: (
          <RouteUserStatusActive>
            <React.Suspense fallback={<AppLoading />}>
              <OnlineUsersPage />
            </React.Suspense>
          </RouteUserStatusActive>
        ),
      },
      {
        path: APP_ROUTES.ARTICLE.path,
        element: (
          <RouteUserStatusActive>
            <React.Suspense fallback={<AppLoading />}>
              <PostPage />
            </React.Suspense>
          </RouteUserStatusActive>
        ),
      },
      {
        path: APP_ROUTES.SURVEYS.path,
        element: (
          <RouteUserStatusActive>
            <React.Suspense fallback={<AppLoading />}>
              <SurveyListPage />
            </React.Suspense>
          </RouteUserStatusActive>
        ),
      },
      {
        path: APP_ROUTES.SURVEY.path,
        element: (
          <RouteUserStatusActive>
            <React.Suspense fallback={<AppLoading />}>
              <SurveyPage />
            </React.Suspense>
          </RouteUserStatusActive>
        ),
      },
      {
        path: APP_ROUTES.MESSAGES_HUB.path,
        element: (
          <RouteUserStatusActive>
            <React.Suspense fallback={<AppLoading />}>
              <MessageHubPage />
            </React.Suspense>
          </RouteUserStatusActive>
        ),
      },
    ],
  },
  {
    element: <EmptyLayout />,
    children: [
      {
        path: APP_ROUTES.SIGN_IN.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <SignInPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.SIGN_IN_CODE.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <SignInCodePage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.SIGNUP_EVENT.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <EventLandingPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.SIGNUP_DOCTORS_3.path,
        element: (
          <RouteRequireAuth>
            <React.Suspense fallback={<AppLoading />}>
              <SignUpDoctorStep3Page />
            </React.Suspense>
          </RouteRequireAuth>
        ),
      },
      {
        path: APP_ROUTES.SIGNUP_DOCTORS_4.path,
        element: (
          <RouteRequireAuth>
            <React.Suspense fallback={<AppLoading />}>
              <SignUpDoctorStep4Page />
            </React.Suspense>
          </RouteRequireAuth>
        ),
      },
      {
        path: APP_ROUTES.SIGNUP_DOCTORS_5.path,
        element: (
          <RouteRequireAuth>
            <React.Suspense fallback={<AppLoading />}>
              <SignUpDoctorStep5Page />
            </React.Suspense>
          </RouteRequireAuth>
        ),
      },
      {
        path: APP_ROUTES.SIGNUP_EXPERTS_3.path,
        element: (
          <RouteRequireAuth>
            <React.Suspense fallback={<AppLoading />}>
              <SignUpExpertStep3Page />
            </React.Suspense>
          </RouteRequireAuth>
        ),
      },
      {
        path: APP_ROUTES.SIGNUP_EXPERTS_4.path,
        element: (
          <RouteRequireAuth>
            <React.Suspense fallback={<AppLoading />}>
              <SignUpExpertStep4Page />
            </React.Suspense>
          </RouteRequireAuth>
        ),
      },
      {
        path: APP_ROUTES.SIGNUP_EXPERTS_5.path,
        element: (
          <RouteRequireAuth>
            <React.Suspense fallback={<AppLoading />}>
              <SignUpExpertStep5Page />
            </React.Suspense>
          </RouteRequireAuth>
        ),
      },
      {
        path: APP_ROUTES.SIGNUP_VISITORS_3.path,
        element: (
          <RouteRequireAuth>
            <React.Suspense fallback={<AppLoading />}>
              <SignUpVisitorStep3Page />
            </React.Suspense>
          </RouteRequireAuth>
        ),
      },
    ],
  },
  {
    element: <PrivateOrEmptyLayout />,
    children: [
      {
        path: APP_ROUTES.NOT_FOUND.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <NotFoundPage />
          </React.Suspense>
        ),
      },
      {
        path: '*',
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <NotFoundPage />
          </React.Suspense>
        ),
      },
    ],
  },
];
