import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { selectAccountsState } from 'store/accounts';
import { parseErrorData } from 'utils';
import { Conversation, ConversationMessage, ServiceChat } from '../service';
import { selectChatConversationsList } from './selectors';

export const actionChatReadConversation = createAsyncThunk<
  { conversationID: string },
  { conversationID: string },
  { state: AppState; serializedErrorType: Error }
>(`CUSTOM_CHAT/read`, async ({ conversationID }, { dispatch }) => {
  try {
    await ServiceChat.readConversation(conversationID);
    return { conversationID };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionChatGetMessages = createAsyncThunk<
  { conversationID: string; messages: ConversationMessage[] },
  { conversationID: string },
  { state: AppState; serializedErrorType: Error }
>(`CUSTOM_CHAT/getMessages`, async ({ conversationID }, { dispatch }) => {
  try {
    const {
      data: { value: messages },
    } = await ServiceChat.getMessages(conversationID);
    return { conversationID, messages };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionChatConversationDelete = createAsyncThunk<
  { conversationID: string; conversation?: Conversation },
  { conversationID: string },
  { state: AppState; serializedErrorType: Error }
>(`CUSTOM_CHAT/deleteConversation`, async ({ conversationID }, { getState }) => {
  try {
    const conversations = selectChatConversationsList(getState());
    const stateItem = conversations.find(
      ({ conversation }) => conversation.conversationID === conversationID,
    );
    await ServiceChat.deleteConversation(conversationID);

    return { conversationID, conversation: stateItem?.conversation };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionChatStartConversation = createAsyncThunk<
  {
    appUserID: string;
    conversationID: string;
    fakeConversationID: string;
    conversation: Conversation;
    message: ConversationMessage;
  },
  { conversationID: string; appUserID: string; message: string },
  { state: AppState; serializedErrorType: Error }
>(
  `CUSTOM_CHAT/startConversation`,
  async ({ conversationID: fakeConversationID, appUserID, message: data }, { getState }) => {
    const currentUser = selectAccountsState(getState());
    try {
      if (!currentUser) {
        throw new Error('unexpected-behaviour');
      }
      const { data: conversationID } = await ServiceChat.startConversation(appUserID);
      const { data: message } = await ServiceChat.addMessage(conversationID, data);
      const { data: conversation } = await ServiceChat.getConversation(conversationID);
      return { appUserID, conversationID, fakeConversationID, conversation, message };
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);

export const actionChatSendMessage = createAsyncThunk<
  {
    appUserID: string;
    conversationID: string;
    message: ConversationMessage;
  },
  { conversationID: string; appUserID: string; message: string },
  { state: AppState; serializedErrorType: Error }
>(
  `CUSTOM_CHAT/sendMessage`,
  async ({ conversationID, appUserID, message: data }, { getState, dispatch }) => {
    const currentUser = selectAccountsState(getState());
    try {
      if (!currentUser) {
        throw new Error('unexpected-behaviour');
      }

      const { data: message } = await ServiceChat.addMessage(conversationID, data);

      return { appUserID, message, conversationID };
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);

export const actionChatConversationGet = createAsyncThunk<
  { conversationID: string; conversation: Conversation },
  { conversationID: string },
  { state: AppState; serializedErrorType: Error }
>(`CUSTOM_CHAT/getConversation`, async ({ conversationID }) => {
  try {
    const { data: conversation } = await ServiceChat.getConversation(conversationID);

    return { conversationID, conversation };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionChatMessageGet = createAsyncThunk<
  { conversationID: string; message: ConversationMessage },
  { conversationID: string; messageID: string },
  { state: AppState; serializedErrorType: Error }
>(`CUSTOM_CHAT/getMessage`, async ({ conversationID, messageID }) => {
  try {
    const { data: message } = await ServiceChat.getMessage(messageID);

    return { message, conversationID };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
