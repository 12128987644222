import { useCallback, useMemo } from 'react';
import { debounce } from '../utils';
import { useOnEnter } from './use-on-enter';

export const useSearchField = (onSubmit: (p: { page: number }) => void) => {
  const onBlur = useMemo(() => {
    return debounce(() => {
      onSubmit({ page: 1 });
    }, 300);
  }, [onSubmit]);
  const onKeyPress = useOnEnter(
    useCallback(() => {
      // @ts-ignore
      document.activeElement?.blur();
    }, []),
  );

  return {
    onBlur,
    onKeyPress,
    onClear: onBlur,
  };
};
