import EventIcon from '@mui/icons-material/Event';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { ListItem } from '@mui/material';
import clsx from 'clsx';
import { APP_ROUTES, DASHBOARD_TAGS } from 'configs/routes';
import {
  useAppDispatch,
  useAppSelector,
  usePermissions,
  useRouterParams,
  useSourceUserTags,
  useTranslate,
} from 'hooks';
import { ExtractTypedParams } from 'modules/typed-routes';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { actionAccountLogout } from 'store/accounts';
import { selectLatestFollowingData } from 'store/following-latest';
import { selectSurveyLatest } from 'store/survey-latest';
import style from './index.module.scss';

interface MenuItemProps {
  Icon: ReactNode;
  onClick: () => void;
  isActive?: boolean;
  children: React.ReactNode;
}

const MenuItem: React.FC<MenuItemProps> = ({ Icon, children, isActive, onClick }) => {
  return (
    <ListItem button className={clsx(style.item, isActive && style.current)} onClick={onClick}>
      <div className={style.icon}>{Icon}</div>
      <div className={style.text}>{children}</div>
    </ListItem>
  );
};
interface MenuMobileProps {
  onClose: () => void;
}

const { DASHBOARD } = APP_ROUTES;

export const AppHeaderMobileMenu: React.FC<MenuMobileProps> = ({ onClose: _onClose }) => {
  const { t } = useTranslate();
  const dispatch = useAppDispatch();
  const onLogout = useCallback(() => {
    dispatch(actionAccountLogout());
  }, [dispatch]);

  const push = useNavigate();
  const onClose = useCallback(
    () =>
      setTimeout(() => {
        _onClose();
      }, 300),
    [_onClose],
  );
  const onGoToTab = useCallback(
    (tab: DASHBOARD_TAGS) => {
      push(DASHBOARD({ tab }).config);
      onClose();
    },
    [push, onClose],
  );

  const { data: dataTags } = useSourceUserTags();
  const followingUsers = useAppSelector(selectLatestFollowingData);
  const survey = useAppSelector(selectSurveyLatest);
  const query = useRouterParams<ExtractTypedParams<typeof APP_ROUTES.DASHBOARD>>();
  const currentTab = query.tab || null;

  const factoryGoToTab = useCallback(
    (tab: DASHBOARD_TAGS) => {
      return () => onGoToTab(tab);
    },
    [onGoToTab],
  );

  const {
    isAllowViewDashboard,
    isAllowViewTags,
    isAllowViewMedicalEvent,
    isAllowViewSurvey,
    hideExperts,
    hideFollowing,
  } = usePermissions();

  const isShowFollowing = followingUsers.length > 0 && !hideFollowing;
  const isShowTags = dataTags.length > 0 && isAllowViewTags;
  const surveyTitle = survey?.title;

  const menuItems = useMemo(
    () => [
      ...(isAllowViewDashboard
        ? [
            {
              value: DASHBOARD_TAGS.DASHBOARD,
              label: t('dashboard'),
              icon: <ViewModuleIcon />,
            },
          ]
        : []),
      ...(isAllowViewMedicalEvent
        ? [
            {
              value: DASHBOARD_TAGS.MED_EVENTS,
              label: t('upcoming-events'),
              icon: <EventIcon />,
            },
          ]
        : []),
      ...(isShowFollowing
        ? [
            {
              value: DASHBOARD_TAGS.FOLLOWING,
              label: t('my-following'),
              icon: <PeopleOutlineOutlinedIcon />,
            },
          ]
        : []),
      ...(isShowTags
        ? [
            {
              value: DASHBOARD_TAGS.TAGS,
              label: t('my-tags'),
              icon: <LoyaltyIcon />,
            },
          ]
        : []),
      ...(!hideExperts
        ? [
            {
              value: DASHBOARD_TAGS.EXPERTS,
              label: t('experts'),
              icon: <LiveHelpIcon />,
            },
          ]
        : []),
      ...(isAllowViewSurvey
        ? [
            {
              value: DASHBOARD_TAGS.SURVEY,
              label: surveyTitle || t('survey'),
              icon: <PlaylistAddCheckIcon />,
            },
          ]
        : []),
    ],
    [
      isAllowViewDashboard,
      isAllowViewMedicalEvent,
      isShowFollowing,
      isShowTags,
      hideExperts,
      isAllowViewSurvey,
      surveyTitle,
      t,
    ],
  );

  return (
    <div className={style.wrap}>
      {menuItems.map(({ icon, label, value }) => (
        <MenuItem
          key={value}
          Icon={icon}
          isActive={value === currentTab}
          onClick={factoryGoToTab(value)}
        >
          {label}
        </MenuItem>
      ))}
      <MenuItem Icon={<ExitToAppIcon />} onClick={onLogout}>
        {t('log-out')}
      </MenuItem>
    </div>
  );
};
