import { ServiceCurrentUser } from 'services/current-user';
import { PostInfo, ServicePosts } from 'services/posts';
import { call, put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import {
  createFilterArrays,
  createFilterContains,
  createFilterEquals,
  createFilterNotEquals,
  createFilterSmartSearch,
  parseErrorData,
} from 'utils';
import { workerErrorNotifySaga, workerErrorNotifyThunk } from 'utils/sagas';
import { selectAccountsPermissions } from '../accounts';
import { selectCheckedTagIDs } from '../tags';
import { actionPostsCreate, actionPostsDelete, actionPostsEdit } from './actions';
import { selectPostsFilters, selectPostsOrder, selectPostsPagination } from './selectors';
import { actionPostsGet, actionPostsGetMore, actionPostsTrackVideo, POSTS_ORDER_BY } from './slice';

export const ARTICLE_CATEGORY_POSTS_AND_DISCUSSIONS_ID = '4b397854-f869-4b2b-823a-bfa97ad328d2';

export const calcPostsOrderBy = (custom: POSTS_ORDER_BY): string => {
  switch (custom) {
    case POSTS_ORDER_BY.PUBLISH_DATE:
      return 'updatedDate desc';
    case POSTS_ORDER_BY.MOST_POPULAR:
      return 'totalInteractives desc';
  }
};

function* getParams() {
  const { skip, take } = yield* select(selectPostsPagination);
  const orderBy = yield* select(selectPostsOrder);
  const tagIDs = yield* select(selectCheckedTagIDs);
  const { search, articleCategoryID } = yield* select(selectPostsFilters);
  const { isAllowViewWebinars } = yield* select(selectAccountsPermissions);

  const filterSearch = [
    createFilterContains<PostInfo>({ name: ['companyName', 'html'], value: search }),
    createFilterSmartSearch<PostInfo>({ name: ['firstName', 'lastName'], value: search }),
  ]
    .filter((v) => !!v)
    .join('||');

  const filterCategory = isAllowViewWebinars
    ? createFilterNotEquals<PostInfo>({
        name: 'articleCategoryID',
        value: ARTICLE_CATEGORY_POSTS_AND_DISCUSSIONS_ID,
      })
    : createFilterEquals<PostInfo>({
        name: 'articleCategoryID',
        value: articleCategoryID === 'ALL' ? null : articleCategoryID,
      });
  const filterTags = createFilterArrays<PostInfo, 'tags'>({
    name: 'tags',
    key: 'tagID',
    value: tagIDs,
  });

  const filter = [filterCategory, filterTags, filterSearch].filter((v) => !!v).join('&&');

  return { filter, take, skip, orderBy: calcPostsOrderBy(orderBy) };
}

function* getPosts() {
  const params = yield* call(getParams);
  try {
    let { data } = yield* call(ServicePosts.getAll, params);
    yield put(actionPostsGet.success({ data: data.value, count: data.count }));
  } catch (e: any) {
    yield put(actionPostsGet.fail({ error: parseErrorData(e) }));
  }
}

function* getMorePosts() {
  const params = yield* call(getParams);
  try {
    let { data } = yield* call(ServicePosts.getAll, params);
    yield put(actionPostsGetMore.success({ data: data.value, count: data.count }));
  } catch (e: any) {
    yield put(actionPostsGetMore.fail({ error: parseErrorData(e) }));
  }
}

function* watchTrackVideo(action: ReturnType<typeof actionPostsTrackVideo>) {
  const {
    payload: { articleID },
  } = action;
  try {
    yield* call(ServiceCurrentUser.trackVideo, articleID);
  } catch (e: any) {}
}

export const sagasPosts = [
  takeLatest(actionPostsGet.request, getPosts),
  takeLatest(actionPostsGetMore.request, getMorePosts),
  takeLatest(actionPostsTrackVideo, watchTrackVideo),
  takeEvery([actionPostsGet.fail, actionPostsGetMore.fail], workerErrorNotifySaga),
  takeEvery(
    [actionPostsDelete.rejected, actionPostsCreate.rejected, actionPostsEdit.rejected],
    workerErrorNotifyThunk,
  ),
];
