import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, Grid } from '@mui/material';
import { AppDialogContent, AppDialogFooter, AppDialogTitle } from 'components/dialogs';
import { useTranslate } from 'hooks';
import React from 'react';

interface DialogFollowProps {
  className?: string;
  onConfirm: () => void;
  onClose: () => void;
}

export const DialogFollow: React.FC<DialogFollowProps> = ({ onClose, onConfirm }) => {
  const { t } = useTranslate();

  return (
    <Dialog open={true} onClose={onClose} maxWidth="md" scroll="paper" fullWidth>
      <AppDialogTitle Icon={<CloseIcon />} onClose={onClose} />
      <AppDialogContent>
        <div
          style={{ fontSize: '2rem', marginBottom: '0.5rem', lineHeight: 1.2, textAlign: 'center' }}
        >
          {t('unsubscribe-follow-are-you-sure')}
        </div>
      </AppDialogContent>
      <AppDialogFooter>
        <Grid container sx={{ alignItems: 'center', justify: 'center' }}>
          <Grid item>
            <Button onClick={onClose}>{t('cancel')}</Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={onConfirm}>
              {t('confirm')}
            </Button>
          </Grid>
        </Grid>
      </AppDialogFooter>
    </Dialog>
  );
};
