import { createSelector } from 'reselect';
import { AppState } from '../index';

const selectState = (state: AppState) => state.latestFollowing;

export const selectLatestFollowingStatus = createSelector(
  selectState,
  ({ isLoading, isInit, error }) => {
    return { isLoading, isInit, error };
  },
);

export const selectLatestFollowingData = createSelector(selectState, ({ data }) => {
  return data;
});

export const selectLatestFollowingApplyTags = createSelector(selectState, ({ applyTags }) => {
  return applyTags;
});
