import { Button, Dialog, Grid, Theme, useMediaQuery } from '@mui/material';
import { AppDialogContent, AppDialogFooter, AppDialogTitle } from 'components/dialogs';
import { FormTags } from 'components/form-tags';
import { useMountedRef, useNotify, useSourceUserTags, useTranslate } from 'hooks';
import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { apiCurrentUser } from 'services/current-user';
import { isRtkMutationFulfilled, isRtkMutationRejected } from 'utils';
import { AppLoading } from '../../loading';

interface DialogTagsProps {
  onClose: () => void;
}

const defaultValues = {
  tags: [] as string[],
};

type Model = typeof defaultValues;

export const DialogTags: React.FC<DialogTagsProps> = ({ onClose }) => {
  const { t } = useTranslate();
  const [updateTags, { isLoading }] = apiCurrentUser.endpoints.updateUserTags.useMutation();

  const { control, reset, handleSubmit } = useForm({ shouldUnregister: true, defaultValues });

  const { data, isFetching } = useSourceUserTags();
  useEffect(() => {
    reset({ tags: data.map(({ tagID }) => tagID) });
  }, [data, reset]);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const mountedRef = useMountedRef();
  const { onError } = useNotify();
  const onSubmit = useCallback(
    async (data: Model) => {
      const result = await updateTags(data.tags);

      if (!mountedRef.current) return;

      if (isRtkMutationRejected(result)) {
        onError(result.error);
      }
      if (isRtkMutationFulfilled(result)) {
        onClose();
      }
    },
    [onError, mountedRef, updateTags, onClose],
  );

  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth="md"
      scroll={'paper'}
      fullWidth={!isMobile}
      fullScreen={isMobile}
    >
      <AppDialogTitle onClose={onClose} children={t('my-tags')} />
      <AppDialogContent>
        <Controller
          control={control}
          name={'tags'}
          render={({ field: { onChange, value } }) => {
            return <FormTags value={value} onChange={onChange} />;
          }}
        />
      </AppDialogContent>
      <AppDialogFooter>
        <Grid container justifyContent={'flex-end'} rowSpacing={'1.5rem'} columnSpacing={'3rem'}>
          <Grid item>
            <Button color={'secondary'} onClick={onClose}>
              {t('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleSubmit(onSubmit)}
              variant={'contained'}
              color={'primary'}
              disabled={isFetching || isLoading}
            >
              {t('submit')}
            </Button>
          </Grid>
        </Grid>
      </AppDialogFooter>
      <AppLoading active={isLoading} />
    </Dialog>
  );
};
