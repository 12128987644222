import { ServiceExpertProfiles, UserFollowing } from 'services/expert-profiles';
import { call, put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import { createFilterArrays, parseErrorData } from 'utils';
import { workerErrorNotifySaga } from 'utils/sagas';
import { selectCheckedTagIDs } from '../tags';
import { selectLatestFollowingApplyTags } from './selectors';
import { actionsLatestFollowingGet } from './slice';

function* fetchFollowingUsers() {
  const tagIDs = yield* select(selectCheckedTagIDs);
  const applyTags = yield* select(selectLatestFollowingApplyTags);

  let filter: string | undefined;

  if (applyTags) {
    filter = [
      createFilterArrays<UserFollowing, 'userExpertProfileTagIDs'>({
        name: 'userExpertProfileTagIDs',
        key: 'ID',
        value: tagIDs,
      }),
    ]
      .filter((v) => v)
      .join('||');
  }

  try {
    const { data } = yield* call(ServiceExpertProfiles.getFollowingSmall, {
      take: 10,
      filter,
    });

    yield* put(actionsLatestFollowingGet.success({ data: data.value }));
  } catch (e: any) {
    yield* put(actionsLatestFollowingGet.fail({ error: parseErrorData(e) }));
  }
}

export const sagasLatestFollowing = [
  takeLatest(actionsLatestFollowingGet.request, fetchFollowingUsers),
  takeEvery([actionsLatestFollowingGet.fail], workerErrorNotifySaga),
];
