import { ExpertProfile, ServiceExpertProfiles } from 'services/expert-profiles';
import { call, debounce, put, select, takeLatest } from 'typed-redux-saga';
import { parseErrorData } from 'utils';
import { workerErrorNotifySaga } from 'utils/sagas';
import {
  createFilter,
  createFilterContains,
  createFilterDeep,
  createFilterSmart,
  mergeFilters,
} from 'utils/service-filters';
import { selectUsersFilters, selectUsersPagination } from './selectors';
import { getUsersActions } from './slice';

function* searchUsers() {
  const { search, languageIDs, hmoIDs, primaryMedicalFieldIDs, secondaryMedicalFieldIDs } =
    yield* select(selectUsersFilters);
  const { skip, take } = yield* select(selectUsersPagination);

  const filterSearch = mergeFilters(
    [
      createFilterSmart<ExpertProfile>(['titleOfNameTitle', 'firstName', 'lastName'], search),
      createFilterContains<ExpertProfile>(
        [
          'position',
          'primaryMedicalFieldTitle',
          'secondaryMedicalFieldTitle',
          'hmoTitle',
          'hospitalTitle',
        ],
        search,
      ),
    ],
    '||',
  );

  const filter = mergeFilters(
    [
      createFilter<ExpertProfile>('externalCertificateValid', '==', true),
      createFilter<ExpertProfile>('userStatusID', '==', '3f9af680-8fdc-41d8-b1a4-252b9da22917'),
      createFilter<ExpertProfile>('expertTypeID', '==', '2dfbd386-de2b-4789-b7c3-7fcbfc040a7e'),

      filterSearch,
      createFilter<ExpertProfile>('primaryMedicalFieldID', '==', primaryMedicalFieldIDs),
      createFilter<ExpertProfile>('secondaryMedicalFieldID', '==', secondaryMedicalFieldIDs),
      createFilter<ExpertProfile>('hmoID', '==', hmoIDs),

      createFilterDeep<ExpertProfile>('userExpertProfileSpeakLanguageIDs', 'ID', languageIDs),
    ],
    '&&',
  );

  try {
    const {
      data: { value, count },
    } = yield* call(ServiceExpertProfiles.getExperts, {
      filter,
      skip,
      take,
    });
    yield* put(getUsersActions.success({ data: value, count }));
  } catch (e: any) {
    yield* put(getUsersActions.fail({ error: parseErrorData(e) }));
  }
}

export const sagasUsers = [
  debounce(300, getUsersActions.request, searchUsers),
  takeLatest([getUsersActions.fail], workerErrorNotifySaga),
];
