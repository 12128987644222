import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserWithMixins } from 'services/auth';
import { combineActions } from 'utils/sagas';
import {
  actionAccountGenerateCode,
  actionAuthChangeExpertType,
  actionAuthGetCurrentUser,
  actionAuthLogin,
  submitMainInfoUserAction,
} from './actions';

export interface StateAccounts {
  init: boolean;
  user: null | UserWithMixins;
  loading: boolean;
  isLoadingPatch: boolean;
  MobilePhone: null | string;
  email: null | string;
  error: null | Error;
}

const initState = (): StateAccounts => {
  return {
    init: false,
    user: null,
    loading: false,
    isLoadingPatch: false,
    MobilePhone: null,
    email: null,
    error: null,
  };
};
const slice = createSlice({
  name: 'AUTH',
  initialState: initState(),
  reducers: {
    patchUserRequest(state, action: PayloadAction<Partial<UserWithMixins>>) {
      state.isLoadingPatch = true;
    },
    patchUserSuccess(state, action: PayloadAction<Partial<UserWithMixins>>) {
      state.isLoadingPatch = false;
      state.user = state.user ? { ...state.user, ...action.payload } : null;
    },
    patchUserError(state, action: PayloadAction<Partial<UserWithMixins>>) {
      state.isLoadingPatch = false;
      state.user = state.user ? { ...state.user, ...action.payload } : null;
    },

    activateUserRequest(state) {
      state.loading = true;
      state.error = null;
    },
    activateUserSuccess(state, action: PayloadAction<UserWithMixins>) {
      state.loading = false;
      state.init = true;
      state.user = action.payload;
    },
    activateUserError(state, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action.payload;
    },

    resetUserAction() {
      return initState();
    },
  },
  extraReducers: (build) => {
    build.addCase(actionAuthLogin.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    build.addCase(actionAuthLogin.fulfilled, (state, action) => {
      state.loading = false;
    });
    build.addCase(actionAuthLogin.rejected, (state, action) => {
      state.loading = false;
    });

    build.addCase(actionAuthGetCurrentUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    build.addCase(actionAuthGetCurrentUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.init = true;
    });
    build.addCase(actionAuthGetCurrentUser.rejected, (state, action) => {
      state.loading = false;
      state.init = true;
    });

    build.addCase(actionAuthChangeExpertType.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    build.addCase(submitMainInfoUserAction.fulfilled, (state, action) => {
      state.user = action.payload;
    });

    build.addCase(actionAccountGenerateCode.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    build.addCase(actionAccountGenerateCode.fulfilled, (state, action) => {
      const { email, mobilePhone } = action.meta.arg;
      state.loading = false;
      state.email = email;
      state.MobilePhone = mobilePhone;
    });
    build.addCase(actionAccountGenerateCode.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

const actions = slice.actions;

export const { resetUserAction } = actions;

export const patchUserActions = combineActions(
  actions.patchUserRequest,
  actions.patchUserSuccess,
  actions.patchUserError,
);
export const activateUserActions = combineActions(
  actions.activateUserRequest,
  actions.activateUserSuccess,
  actions.activateUserError,
);

export const reducerAccounts = slice.reducer;
