import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SpecialistPreview } from 'services/specialists';
import { combineActions } from 'utils/sagas';
import { addTag, removeTag, uncheckAllTags, verifyTags } from '../tags';

interface Filters {
  search: string;
}

interface State {
  applyTags: boolean;
  isInit: boolean;
  isLoading: boolean;
  error: Error | null;
  data: SpecialistPreview[];
  filters: Filters;
  take: number;
  page: number;
  count: number;
}

export const initState = (): State => {
  return {
    applyTags: false,
    isInit: false,
    isLoading: false,
    error: null,
    data: [],
    filters: {
      search: '',
    },
    take: 5,
    page: 1,
    count: 0,
  };
};
const slice = createSlice({
  name: 'SPECIALISTS_LATEST',
  initialState: initState(),
  reducers: {
    requestGet(state, action: PayloadAction<{ page: number | undefined }>) {
      state.isLoading = true;
      state.error = null;
      state.page = action.payload.page ?? state.page;
    },
    successGet(state, action: PayloadAction<{ data: SpecialistPreview[]; count: number }>) {
      state.isLoading = false;
      state.isInit = true;
      state.error = null;
      state.count = action.payload.count;
      state.data = action.payload.data;
    },
    failGet(state, action: PayloadAction<{ error: Error }>) {
      state.isLoading = false;
      state.isInit = true;
      state.error = action.payload.error;
    },

    actionSpecialistsLatestSetFilters(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    actionSpecialistsLatestResetFilters(state) {
      state.filters = initState().filters;
    },
    actionSpecialistsLatestSetApplyTags(state, action: PayloadAction<boolean>) {
      state.applyTags = action.payload;
      state.isInit = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => {
        return [addTag.type, removeTag.type, verifyTags.type, uncheckAllTags.type].some(
          (itemAction) => itemAction === action.type,
        );
      },
      (state) => {
        state.isInit = state.applyTags ? false : state.isInit;
      },
    );
  },
});

const actions = slice.actions;
export const {
  actionSpecialistsLatestSetFilters,
  actionSpecialistsLatestResetFilters,
  actionSpecialistsLatestSetApplyTags,
} = actions;

export const actionSpecialistsLatestGet = combineActions(
  actions.requestGet,
  actions.successGet,
  actions.failGet,
);

export const reducerSpecialistsLatest = slice.reducer;
