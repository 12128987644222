import { ThemeOptions } from '@mui/material';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';
import VARS from 'styles/config.scss';

const appBreakpoints = {
  values: {
    xs: Number(VARS.xs),
    sm: Number(VARS.sm),
    md: Number(VARS.md),
    lg: Number(VARS.lg),
    xl: Number(VARS.xl),
  },
};

const breakpoints = createBreakpoints({ ...appBreakpoints });

export const AppThemeOptions: ThemeOptions = {
  breakpoints: breakpoints,
  spacing: 10,
  typography: {
    fontSize: 14,
    htmlFontSize: 10,
    fontWeightRegular: 400,
    fontFamily: 'Arimo, Arial, Tahoma, Geneva, sans-serif',
    h1: {
      fontSize: '3.2rem',
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '2.4rem',
      fontWeight: 400,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: '2.3rem',
      fontWeight: 400,
      lineHeight: 1.2,
    },
    h4: {
      fontSize: '2.2rem',
      fontWeight: 400,
      lineHeight: 1.2,
    },
    h5: {
      fontSize: '2rem',
      fontWeight: 400,
      lineHeight: 1.2,
    },
    h6: {
      fontSize: '1.7rem',
      fontWeight: 400,
      lineHeight: 1.2,
    },
    subtitle1: {
      fontSize: '1.6rem',
      fontWeight: 400,
      lineHeight: 1.43,
    },
    subtitle2: {
      fontSize: '1.4rem',
      lineHeight: 1.43,
      fontWeight: 400,
    },
    body1: {
      fontSize: '1.3rem',
      lineHeight: 1.43,
    },
    body2: {
      fontSize: '1.2rem',
      lineHeight: 1.43,
    },
    caption: {
      fontSize: '1rem',
      lineHeight: 1.2,
    },
    button: {
      fontSize: '1.4rem',
      fontWeight: 400,
    },
  },

  palette: {
    text: {
      primary: 'rgba(0,0,0,0.87)',
    },
    primary: {
      main: VARS.colorPrimary,
      contrastText: '#fff',
    },
    secondary: {
      main: VARS.colorSecondary,
      contrastText: '#fff',
    },
    secondaryReverse: {
      main: '#fff',
      contrastText: VARS.colorSecondary,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          boxShadow: '0 0.2rem 1.2rem 0 rgba(0,0,0, 0.16)',
        },
        root: {
          borderRadius: '0.6rem',
          textTransform: 'none',
        },
        sizeLarge: {
          minHeight: '4.8rem',
          fontSize: '1.6rem',
          fontWeight: 'bold',
          minWidth: '16rem',
        },
        sizeMedium: {
          minHeight: '4rem',
          minWidth: '9rem',
          padding: '0.6rem 1.6rem',
        },
        sizeSmall: {
          fontSize: '1.3rem',
          padding: '0.3rem 1rem',
          minWidth: 'auto',
          lineHeight: 1.2,
          [breakpoints.down('md')]: {
            fontSize: '1.2rem',
          },
        },
        iconSizeMedium: {
          '& > *:first-child': {
            fontSize: '2.4rem',
          },
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          color: '#CFDCE7',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          color: '#CFDCE7',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        color: 'secondary',
        variant: 'standard',
        inputProps: {
          autoComplete: 'off',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '1.4rem',
        },
        multiline: {
          padding: '0.6rem 0 0.7rem',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontSize: '1.7rem',
          lineHeight: 1,
          height: '0.894em',
          '&::placeholder': {
            fontSize: '1.4rem',
          },
        },
        root: {
          '& fieldset': {
            borderColor: 'rgba(0,0,0,0.12)',
          },
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        elevation1: {
          // boxShadow: '0 0.1rem 0.4rem 0 rgba(0,0,0, 0.1), 0 0.1rem 0.1rem 0 rgba(0,0,0, 0.1)',
          boxShadow: 'none',
        },
        rounded: {
          borderRadius: '0.6rem',
          border: '1px solid rgba(54,88,122,0.2)',
          [breakpoints.down('md')]: {
            borderRadius: '0',
            border: 'none',
            boxShadow: VARS.BoxShadowMobile + '!important',
          },
        },
        root: {
          // marginBottom: '1rem',
          // color: '#000',
          // [breakpoints.down('md')]: {
          //   marginBottom: '0.6rem',
          // },
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '1.1rem',
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          margin: '0.2rem',
          fontSize: '1.4rem',
        },
        listbox: {
          padding: 0,
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            '&.Mui-focused': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          },
        },
        option: {
          fontSize: '1.4rem',
        },
      },
    },

    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255,255,255,0.25)',
          [breakpoints.down('md')]: {
            backgroundColor: 'rgba(0,0,0,0.32)',
          },
        },
        invisible: {
          backgroundColor: 'transparent',
          [breakpoints.down('md')]: {
            backgroundColor: 'transparent',
          },
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '1.2rem',
        },
        sizeSmall: {
          padding: '0.8rem',
        },
      },
    },

    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '0',
          paddingRight: '0',
          [breakpoints.up('sm')]: {
            paddingLeft: '0',
            paddingRight: '0',
          },
          [breakpoints.up('md')]: {
            paddingLeft: '1.6rem',
            paddingRight: '1.6rem',
          },
        },
      },
    },

    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '0.8rem',
          padding: '0.9rem 1.1rem',
          display: 'flex',
          alignItems: 'center',
          border: 'none',
        },
        message: {
          fontSize: ' 1.4rem',
          padding: 0,
          color: '#000',
          [breakpoints.down('md')]: {
            fontSize: '1.3rem',
            lineHeight: 1.2,
          },
        },
        icon: {
          padding: 0,
        },
        standardError: {
          backgroundColor: 'rgba(235,87,87,.3)',
        },
      },
    },

    MuiChip: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: {
          height: '2.3rem',
        },
        label: {
          fontSize: '1.2rem',
        },
        deleteIcon: {
          width: '1.6rem',
          height: '1.6rem',
        },
      },
    },

    MuiBadge: {
      styleOverrides: {
        badge: {
          border: '0.2rem solid #fff',
          height: '2rem',
          minWidth: '2rem',
          borderRadius: '1rem',
          fontWeight: 'bold',
          color: '#fff',
          padding: '0 0.5rem',
          fontSize: '1.2rem',
        },
        dot: {
          height: '1.2rem',
          width: '1.2rem',
          minWidth: '1.2rem',
          padding: '0',
          borderRadius: '50%',
        },
      },
    },

    MuiFab: {
      styleOverrides: {
        root: {
          zIndex: 50,
          boxShadow: '0 0.2rem 1.2rem 0 rgba(0,0,0, 0.16)',
        },
      },
    },

    MuiMenu: {
      styleOverrides: {
        list: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: 'inherit',
          paddingTop: '1.05rem',
          paddingBottom: '1.05rem',
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
        },
      },
    },

    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#F3F6F9',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
          },
        },
      },
    },
  },
};
