import { useContext } from 'react';
import { ChatContext } from '../provider';

export const useChatContext = () => {
  const context = useContext(ChatContext);

  if (!context) {
    throw new Error('CHAT CONTEXT is required');
  }

  return context;
};
