import { useTranslate } from 'hooks/use-translate';
import { useCallback } from 'react';
import { ControllerFieldState, ControllerRenderProps, UseFormStateReturn } from 'react-hook-form';
import { fieldToLabelKey } from 'utils/other';

interface Options {
  field: ControllerRenderProps<any, any>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<any>;
}

export const useFormSelectProps = (props?: Partial<any>) => {
  const { t } = useTranslate();
  return useCallback(
    (options: Options) => {
      const { field, fieldState } = options;
      const helperText = fieldState.error?.message ? t(fieldState.error.message) : ' ';
      return {
        ...field,
        ...props,
        helperText,
        error: Boolean(fieldState.error),
        label: t(fieldToLabelKey(field.name)),
      };
    },
    [t, props],
  );
};
